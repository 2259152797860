import Vue from 'vue';
import App from './App.vue';
import router from './plugins/router';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import './styles/general.sass';
import store from './plugins/vuex'
import './plugins/tracking'
import LoadScript from 'vue-plugin-load-script';

require('require-context/register');

Vue.use(LoadScript);
Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
