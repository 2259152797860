<template>
    <div class="content-app">
        <popup-box v-if="hoverText"
                   ref="popup-box"
                   :style='style'
                   @mouseover.native="hoverDebounce(true)"
                   @mouseleave.native="hoverDebounce(false)"
                   v-bind:infos="infos"
                   v-bind:language="language">
        </popup-box>
    </div>
</template>

<script>
    import PopupBox from './PopupBox';
    import { mapState } from 'vuex';

    export default {
        name: "PopupBoxContent",
        components: {PopupBox},
        computed: {
            ...mapState(['hoverText', 'hoverDebounce', 'infos', 'language', 'leftMargin', 'topMargin']),
            style () {
                return {
                    transform: `translate3d(${this.leftMargin}px,${this.topMargin}px,0)`,
                }
            }
        },
    };
</script>