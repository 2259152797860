<template>
    <svg class="icon" height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <clipPath :id="`clip-path_${uuid}`">
                <path class="cls-1" d="M426.38,455.58a9.48,9.48,0,0,1-8.73-5.8l-35.45-84L340.06,403a11.91,11.91,0,0,1-8.23,3.12,12.56,12.56,0,0,1-12.52-12.57V164.93a12.56,12.56,0,0,1,12.52-12.57,12.88,12.88,0,0,1,8.15,3L505.26,322.91c2.58,3.17,3.58,6,3.58,8.87a12.57,12.57,0,0,1-12.52,12.57H440.18l34.16,81a9.47,9.47,0,0,1-5,12.37L430.16,454.8A9.55,9.55,0,0,1,426.38,455.58ZM385.81,340.47a9.24,9.24,0,0,1,2.11.24,9.45,9.45,0,0,1,6.62,5.56L431.4,433.6l21.85-9.5-36.07-85.55a9.47,9.47,0,0,1,8.73-13.15h55.28L338.27,180.49V379.35l41.26-36.5A9.49,9.49,0,0,1,385.81,340.47Zm-58,48.15a7.29,7.29,0,0,0-.73.64Zm163.29-53.19h0ZM140.44,102.54h18.33l22.74,72.54h-15.1l-4.61-15.19h-24.7l-4.51,15.19H117.7Zm17.94,44.31L149.46,115l-8.92,31.86Zm31.07-44.31H218q20.68,0,20.68,19,0,6.47-2.65,10.54a12.35,12.35,0,0,1-7.45,5.34,16,16,0,0,1,8.87,5.69q3.58,4.41,3.58,12,0,10.78-5.44,15.39t-16.32,4.61h-29.8Zm28.62,29.9a5.91,5.91,0,0,0,5-2.35,10,10,0,0,0,1.81-6.27q0-4.31-1.91-6.27a6.74,6.74,0,0,0-5-2H204v16.86Zm.78,29.6q8.33,0,8.33-9.51a9,9,0,0,0-2.3-6.52,8,8,0,0,0-6-2.4H204V162Zm41.76,10.73q-5.59-2.89-8.58-11t-3-23.18q0-14.51,3.09-22.55t8.77-11.13q5.68-3.09,14.61-3.09a72.81,72.81,0,0,1,12.55,1,74.59,74.59,0,0,1,10.1,2.4v11.27a65.07,65.07,0,0,0-8.33-1.18q-5.39-.49-11.27-.49t-9,1.81q-3.14,1.81-4.56,6.86t-1.42,15.15q0,10,1.27,15t4.41,7q3.14,2,9.31,2a159.4,159.4,0,0,0,19.61-1v11.18q-8.73,2.84-22.84,2.84Q266.21,175.66,260.62,172.77Zm26.21,29.06a8.5,8.5,0,0,0-8.5-8.5H29.58a11.26,11.26,0,0,1-11.25-11.25V93.69A11.26,11.26,0,0,1,29.58,82.44H394.32a11.26,11.26,0,0,1,11.25,11.25v81.39a8.5,8.5,0,0,0,17,0V93.69a28.28,28.28,0,0,0-28.25-28.25H29.58A28.28,28.28,0,0,0,1.33,93.69v88.4a28.28,28.28,0,0,0,28.25,28.25H278.33A8.5,8.5,0,0,0,286.83,201.84Z"/>            </clipPath>
            <linearGradient :id="`grad_${uuid}`"
                            :gradientTransform="`rotate(${rotateAngle})`"
                            x1="0%"
                            y1="0%"
                            x2="100%"
                            y2="0%"
            >
                <stop offset="0%" :style="`stop-color:${gradColorStart};stop-opacity:1`"/>
                <stop offset="100%" :style="`stop-color:${gradColorEnd};stop-opacity:1`"/>
            </linearGradient>
        </defs>
        <g :clip-path="`url(#clip-path_${uuid})`">
            <rect :fill="iconColor" x="0" y="0" width="512" height="512"/>
        </g>
    </svg>
</template>

<script>
    import { uuidv4 } from '../../../utils/helper';

    export default {
        name: "HoverIcon",
        props: {
            color: {
                type: String,
                default: '',
            },
            gradColorStart: {
                type: String,
                default: 'black',
            },
            gradColorEnd: {
                type: String,
                default: 'black',
            },
            rotateAngle: {
                type: Number,
                default: 0,
            }
        },
        data () {
            return {
                uuid: uuidv4(),
            }
        },
        computed: {
            iconColor() {
                if (this.color === '') {
                    return `url(#grad_${this.uuid})`;
                }
                return this.color;
            }
        },
    }
</script>

<style scoped lang="sass">
    .icon
        border-radius: 0 !important
</style>