<template>
    <div>
        <svg class="faq-background__elm1"
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 50 50">
            <circle class="faq-background__elm1-path" cx="25" cy="25" r="22"/>
        </svg>
        <svg class="faq-background__elm2"
             xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 50 50">
            <circle class="faq-background__elm2-path" cx="25" cy="25" r="22"/>
        </svg>
    </div>
</template>

<script>
    export default {
        name: "FAQBackground"
    }
</script>

<style scoped lang="sass">
    .faq-background__elm1
        position: absolute
        height: 15px
        left: 150px
        top: -40px

    .faq-background__elm1-path
        fill: #ede3fc

    .faq-background__elm2
        position: absolute
        height: 15px
        right: 75px
        bottom: 20px

    .faq-background__elm2-path
        fill: #fad9ca

    @media screen and (max-width: 1264px)
        .faq-background__elm2
            display: none

    @media screen and (max-width: 960px)
        .faq-background__elm1
            left: 50px


    @media screen and (max-width: 600px)
        .faq-background__elm1
            left: 25px
</style>