<template>
    <policy-page v-bind:title="$t('policy_pages.privacy.title')"
                 v-bind:last-update="$t('policy_pages.privacy.last_update')"
                 v-bind:description="$t('policy_pages.privacy.description')"
                 v-bind:sections="sections"
    ></policy-page>
</template>

<script>
    import PolicyPage from './PolicyPage';
    export default {
        name: "PrivacyPage",
        components: {PolicyPage},
        computed: {
            sections() {
                return [
                    {
                        title: this.$t('policy_pages.privacy.subsection_0.title'),
                        content: this.$t('policy_pages.privacy.subsection_0.content'),
                    },
                    {
                        title: this.$t('policy_pages.privacy.subsection_1.title'),
                        content: this.$t('policy_pages.privacy.subsection_1.content'),
                    },
                    {
                        title: this.$t('policy_pages.privacy.subsection_2.title'),
                        content: this.$t('policy_pages.privacy.subsection_2.content'),
                    },
                    {
                        title: this.$t('policy_pages.privacy.subsection_3.title'),
                        content: this.$t('policy_pages.privacy.subsection_3.content'),
                    },
                ]
            },
        }
    }
</script>

<style scoped>

</style>