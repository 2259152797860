<template>
    <div class="why d-flex flex-column align-center secondary--text justify-center">
        <why-background></why-background>
        <div class="why__content d-flex flex-column align-left px-7">
            <div class="why__title-group d-flex align-center flex-column">
                <div class="why__separator-title rounded-pill background_primary"></div>
                <div class="why__title mt-4">
                    {{$t('home.why.title')}}
                </div>
                <div class="why__subtitle mt-4">
                    {{$t('home.why.subtitle')}}
                </div>
                <div :class="`why__cards d-flex 'flex-row' mt-12 flex-wrap justify-center`">
                    <v-card v-for="(item, index) in cards"
                            v-bind:key="index"
                            :class="`why__card rounded-xl ma-3 pa-7 ${item.smallText ? 'smallText' : ''}`"
                    >
                        <component class="why__card-icon"
                                   :is="item.icon"
                                   v-bind:grad-color-start="'#e82e10'"
                                   v-bind:grad-color-end="'#fcd702'"
                                   v-bind:rotate-angle="14"
                        ></component>
                        <div class="why__card-title mt-2">{{item.title}}</div>
                        <div class="why__card-content mt-2">
                            {{item.content_p1}}
                            <word-example v-if="item.exampleWord" v-bind:word="item.exampleWord"></word-example>
                            {{item.content_p2}}
                        </div>
                    </v-card>
                    <dots-grid class="why__cards-background-elm"
                               v-bind:color="'#c9d1de'"
                    ></dots-grid>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import WhyBackground from './WhyBackground';
    import TechnicalSupportIcon from '../../assets/icons/TechnicalSupportIcon';
    import RepeatIcon from '../../assets/icons/RepeatIcon';
    import GraphUpIcon from '../../assets/icons/GraphUpIcon';
    import SecureIcon from '../../assets/icons/SecureIcon';
    import DotsGrid from '../../assets/images/background/DotsGrid';
    import { GERMAN, getLanguageFromCode, SPANISH } from '../../utils/languages';
    import WordExample from '../popupBox/WordExample';

    export default {
        name: "Why",
        components: {WordExample, DotsGrid, WhyBackground},
        computed: {
            cards () {
                return [
                    {
                        icon: TechnicalSupportIcon,
                        title: this.$t('home.why.box1.title'),
                        content_p1: this.$t('home.why.box1.content_p1'),
                        exampleWord: 'beginner',
                        content_p2: this.$t('home.why.box1.content_p2'),
                        smallText: false,
                    },
                    {
                        icon: GraphUpIcon,
                        title: this.$t('home.why.box2.title'),
                        content_p1: this.$t('home.why.box2.content'),
                        smallText: getLanguageFromCode(this.$i18n.locale) === SPANISH,
                    },
                    {
                        icon: RepeatIcon,
                        title: this.$t('home.why.box3.title'),
                        content_p1: this.$t('home.why.box3.content_p1'),
                        exampleWord: 'results',
                        content_p2: this.$t('home.why.box3.content_p2'),
                        smallText: new Set([GERMAN, SPANISH]).has(getLanguageFromCode(this.$i18n.locale)),
                    },
                    {
                        icon: SecureIcon,
                        title: this.$t('home.why.box4.title'),
                        content_p1: this.$t('home.why.box4.content'),
                        smallText: false,
                    },
                ];
            },
        },
    }
</script>

<style scoped lang="sass">
    .why
        position: relative
        margin-bottom: 150px

        .why__content
            margin-top: 150px
            max-width: 1200px
            width: 100%

        .why__separator-title
            width: 115px
            height: 4px

        .why__title
            max-width: 100% !important
            font-size: 50px
            font-weight: bold
            text-align: center

        .why__subtitle
            font-size: 18px
            max-width: 800px
            text-align: center

        .why__cards
            width: fit-content
            position: relative

        .why__card
            width: 260px
            height: 350px
            box-shadow: 0 11px 43px #17376b20 !important

        .why__card-icon
            height: 50px
            width: 50px

        .why__card-title
            font-size: 20px
            font-weight: bold

        .why__cards-background-elm
            z-index: 0
            position: absolute
            right: -38px
            bottom: -35px
            height: 100px

        .smallText
            hyphens: auto
            font-size: 15px

        @media screen and (max-width: 1264px)
            .why__cards
                max-width: 650px

            .why__card
                max-width: 300px
                max-height: 320px
                width: 100vw
                height: 160vw
                text-align: center

            .why__card-title
                font-size: 20px
                font-weight: bold

        @media screen and (max-width: 720px)
            .why__cards
                max-width: 325px

            .why__cards-background-elm
                right: -15px

        @media screen and (max-width: 600px)
            .why__title
                font-size: 40px
                line-height: 50px

</style>