import { render, staticRenderFns } from "./WordsPlot.vue?vue&type=template&id=9fe94662&scoped=true&"
import script from "./WordsPlot.vue?vue&type=script&lang=js&"
export * from "./WordsPlot.vue?vue&type=script&lang=js&"
import style0 from "./WordsPlot.vue?vue&type=style&index=0&lang=sass&"
import style1 from "./WordsPlot.vue?vue&type=style&index=1&id=9fe94662&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9fe94662",
  null
  
)

export default component.exports

/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})
