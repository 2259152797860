<template>
    <svg class="icon" height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <clipPath :id="`clip-path_${uuid}`">
                <path d="M462,44h-47.667V30c0-16.542-13.458-30-30-30s-30,13.458-30,30v14h-168V30c0-16.542-13.458-30-30-30s-30,13.458-30,30			v14.37c-0.85-0.235-1.741-0.37-2.666-0.37H83.823c-27.57,0-50,22.43-50,50v170.333c0,5.523,4.478,10,10,10s10-4.477,10-10V147			h360.51c5.522,0,10-4.477,10-10s-4.478-10-10-10H53.823V94c0-16.542,13.458-30,30-30h39.844c0.925,0,1.816-0.136,2.666-0.37V76.5			c0,16.542,13.458,30,30,30s30-13.458,30-30V64h168v12.5c0,16.542,13.458,30,30,30s30-13.458,30-30V64H462			c16.542,0,30,13.458,30,30v379.994c0,9.928-8.077,18.006-18.006,18.006s-18.006-8.078-18.006-18.006v-25.869			c0-5.523-4.478-10-10-10H329.667c-5.522,0-10,4.477-10,10s4.478,10,10,10h106.321v15.869c0,6.511,1.648,12.643,4.545,18.006H38			c-9.925,0-18-8.075-18-18v-15.875h223.825c5.522,0,10-4.477,10-10s-4.478-10-10-10h-99.916			c17.474-15.049,28.57-37.309,28.57-62.125c0-45.215-36.785-82-82-82c-45.215,0-82,36.785-82,82			c0,24.816,11.096,47.076,28.57,62.125H10c-5.522,0-10,4.477-10,10V474c0,20.953,17.047,38,38,38h435.994			C494.95,512,512,494.951,512,473.994V94C512,66.43,489.57,44,462,44z M166.333,76.5c0,5.514-4.486,10-10,10s-10-4.486-10-10V30			c0-5.514,4.486-10,10-10s10,4.486,10,10V76.5z M394.333,76.5c0,5.514-4.486,10-10,10c-5.514,0-10-4.486-10-10V30			c0-5.514,4.486-10,10-10c5.514,0,10,4.486,10,10V76.5z M28.479,376c0-34.187,27.813-62,62-62s62,27.813,62,62s-27.813,62-62,62			S28.479,410.187,28.479,376z"/>
                <path d="M468.309,129.93c-1.859-1.86-4.439-2.93-7.069-2.93c-2.631,0-5.21,1.07-7.07,2.93c-1.86,1.86-2.93,4.44-2.93,7.07			s1.069,5.21,2.93,7.07c1.861,1.86,4.439,2.93,7.07,2.93c2.63,0,5.21-1.07,7.069-2.93c1.86-1.86,2.931-4.44,2.931-7.07			S470.17,131.79,468.309,129.93z"/>
                <path d="M298.649,441.05c-1.859-1.86-4.439-2.92-7.069-2.92s-5.21,1.06-7.07,2.92c-1.86,1.87-2.93,4.44-2.93,7.07			c0,2.64,1.069,5.21,2.93,7.08c1.86,1.86,4.44,2.92,7.07,2.92s5.21-1.06,7.069-2.92c1.86-1.87,2.931-4.45,2.931-7.08			C301.58,445.49,300.51,442.92,298.649,441.05z"/>
                <path d="M226.245,304c-20.953,0-38,17.047-38,38s17.047,38,38,38s38-17.047,38-38S247.198,304,226.245,304z M226.245,360			c-9.925,0-18-8.075-18-18s8.075-18,18-18s18,8.075,18,18S236.17,360,226.245,360z"/>
                <path d="M319.578,304c-20.953,0-38,17.047-38,38s17.047,38,38,38s38-17.047,38-38S340.531,304,319.578,304z M319.578,360			c-9.925,0-18-8.075-18-18s8.075-18,18-18s18,8.075,18,18S329.503,360,319.578,360z"/>
                <path d="M412.912,304c-20.953,0-38,17.047-38,38s17.047,38,38,38c20.953,0,38-17.047,38-38S433.865,304,412.912,304z M412.912,360			c-9.925,0-18-8.075-18-18s8.075-18,18-18s18,8.075,18,18S422.837,360,412.912,360z"/>
                <path d="M132.912,200c-20.953,0-38,17.047-38,38s17.047,38,38,38s38-17.047,38-38S153.865,200,132.912,200z M132.912,256			c-9.925,0-18-8.075-18-18s8.075-18,18-18c9.925,0,18,8.075,18,18S142.837,256,132.912,256z"/>
                <path d="M319.578,200c-20.953,0-38,17.047-38,38s17.047,38,38,38s38-17.047,38-38S340.531,200,319.578,200z M319.578,256			c-9.925,0-18-8.075-18-18s8.075-18,18-18s18,8.075,18,18S329.503,256,319.578,256z"/>
                <path d="M412.912,200c-20.953,0-38,17.047-38,38s17.047,38,38,38c20.953,0,38-17.047,38-38S433.865,200,412.912,200z M412.912,256			c-9.925,0-18-8.075-18-18s8.075-18,18-18s18,8.075,18,18S422.837,256,412.912,256z"/>
                <path d="M226.245,200c-20.953,0-38,17.047-38,38s17.047,38,38,38s38-17.047,38-38S247.198,200,226.245,200z M226.245,256			c-9.925,0-18-8.075-18-18s8.075-18,18-18s18,8.075,18,18S236.17,256,226.245,256z"/>
                <path d="M126.104,351.629c-3.906-3.905-10.236-3.905-14.143,0l-32.566,32.567l-11.129-11.129c-3.906-3.905-10.236-3.905-14.143,0			c-3.905,3.905-3.905,10.237,0,14.143l18.201,18.199c1.876,1.875,4.419,2.929,7.071,2.929c2.652,0,5.195-1.054,7.071-2.929			l39.638-39.638C130.009,361.866,130.009,355.534,126.104,351.629z"/>
            </clipPath>
            <linearGradient :id="`grad_${uuid}`"
                            :gradientTransform="`rotate(${rotateAngle})`"
                            x1="0%"
                            y1="0%"
                            x2="100%"
                            y2="0%"
            >
                <stop offset="0%" :style="`stop-color:${gradColorStart};stop-opacity:1`"/>
                <stop offset="100%" :style="`stop-color:${gradColorEnd};stop-opacity:1`"/>
            </linearGradient>
        </defs>
        <g :clip-path="`url(#clip-path_${uuid})`">
            <rect :fill="iconColor" x="0" y="0" width="512" height="512"/>
        </g>
    </svg>
</template>

<script>
    import { uuidv4 } from '../../../utils/helper';

    export default {
        name: "DaysIcon",
        props: {
            color: {
                type: String,
                default: '',
            },
            gradColorStart: {
                type: String,
                default: 'black',
            },
            gradColorEnd: {
                type: String,
                default: 'black',
            },
            rotateAngle: {
                type: Number,
                default: 0,
            }
        },
        data () {
            return {
                uuid: uuidv4(),
            }
        },
        computed: {
            iconColor () {
                if (this.color === '') {
                    return `url(#grad_${this.uuid})`;
                }
                return this.color;
            }
        },
    }
</script>

<style scoped lang="sass">
    .icon
        border-radius: 0 !important
</style>