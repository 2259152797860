<template>
    <div>
        <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="dateRange"
                transition="slide-y-transition"
                offset-y
                v-resize="onResize"
                v-bind:nudge-left="nudgeLeft"
                v-bind:nudge-bottom="5"
                style="display: block"
                min-width="auto"
        >
            <template v-slot:activator="{ on, attrs }">
                <div class="date-selector__button d-flex elevation-4 px-4 py-2"
                     ref="dropdown"
                     v-bind="attrs"
                     v-on="on">
                    <v-icon>mdi-calendar</v-icon>
                    <div class="d-flex flex-column ml-2">
                        <div class="date-selector__date-range">{{$t('analytics.date_selector.date_range')}}</div>
                        <div class="date-selector__text-field">{{dateRangeText}}</div>
                    </div>
                    <div class="grow"></div>
                    <v-icon>mdi-chevron-down</v-icon>
                </div>
            </template>
            <div v-if="isPremium"
                 class="date-selector__menu-content d-flex flex-sm-row flex-column"
            >
                <div class="d-flex flex-column pa-2">
                    <lexios-button v-for="(period, i) in fixedPeriods"
                                   v-bind:key="i"
                                   background-color="white"
                                   color-text="primary"
                                   color-text-clicked="white"
                                   background-color-clicked="primary"
                                   v-bind:clicked-state="i === indexPeriodSelected"
                                   @click.native="indexPeriodSelected = i"
                    >
                        {{period.name}}
                    </lexios-button>
                </div>
                <v-divider :vertical="!$vuetify.breakpoint.xs"></v-divider>
                <v-date-picker
                        v-model="dateRange"
                        range
                        no-title
                        scrollable
                        :locale="$i18n.locale"
                        @click:date="indexPeriodSelected = 0"
                >
                    <v-spacer></v-spacer>
                    <lexios-button
                            class="elevation-2"
                            color-text="primary"
                            background-color="white"
                            @click.native="menu = false"
                    >
                        {{$t('analytics.date_selector.cancel')}}
                    </lexios-button>
                    <lexios-button @click.native="changeDates()">
                        {{$t('analytics.date_selector.ok')}}
                    </lexios-button>
                </v-date-picker>
            </div>
            <div v-else
                 class="date-selector__menu-content-free pa-2"
            >
                {{$t('analytics.date_selector.premium_only_p1')}}
                <router-link to="/subscription">
                    {{$t('analytics.date_selector.premium_only_p2')}}
                </router-link>
                {{$t('analytics.date_selector.premium_only_p3')}}
            </div>
        </v-menu>
    </div>
</template>

<script>
    import LexiosButton from '../LexiosButton';
    import { dateMinusDayToString, posX } from '../../utils/helper';

    export default {
        name: "DateSelector",
        components: {LexiosButton},
        data () {
            return {
                dateRange: [],
                menu: false,
                indexPeriodSelected: 2,
                previousIndexPeriodSelected: 2,
                nudgeLeft: 0,
            };
        },
        props: {
            isPremium: {
                type: Boolean,
                required: true,
            },
        },
        mounted () {
            this.onIndexPeriodChange(true);
            this.$emit('date-range', this.dateRange);
            this.onResize();
        },
        methods: {
            onResize() {
                if(posX(this.$refs.dropdown) < 100){
                    this.nudgeLeft = 0;
                } else {
                    this.nudgeLeft = 150;
                }
            },
            changeDates () {
                this.previousIndexPeriodSelected = this.indexPeriodSelected;
                this.$refs.menu.save(this.dateRange);
                this.$emit('date-range', this.dateRange);
            },
            onIndexPeriodChange (isInit) {
                if(isInit){
                    this.fixedPeriods[this.indexPeriodSelected].timeFunction();
                }else{

                    this.fixedPeriods[this.indexPeriodSelected].timeFunction();
                }
            },
        },
        computed: {
            fixedPeriods () {
                return [
                    {
                        name: this.$t('analytics.date_selector.personalized'),
                        timeFunction: () => {},
                    },
                    {
                        name: this.$t('analytics.date_selector.yesterday'),
                        timeFunction: () => {
                            this.dateRange = [
                                dateMinusDayToString(1),
                                dateMinusDayToString(1),
                            ]
                        },
                    },
                    {
                        name: this.$t('analytics.date_selector.last_7_days'),
                        timeFunction: () => {
                            this.dateRange = [
                                dateMinusDayToString(7),
                                dateMinusDayToString(1),
                            ]
                        },
                    },
                    {
                        name: this.$t('analytics.date_selector.last_30_days'),
                        timeFunction: () => {
                            this.dateRange = [
                                dateMinusDayToString(30),
                                dateMinusDayToString(1),
                            ]
                        },
                    },
                    {
                        name: this.$t('analytics.date_selector.forever'),
                        timeFunction: () => {
                            this.dateRange = [
                                dateMinusDayToString(365),
                                dateMinusDayToString(1),
                            ]
                        },
                    },
                ];
            },
            dateRangeText () {
                return this.indexPeriodSelected > 0
                    ? this.fixedPeriods[this.indexPeriodSelected].name
                    : this.dateRange.join(' ~ ');
            },
        },
        watch: {
            indexPeriodSelected () {
                this.onIndexPeriodChange(false)
            },
            menu(newVal) {
                if(!newVal){
                    this.indexPeriodSelected = this.previousIndexPeriodSelected;
                }
            }
        }
    }
</script>

<style scoped lang="sass">
    .date-selector__button
        position: relative
        width: 300px
        border-radius: 30px
        background-color: white
    .date-selector__date-range
        font-size: 12px
    .date-selector__menu-content
        background-color: white
        max-width: 450px
    .date-selector__menu-content-free
        background-color: white
        max-width: 450px
</style>