<template>
    <div class="popup-button"
         @mouseover="onStartHover"
         @mouseleave="onQuitHover">
        <popup-tooltip v-if="title && isHovered"
                       v-bind:title="title"
                       v-bind:popup-size="popupSize"
        >
        </popup-tooltip>
        <div class="popup-button__content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import PopupTooltip from './PopupTooltip';
    import { debounce } from '../../utils/helper';

    export default {
        name: "PopupButton",
        components: {PopupTooltip},
        props: {
            title: {
                type: String,
                default: null,
            },
            popupSize: {
                type: String, //s, m, l, xl
                default: 's',
            },
        },
        data() {
            return {
                isHovered: false,
                hoverDebounce: debounce((isHovered) => {
                    this.isHovered = isHovered;
                    this.$emit('hovered', isHovered);
                }, 300),
            };
        },
        methods: {
            onStartHover(){
                this.hoverDebounce(true);
                this.$emit('hovered', true);
            },
            onQuitHover(){
                this.isHovered = false;
                this.$emit('hovered', false);
                this.hoverDebounce(false);
            }
        }
    }
</script>