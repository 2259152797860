<template>
    <div class="about d-flex flex-column align-center white--text">
        <about-background></about-background>
        <div class="about-title">
            {{$t('home.about.title_p1')}}
            <word-example word="vocabulary"></word-example>
            {{$t('home.about.title_p2')}}
        </div>
        <div class="about-subtitle">{{$t('home.about.title_sub')}}</div>
        <download-button class="about-download_button"
                         :height="62"
                         element-tracking-id="about-download_button"
        ></download-button>
        <div class="about-supported_languages">{{$t('home.about.supported_languages')}}</div>
        <div class="about-supported_languages_buttons d-flex">
            <lexios-button v-for="item in Object.entries(supportedLanguages)"
                           class="about-supported_languages_button"
                           v-bind:key="item[0]"
                           v-bind:clicked-state="item[0] === selectedSupportedLanguage"
                           v-bind:background-color="'disable_primary'"
                           v-bind:background-color-clicked="'white'"
                           v-bind:color-text="'white'"
                           v-bind:color-text-clicked="'secondary'"
                           v-bind:disabled="item[0] === locale"
                           v-on:click.native="updateSupported(item[0])"
            >
                <div class="d-flex">
                    <flag class="about-supported_languages_flag align-self-center"
                          v-bind:border="false"
                          v-bind:country="getLanguageCode(item[0])"
                          v-bind:size="30"></flag>
                    <b class="align-self-center ml-2">{{item[1]}}</b>
                </div>
            </lexios-button>
        </div>
        <div class="about__example mx-5 mt-7">
            <video class="about__example-content elevation-10 rounded"
                   width="800"
                   ref="about__video-player"
                   muted
                   loop
                   autoplay
                   playsinline
            >
                <source :src="currentClip" type="video/mp4">
            </video>
            <div class="about__swiss-made d-flex justify-end">
                <span class="about__swiss-made-text mr-2">{{$t('home.about.swiss_made')}}</span>
                <flag country="ch" v-bind:border="false"></flag>
            </div>
        </div>
    </div>
</template>

<script>
    import LexiosButton from '../LexiosButton';
    import Flag from '../../assets/icons/flags/Flag';
    import {
        ENGLISH,
        FRENCH,
        GERMAN,
        PORTUGUESE,
        SPANISH,
        getLanguageCode,
        getLanguageFromCode
    } from '../../utils/languages';
    import AboutBackground from './AboutBackground';
    import DownloadButton from './DownloadButton';
    import WordExample from '../popupBox/WordExample';

    export default {
        name: "About",
        components: {WordExample, DownloadButton, AboutBackground, Flag, LexiosButton},
        data () {
            return {
                windowWidth: window.innerWidth,
                selectedSupportedLanguage: this.defaultSupportedLanguage(),
            };
        },
        mounted() {
            this.$store.commit('changeTargetLanguage', this.selectedSupportedLanguage);
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize);
            })
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        computed: {
            currentClip() {
                return `https://storage.googleapis.com/website_ressources/about_clip_${this.languageClip}_${this.languageClipTarget}_${this.clipSize}.mp4`;
            },
            clipSize() {
                return this.windowWidth < 701 ? 380 : 800;
            },
            languageClipTarget() {
                return getLanguageCode(this.selectedSupportedLanguage);
            },
            languageClip() {
                return getLanguageCode(this.locale);
            },
            supportedLanguages() {
                return{
                    [ENGLISH]: this.$t('languages.english'),
                    [FRENCH]: this.$t('languages.french'),
                    [GERMAN]: this.$t('languages.german'),
                    [SPANISH]: this.$t('languages.spanish'),
                    [PORTUGUESE]: this.$t('languages.portuguese'),
                };
            },
            video () {
                return this.$refs['about__video-player'];
            },
            locale() {
                return getLanguageFromCode(this.$i18n.locale);
            }
        },
        methods: {
            getLanguageCode,
            onResize() {
                this.windowWidth = window.innerWidth;
            },
            updateSupported (newVal) {
                this.selectedSupportedLanguage = newVal;
            },
            defaultSupportedLanguage() {
                if (getLanguageFromCode(this.$i18n.locale) === ENGLISH) {
                    return FRENCH;
                }
                return ENGLISH;
            },
        },
        watch: {
            locale () {
                // Avoid having the locale and selectedSupportedLanguage to be the same value
                if (this.locale === this.selectedSupportedLanguage) {
                    if (this.locale === ENGLISH) {
                        this.selectedSupportedLanguage = SPANISH;
                    } else {
                        this.selectedSupportedLanguage = ENGLISH;
                    }
                }
            },
            selectedSupportedLanguage(val) {
                this.$store.commit('changeTargetLanguage', val);
            },
            currentClip() {
                this.video.pause();
                this.video.load();
            }
        }
    }
</script>

<style scoped lang="sass">
    .about
        width: 100%
        margin-bottom: 100px
        background: transparent
        padding-bottom: 150px
        position: relative

    .about-title
        margin-top: 60px
        max-width: 900px
        padding-left: 30px
        padding-right: 30px
        font-size: 60px
        line-height: 70px
        font-family: "Exo", sans-serif
        font-weight: bold
        text-align: center

    .about-subtitle
        margin-top: 20px
        font-size: 24px
        text-align: center
        padding-left: 15px
        padding-right: 15px

    .about-download_button
        margin-top: 20px !important

    .about-supported_languages
        margin-top: 20px
        font-family: "Exo", sans-serif
        font-weight: bold
        text-align: center
        font-size: 18px

    .about-supported_languages_buttons
        margin-top: 20px
        max-width: 800px
        flex-wrap: wrap
        justify-content: center

    .about-supported_languages_button
        height: 45px !important
        font-size: 18px !important

    .about__example
        width: fit-content

    .about__example-content
        max-width: 95vw
        max-height: 47.5vw
        width: 800px
        height: 400px
        background-color: white

    .about__swiss-made
        font-size: 14px
        width: 100%
        text-align: right

    .about__swiss-made-text
        margin-top: 2px

    @media screen and (max-width: 700px)
        .about__example-content
            max-width: 95vw
            max-height: 125vw
            width: 456px
            height: 600px

    @media screen and (max-width: 600px)
        .about-title
            font-size: 40px
            line-height: 50px
</style>