export const GERMAN = "German";
export const ENGLISH = "English";
export const FRENCH = "French";
export const PORTUGUESE = "Portuguese";
export const ITALIAN = "Italian";
export const SPANISH = "Spanish";
export const UNDEFINED = "Undefined";

export function getLanguageCode(language) {
    switch (language) {
        case ENGLISH:
            return 'en';
        case FRENCH:
            return 'fr';
        case GERMAN:
            return 'de';
        case PORTUGUESE:
            return 'pt';
        case SPANISH:
            return 'es';
        default:
            return 'en';
    }
}

export function getLanguageFromCode(code) {
    switch (code.substring(0,2)) {
        case 'en':
            return ENGLISH;
        case 'fr':
            return FRENCH;
        case 'de':
            return GERMAN;
        case 'es':
            return SPANISH;
        default:
            return ENGLISH;
    }
}