<template>
    <div>
        <lexios-button class="download_button"
                       :style="`height:${height}px !important; padding-left: ${paddingX}px !important; padding-right: ${paddingX}px !important`"
                       v-on:click.native="onClick()"
                       v-bind:background-color="'highlight'">
            {{textContent}}
        </lexios-button>
        <v-dialog v-model="dialog" max-width="500">
            <div v-if="!isMobile" class="download_button__dialog pa-4">
                <div v-html="dialogText"></div>
                <div class="d-flex mt-3 flex-wrap">
                    <a v-for="(browser, index) in browsers"
                       v-bind:key="index"
                       :href="browser.url"
                    >
                        <lexios-button>
                            <browser v-bind:browser="browser.name"
                                     class="mr-2"
                            ></browser>
                            {{browser.displayName}}
                        </lexios-button>
                    </a>
                </div>
            </div>
            <div v-else class="download_button__dialog pa-4">
                <div v-html="$t('download.dialog_mobile')"></div>
                <v-text-field class="mt-2"
                              v-bind:label="$t('download.dialog_email')"
                              hide-details="auto"
                              v-model="emailAddress"
                              v-bind:error-messages="errorMessages"
                              v-on:keyup.enter="sendEmailMobileUser"
                ></v-text-field>
                <div class="d-flex mt-2 ml-auto align-center justify-end">
                    <lexios-button class="download_button__validate ml-4"
                                   @click.native="sendEmailMobileUser"
                                   v-bind:disabled="!isValidEmail">
                        {{$t('download.dialog_validate')}}
                    </lexios-button>
                    <lexios-button class="mr-0"
                                   @click.native="dialog = false"
                                   color-text="primary"
                                   outlined>
                        {{$t('download.dialog_cancel')}}
                    </lexios-button>
                </div>
            </div>
        </v-dialog>
    </div>
</template>

<script>
    import LexiosButton from '../LexiosButton';
    import { CHROME, EDGE, getBrowser, isMobile, OPERA, debounce, validateEmail } from '../../utils/helper';
    import { EVENTS, getGlobalUserId, gtag } from '../../plugins/tracking';
    import Browser from '../../assets/icons/browsers/Browser';
    import { getLanguageFromCode } from '../../utils/languages';

    export default {
        name: "DownloadButton",
        components: {Browser, LexiosButton},
        props: {
            resizeSmall: {
                type: Boolean,
                default: false,
            },
            elementTrackingId: {
                type: String,
                default: undefined,
            },
            height: {
                type: Number,
                default: 48,
            },
        },
        data () {
            return {
                dialog: false,
                emailAddress: '',
                emailDebounce: debounce(() => {
                    if (this.emailAddress === '' || this.isValidEmail) {
                        this.errorMessage = '';
                    } else {
                        this.errorMessage = this.$t('download.dialog_wrong_email_format');
                    }
                }, 500),
                errorMessage: '',
                browsers: [
                    {
                        name: 'chrome',
                        displayName: 'Chrome',
                        url: 'https://chrome.google.com/webstore/detail/lexios/fcliomgofohffdcmapnabhpgcemjjhin',
                    },
                    //{
                    //    name: 'firefox',
                    //    displayName: 'Firefox',
                    //    url: 'https://addons.mozilla.org/firefox/addon/lexios?utm_source=lexios_website&utm_medium=lexios_website&utm_content=lexios_website',
                    //},
                    {
                        name: 'edge',
                        displayName: 'Edge',
                        url: 'https://microsoftedge.microsoft.com/addons/detail/lexios/lehjfbkmibongagpiniiblddlapbigne',
                    },
                    {
                        name: 'opera',
                        displayName: 'Opera',
                        url: 'https://chrome.google.com/webstore/detail/lexios/fcliomgofohffdcmapnabhpgcemjjhin',
                    },
                ]
            };
        },
        computed: {
            isValidEmail() {
                return validateEmail(this.emailAddress);
            },
            errorMessages() {
                return this.errorMessage ? [this.errorMessage] : [];
            },
            textContent () {
                if (this.resizeSmall) {
                    return this.$t('generic.download_button_short');
                }
                switch (getBrowser()) {
                    /*case FIREFOX:
                        return this.$t('generic.download_button_firefox');*/
                    case OPERA:
                        return this.$t('generic.download_button_opera');
                    case CHROME:
                        return this.$t('generic.download_button_short');
                    case EDGE:
                        return this.$t('generic.download_button_edge');
                    default:
                        return this.$t('generic.download_button_short');
                }
            },
            supportedBrowsers () {
                return new Set(this.browsers.map(browser => browser.name));
            },
            paddingX () {
                return this.height / 3.0;
            },
            isMobile() {
                return isMobile();
            },
            redirectURL () {
                if (this.isMobile) {
                    return null;
                }
                const browserName = getBrowser().toLowerCase();
                if (this.supportedBrowsers.has(browserName)) {
                    return this.browsers.filter(browser => browser.name === browserName)[0].url;
                }
                return null;
            },
            dialogText () {
                let output = this.$t('download.dialog_unsupported_p1');
                output += getBrowser()
                output += this.$t('download.dialog_unsupported_p2');
                return output;
            }
        },
        methods: {
            sendEmailMobileUser() {
                if (!this.isValidEmail) {
                    return;
                }

                this.dialog = false;
                const email = this.emailAddress;
                gtag('event', EVENTS.MOBILE_USER_NEWSLETTER);
                getGlobalUserId().then(globalUserId => {
                    fetch(
                        'https://us-central1-lingualess-com.cloudfunctions.net/webApi/api/v1/mobile_user_newsletter',
                        {
                            method: 'POST',
                            body: JSON.stringify({
                                globalUserId,
                                userAgent: navigator.userAgent,
                                email,
                                language: getLanguageFromCode(this.$i18n.locale),
                            }),
                        },
                    );
                });
            },
            onClick () {
                if (this.redirectURL) {
                    window.open(this.redirectURL, "_blank");
                } else {
                    this.dialog = true;
                }
                if (this.elementTrackingId) {
                    gtag('event', EVENTS.DOWNLOAD_CLICK, {elementTrackingId: this.elementTrackingId, isMobile: this.isMobile});
                } else {
                    gtag('event', EVENTS.DOWNLOAD_CLICK, {isMobile: this.isMobile});
                }
                // eslint-disable-next-line no-undef
                fbq('track', 'InitiateCheckout'); // FB tracking
                function gtag_report_conversion(url) {
                    const callback = function () {
                        if (typeof(url) != 'undefined') {
                            window.location = url;
                        }
                    };
                    gtag('event', 'conversion', {
                        'send_to': 'AW-1017208892/exjECOTwuMcCELzAheUD',
                        'event_callback': callback
                    });
                    return false;
                }
                gtag_report_conversion();
            },
        },
        watch: {
            emailAddress() {
                this.emailDebounce();
            }
        }
    }
</script>

<style scoped lang="sass">
    .download_button
        font-size: 18px !important
        font-weight: bold

    .download_button__dialog
        background-color: white

    .download_button__validate
        height: 36px !important
</style>