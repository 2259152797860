<template>
    <div :style="`position: relative; height: ${size}px; width: ${size}px;`">
        <img class="browser"
             :style="`position: absolute; top: 0; left: 0;
             height: ${size}px;
             width: ${size}px;`"
             :src="browserSvg"/>
    </div>
</template>

<script>
    const browsers = {}
    browsers.chrome = require("./chrome.svg")
    browsers.firefox = require("./firefox.svg")
    browsers.edge = require("./edge.svg")
    browsers.opera = require("./opera.svg")

    export default {
        name: 'Browser',
        props: {
            browser: {
                type: String,
                required: true,
            },
            size: {
                type: Number,
                default: 25,
            },
        },
        computed: {
            browserSvg () {
                return browsers[this.browser];
            },
        }
    }
</script>

<style lang="sass" scoped>
</style>