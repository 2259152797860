import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../components/homePage/Home.vue';
import TermsPage from '../components/TermsPage';
import PrivacyPage from '../components/PrivacyPage';
//import SubscriptionPage from '../components/subscription/SubscriptionPage';
import AnalyticsPage from '../components/analytics/Analytics';
import Bookmarks from '../components/bookmark/Bookmarks';
import Achievements from '../components/achievements/Achievements';
import { getFlags } from '../flags';
import WelcomePage from '../components/WelcomePage';

Vue.use(VueRouter);

const routes = [
    ...(getFlags().premium_exists ? [
        //{path: '/subscription', component: SubscriptionPage},
        {path: '/bookmarks', component: Bookmarks},
        {path: '/analytics', component: AnalyticsPage},
        {path: '/achievements', component: Achievements},
    ] : []),
    {path: '/home/:section/:subsection', component: Home},
    {path: '/home/:section', component: Home},
    {path: '/', component: Home},
    {path: '/home', component: Home},
    {path: '/policies/terms-of-service', component: TermsPage},
    {path: '/policies/privacy-policy', component: PrivacyPage},
    {path: '/welcome', component: WelcomePage},
    {path: '/:other', component: Home}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
