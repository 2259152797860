<template>
    <div>
        <div class="d-flex align-center flex-wrap">
            <v-checkbox class="words-list__global-action pt-0 my-1 mx-4"
                        hide-details
                        v-model="showAll"
            >
                <template v-slot:label>
                        <span class="words-list__reveal-all-label">
                            {{$t('analytics.recent_words.reveal_all')}}
                        </span>
                </template>
            </v-checkbox>
            <v-checkbox class="words-list__global-action pt-0 my-1 mx-4"
                        hide-details
                        v-bind:disabled="showAll"
                        v-model="reverseAll"
            >
                <template v-slot:label>
                        <span class="words-list__reveal-all-label">
                            {{$t('analytics.recent_words.reverse_spoilers')}}
                        </span>
                </template>
            </v-checkbox>
            <v-checkbox class="words-list__global-action pt-0 my-1 mx-4"
                        hide-details
                        v-model="shuffle"
            >
                <template v-slot:label>
                        <span class="words-list__shuffle-label">
                            {{$t('analytics.recent_words.shuffle')}}
                        </span>
                </template>
            </v-checkbox>
        </div>
        <v-data-table v-if="$vuetify.breakpoint.mdAndUp"
                      v-bind:items="displayedData"
                      class="words-list__data-table mt-2"
                      :items-per-page="itemsPerPage"
                      @update:items-per-page="updateItemsPerPage"
                      :mobile-breakpoint="0"
        >
            <template v-slot:body="{ items }">
                <thead>
                <tr>
                    <th class="text-right words-list__table-headers pr-0" v-if="hasMultipleSourceLanguages">
                        {{$t('analytics.recent_words.source')}}
                    </th>
                    <th class="words-list__original-word words-list__table-headers">
                        {{$t('analytics.recent_words.original_word')}}
                    </th>
                    <th class="words-list__translation words-list__table-headers flex">
                        <div class="words-list__translation-title">
                            {{$t('analytics.recent_words.word_expression')}}
                        </div>
                    </th>
                    <th class="words-list__type words-list__table-headers">
                        {{$t('analytics.recent_words.type')}}
                    </th>
                    <th class="text-center words-list__table-headers">
                        {{$t('analytics.recent_words.listen')}}
                    </th>
                    <th class="text-center words-list__table-headers" v-html="$t('analytics.recent_words.bookmark')">
                    </th>
                    <th class="text-center words-list__table-headers" v-html="$t('analytics.recent_words.word_mastery')">
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(row, i) in items"
                    :key="i"
                >
                    <td class="pr-0" v-if="hasMultipleSourceLanguages">
                        <flag class="ma-auto mr-0" :country="row.sourceLanguageCode"></flag>
                    </td>
                    <td>
                        <lexios-spoiler class="grow"
                                        v-bind:is-visible="visibleSpoilerReversed[row.origin]"
                                        @is-visible="setSpoiled(row.origin, row.sourceLanguageCode, row.w, targetLanguageCode)"
                        >
                            {{row.origin}}
                        </lexios-spoiler>
                    </td>
                    <td>
                        <lexios-spoiler v-bind:is-visible="visibleSpoiler[row.w]"
                                        @is-visible="setSpoiled(row.w, targetLanguageCode, row.origin, row.sourceLanguageCode)"
                        >
                            {{row.translation}}
                        </lexios-spoiler>
                    </td>
                    <td>{{row.type}}</td>
                    <td>
                        <lexios-icon-button class="ma-auto"
                                            v-bind:title="$t('popupbox.hear_word')"
                                            color="primary"
                                            hovered-color="background_primary"
                                            icon-name="mdi-volume-high"
                                            @click.native="speakWord(row.translation, targetLanguage)"
                        >
                        </lexios-icon-button>
                    </td>
                    <td>
                        <lexios-icon-button class="ma-auto"
                                            color="primary"
                                            v-bind:title="row.lem in bookmarked ? $t('popupbox.unbookmark') : $t('popupbox.bookmark')"
                                            hovered-color="background_primary"
                                            v-bind:icon-name="row.lem in bookmarked ? 'mdi-book-remove' : 'mdi-book-plus-outline'"
                                            @click.native="updateBookmarkState(row)"
                        >
                        </lexios-icon-button>
                    </td>
                    <td class="d-flex">
                        <v-progress-circular class="ma-auto"
                                             :value="wordMastery(row.lem)"
                                             color="primary"
                        >
                            {{wordMastery(row.lem)}}
                        </v-progress-circular>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-data-table>
        <v-data-iterator v-else
                         v-bind:items="displayedData"
                         v-bind:items-per-page="itemsPerPage"
                         @update:items-per-page="updateItemsPerPage"
                         v-bind:mobile-breakpoint="0"
            >
                <template v-slot:default="{ items }">
                    <div class="d-flex flex-wrap">
                        <div v-for="(row, key) in items"
                                   v-bind:key="key"
                                   class="words-list__voc-card pa-2 ma-2 elevation-3 rounded grow"
                        >
                            <div class="d-flex flex-row mb-2 justify-space-between">
                                <lexios-icon-button v-bind:title="$t('popupbox.hear_word')"
                                                    color="primary"
                                                    hovered-color="background_primary"
                                                    icon-name="mdi-volume-high"
                                                    @click.native="speakWord(row.translation, targetLanguage)"
                                >
                                </lexios-icon-button>
                                <lexios-icon-button color="primary"
                                                    v-bind:title="row.lem in bookmarked ? $t('popupbox.unbookmark') : $t('popupbox.bookmark')"
                                                    hovered-color="background_primary"
                                                    v-bind:icon-name="row.lem in bookmarked ? 'mdi-book-remove' : 'mdi-book-plus-outline'"
                                                    @click.native="updateBookmarkState(row)"
                                >
                                </lexios-icon-button>
                            </div>
                            <div class="d-flex mb-2">
                                <flag class="mr-2" :country="row.sourceLanguageCode"></flag>
                                <lexios-spoiler class="grow"
                                                v-bind:is-visible="visibleSpoilerReversed[row.origin]"
                                                @is-visible="setSpoiled(row.origin, row.sourceLanguageCode, row.w, targetLanguageCode)"
                                >
                                    {{row.origin}}
                                </lexios-spoiler>
                            </div>
                            <div class="words-list__voc-card-translation d-flex mb-3">
                                <span class="mr-2">{{row.type}}:</span>
                                <lexios-spoiler class="grow"
                                                v-bind:is-visible="visibleSpoiler[row.w]"
                                                @is-visible="setSpoiled(row.w, targetLanguageCode, row.origin, row.sourceLanguageCode)"
                                >
                                    {{row.translation}}
                                </lexios-spoiler>
                            </div>
                            <div class="words-list__voc-card-word-mastery d-flex">
                                {{$t('analytics.recent_words.word_mastery_inline')}}
                            </div>
                            <div class="d-flex">
                                <v-progress-linear class="ma-auto mr-4 rounded"
                                                   :value="wordMastery(row.lem)"
                                                   color="primary"
                                                   height="7"
                                >
                                </v-progress-linear>
                                {{wordMastery(row.lem)}}%
                            </div>
                        </div>
                    </div>
                </template>
            </v-data-iterator>
    </div>
</template>

<script>
    import { ENGLISH, FRENCH, GERMAN, getLanguageFromCode, PORTUGUESE, SPANISH } from '../utils/languages';
    import LexiosIconButton from './LexiosIconButton';
    import { shuffle, speakWord } from '../utils/helper';
    import LexiosSpoiler from './LexiosSpoiler';
    import Flag from '../assets/icons/flags/Flag';
    import { getLemsScores } from './analytics/userLems';
    import { mapGetters } from 'vuex';
    import firebase from "firebase";
    import 'firebase/firestore';
    import { FIRESTORE_BOOKMARK_PATH } from './connection/databasePaths';
    import { EVENTS, gtag } from '../plugins/tracking';

    export default {
        name: "WordsList",
        components: {Flag, LexiosSpoiler, LexiosIconButton},
        props: {
            data: {
                type: Array,
                required: true,
            },
            lemsData: {
                type: Map,
                required: true,
            },
            targetLanguageCode: {
                type: String,
                required: true,
            },
            bookmarked: {
                type: Object,
                required: true,
            },
            defaultItemsPerPage: {
                type: Number,
                default: 5,
            },
        },
        data () {
            return {
                showAll: false,
                reverseAll: false,
                shuffle: false,
                shuffledData: null,
                visibleSpoiler: Object.create(null),
                visibleSpoilerReversed: Object.create(null),
                itemsPerPage: this.defaultItemsPerPage,
            };
        },
        mounted () {
            this.onDataChange();
        },
        computed: {
            ...mapGetters([
                'firebaseUser', 'userProfile',
            ]),
            lemsToWordMastery() {
                return getLemsScores(this.lemsData, this.formattedData.map(_ => _.lem), 50)
            },
            targetLanguage () {
                return getLanguageFromCode(this.targetLanguageCode);
            },
            hasMultipleSourceLanguages() {
                return new Set(this.formattedData.map(_ => _.sourceLanguageCode)).size > 1;
            },
            bookmarkPath () {
                return this.targetLanguageCode + FIRESTORE_BOOKMARK_PATH;
            },
            formattedData () {
                const isGender = row => {
                    return row.g && row.g !== '-';
                }

                const determinant = row => {
                    switch (this.targetLanguage) {
                        case ENGLISH:
                            return '';
                        case GERMAN:
                            if (row.p) {
                                return 'die ';
                            }
                            switch (row.g) {
                                case 'nt':
                                    return 'das ';
                                case 'm':
                                    return 'der ';
                                case 'f':
                                    return 'die ';
                            }
                            return '';
                        case FRENCH:
                            if (row.p) {
                                return 'des ';
                            }
                            switch (row.g) {
                                case 'm':
                                    return 'un ';
                                case 'f':
                                    return 'une ';
                            }
                            return '';
                        case SPANISH:
                            if (row.p) {
                                switch (row.g) {
                                    case 'm':
                                        return 'los ';
                                    case 'f':
                                        return 'las ';
                                }
                                return '';
                            }
                            switch (row.g) {
                                case 'm':
                                    return 'el ';
                                case 'f':
                                    return 'la ';
                            }
                            return '';
                        case PORTUGUESE:
                            if (row.p) {
                                switch (row.g) {
                                    case 'm':
                                        return 'os ';
                                    case 'f':
                                        return 'as ';
                                }
                                return '';
                            }
                            switch (row.g) {
                                case 'm':
                                    return 'o ';
                                case 'f':
                                    return 'a ';
                            }
                            return '';
                        default:
                            return '';
                    }
                }

                const typeDisplay = row => {
                    let output = this.$t(`popupbox.word_types.${row.t}`);
                    if (isGender(row) || row.p) {
                        output += ' (';
                        if (isGender(row)) {
                            output += this.$t(`popupbox.word_genders.${row.g}`);
                            if (row.p) {
                                output += ', ';
                            }
                        }
                        if (row.p) {
                            output += row.p ? this.$t('popupbox.plural_abrev') : this.$t('popupbox.singular_abrev');
                        }
                        output += ')';
                    }
                    return output;
                }

                return this.data.map(row => {
                    return {
                        translation: determinant(row) + row.w,
                        w: row.w,
                        p: row.p,
                        g: row.g,
                        origin: row.o,
                        lem: row.l,
                        t: row.t,
                        type: typeDisplay(row),
                        sourceLanguageCode: row.s,
                    };
                }).reverse();
            },
            displayedData() {
                return this.shuffle ? this.shuffledData : this.formattedData;
            },
        },
        methods: {
            updateItemsPerPage(val){
                this.itemsPerPage = val;
            },
            updateBookmarkState(row) {
                const lem = row.lem;
                const isBookmarked = lem in this.bookmarked;

                // We invert bookmarked state, this is the event after the change! :)
                gtag('event', EVENTS.WORDS_LIST, {event_category: 'bookmarked', bool_value: !isBookmarked});
                if (isBookmarked) {
                    firebase.firestore().collection(this.bookmarkPath).doc(this.firebaseUser.uid)
                        .update({[lem]: firebase.firestore.FieldValue.delete()})
                        .catch(error => {
                            console.error("Error writing document firestore: ", error);
                        });
                } else {
                    const bookmarkedObject = {
                        o: row.origin,
                        w: row.w,
                        s: row.sourceLanguageCode,
                        t: row.t,
                        time: Date.now(),
                    };
                    if (row.g !== undefined) {
                        bookmarkedObject.g = row.g;
                        bookmarkedObject.p = row.p;
                    }
                    firebase.firestore().collection(this.bookmarkPath).doc(this.firebaseUser.uid)
                        .set({[lem]: bookmarkedObject}, {merge: true})
                        .catch(error => {
                            console.error("Error writing document firestore: ", error);
                        });
                }
            },
            wordMastery(lem) {
                return Math.round(this.lemsToWordMastery.get(lem) * 100);
            },
            speakWord (toPronounce) {
                gtag('event', EVENTS.WORDS_LIST, {event_category: 'speak', toPronounce});
                speakWord(toPronounce, this.targetLanguage);
            },
            onDataChange() {
                this.data.forEach(row => {
                    if(this.reverseAll){
                        if (!(row.o in this.visibleSpoilerReversed)) {
                            this.visibleSpoilerReversed[row.o] = this.showAll;
                        }
                        if (!(row.w in this.visibleSpoiler)) {
                            this.visibleSpoiler[row.w] = true;
                        }
                    } else {
                        if (!(row.w in this.visibleSpoiler)) {
                            this.visibleSpoiler[row.w] = this.showAll;
                        }
                        if (!(row.o in this.visibleSpoilerReversed)) {
                            this.visibleSpoilerReversed[row.o] = true;
                        }
                    }
                })
                this.visibleSpoiler = Object.assign({}, this.visibleSpoiler);
                this.visibleSpoilerReversed = Object.assign({}, this.visibleSpoilerReversed);
            },
            setSpoiled(spoiledWord, spoiledLanguageCode, otherWord, otherLanguageCode) {
                gtag(
                    'event',
                    EVENTS.WORDS_LIST,
                    {
                        event_category: 'clickSpoiler',
                        spoiledWord,
                        spoiledLanguageCode,
                        otherWord,
                        otherLanguageCode,
                    },
                );
                if (this.reverseAll) {
                    this.$set(this.visibleSpoilerReversed, spoiledWord, true);
                } else {
                    this.$set(this.visibleSpoiler, spoiledWord, true);
                }
            },
            updateAllSpoilers(){
                Object.keys(this.visibleSpoiler)
                    .forEach(key => this.$set(this.visibleSpoiler, key, this.reverseAll || this.showAll));
                Object.keys(this.visibleSpoilerReversed)
                    .forEach(key => this.$set(this.visibleSpoilerReversed, key, !this.reverseAll || this.showAll));
            },
        },
        watch: {
            data () {
                this.onDataChange();
            },
            showAll(newVal) {
                gtag('event', EVENTS.WORDS_LIST, {event_category: 'showAll', bool_value: newVal});
                this.updateAllSpoilers();
            },
            reverseAll(newVal) {
                gtag('event', EVENTS.WORDS_LIST, {event_category: 'reverseAll', bool_value: newVal});
                this.updateAllSpoilers();
            },
            itemsPerPage(newVal) {
                gtag('event', EVENTS.WORDS_LIST, {event_category: 'itemsPerPage', bool_value: newVal});
                // Informs when the component is long to update parent layout
                if(newVal !== 5) {
                    this.$emit('long', true);
                } else {
                    this.$emit('long', false);
                }
            },
            formattedData(newVal, oldVal) {
                if (!this.shuffle) {
                    return;
                }

                if (oldVal.length > newVal.length) {
                    const newSet = new Set(newVal.map(x => x.w+x.t));
                    oldVal.map(x => x.w+x.t).forEach(x => {
                        if(!newSet.has(x)) {
                            this.shuffledData = this.shuffledData.filter(x1 => x1.w+x1.t !== x);
                        }
                    })
                } else if (oldVal.length < newVal.length) {
                    const oldSet = new Set(oldVal.map(x => x.w+x.t));
                    newVal.map(x => x.w+x.t).forEach(x => {
                        if(!oldSet.has(x)) {
                            this.shuffledData.unshift(newVal.filter(x1 => x1.w+x1.t === x)[0]);
                        }
                    })
                }
            },
            shuffle(newVal) {
                gtag('event', EVENTS.WORDS_LIST, {event_category: 'shuffle', bool_value: newVal});
                if (newVal) {
                    this.shuffledData = shuffle([...this.formattedData]);
                } else {
                    this.shuffledData = null;
                }
            }
        },
    }
</script>

<style scoped lang="sass">
    .words-list__data-table
        ::v-deep .v-data-table__wrapper
            overflow-y: unset !important
            overflow-x: unset !important

    .words-list__table-headers
        font-size: 14px !important

    .words-list__type
        min-width: 110px

    .words-list__original-word
        min-width: 180px

    .words-list__translation
        min-width: 250px

    .words-list__reveal-all
        margin-left: 4px
        margin-top: 0

    .words-list__global-action
        font-weight: bold
        font-size: 16px

    .words-list__empty-box
        max-width: 100vw
        width: 500px
        height: 300px

    .words-list__voc-card
        min-width: 250px

    .words-list__voc-card-translation
        min-width: 230px

    .words-list__voc-card-word-mastery
        font-size: 14px
</style>