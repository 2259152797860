<template>
    <div class="time-plot" v-resize="onResize" ref="time_plot">
        <div id="time-plot-d3" :style="`display: ${visibility}`"></div>
        <div class="time-plot__tooltip elevation-2 rounded pa-2" id="time-plot-tooltip">
            <div class="time-plot__tooltip-date">{{this.infoBoxTitle}}</div>
            <div class="d-flex justify-space-between">
                <div>
                    <span class="time-plot__tooltip-dot primary"></span>
                    {{$t('analytics.time.daily')}}
                </div>
                <span class="time-plot__tooltip-daily">{{hoveredData && Math.round(hoveredData.time) + $t('analytics.time.minutes')}}</span>
            </div>
            <div class="d-flex justify-space-between">
                <div>
                    <span class="time-plot__tooltip-dot highlight"></span>
                    {{$t('analytics.time.average_7_days')}}
                </div>
                <span class="time-plot__tooltip-average">{{hoveredData && Math.round(hoveredData.timeWeeklyAverage) + $t('analytics.time.minutes')}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import { daysBetween } from '../../utils/time';

    import * as d3 from "d3";
    import { debounce, viewPortWidth } from '../../utils/helper';

    export default {
        name: "TimePlot",
        props: {
            times: {
                required: true,
            },
            startDate: {
                type: String,
                required: true,
            },
            endDate: {
                type: String,
                required: true,
            },
        },
        data () {
            return {
                defaultWidth: 450,
                scale: 1,
                debounceResize: debounce(this.computeSvgDimensions, 400),
                visibility: 'block',
                hoveredData: null,
            };
        },
        mounted () {
            this.visibility = 'none';
            this.debounceResize();
        },
        methods: {
            onResize(){
                this.visibility = 'none';
                this.debounceResize();
            },
            computeSvgDimensions() {
                this.visibility = 'block';
                const oldWidth = this.defaultWidth;
                const screenWidth = viewPortWidth();
                if (screenWidth > 795) {
                    this.defaultWidth = this.$refs.time_plot.clientWidth - 4;
                } else {
                    this.defaultWidth = screenWidth - 5 * 16;
                }
                if (this.defaultWidth < 450 && screenWidth <= 795) {
                    this.scale = this.defaultWidth / 450;
                } else if (screenWidth <= 1600) {
                    this.scale = 1;
                } else {
                    this.scale = 1.145;
                }
                if (oldWidth !== this.defaultWidth) {
                    this.createPlot();
                }
            },
            createPlot () {
                const width = this.defaultWidth;
                const height = 320 * this.scale;
                const marginLeft = 40;
                const marginRight = 10;
                const marginBottom = 25;
                const marginTop = 5;
                const realWidth = width - marginLeft - marginRight;
                const realHeight = height - marginTop - marginBottom;

                // eslint-disable-next-line no-debugger
                //debugger;
                d3.select("#time-plot-d3").selectAll("*").remove();
                const svg = d3.select("#time-plot-d3").append('svg')
                    .attr("width", width)
                    .attr("height", height);

                const xScale = d3.scalePoint().range([0, realWidth]).padding(0.2 * this.scale),
                    yScale = d3.scaleLinear().range([realHeight, 0]);

                const g = svg.append("g")
                    .attr("transform", "translate(" + marginLeft + "," + marginTop + ")");

                xScale.domain(this.times.map(d => this.axisConversion(d.date)));
                const maxY = d3.max(this.times, d => Math.max(d.time, d.timeWeeklyAverage))
                yScale.domain([0, maxY]);

                // Sometimes NaN at first => redraw
                if (isNaN(yScale(1))) {
                    setTimeout(this.createPlot, 50);
                    return;
                }

                let i = 0;
                g.append("g")
                    .attr("transform", "translate(0," + realHeight + ")")
                    .call(d3.axisBottom(xScale).tickFormat(d => {
                        if (this.periodDays > 7) {
                            if (i++ % Math.ceil(this.periodDays / 7) === 0) {
                                return d;
                            }
                            return '';
                        }
                        return d;
                    })
                        .tickSize(0)
                    )
                    .style("font-size","14px")
                    .selectAll('text')
                    .attr('transform',`translate(0,${marginBottom/5})`);

                g.append("g")
                    .call(
                        d3.axisLeft(yScale)
                            .ticks(5)
                            .tickFormat(d => `${d}`)
                            .tickSize(-width + marginLeft + marginRight)
                    )
                    .call(g => g.select(".domain").remove())
                    .style("font-size","14px")
                    .selectAll('line')
                    .attr("fill", "none")
                    .style("stroke", "rgb(172,172,172)")
                    .attr("stroke-width", 1)

                g.append("path")
                    .datum(this.times)
                    .attr("fill", "none")
                    .attr("stroke", "#ed540d")
                    .attr("stroke-width", 2.5)
                    .attr("d", d3.line()
                        .x(d => xScale(this.axisConversion(d.date)))
                        .y(d => yScale(d.timeWeeklyAverage))
                    );

                g.append("path")
                    .datum(this.times)
                    .attr("fill", "none")
                    .attr("stroke", "#3f79fc")
                    .attr("stroke-width", 2.5)
                    .attr("d", d3.line()
                        .x(d => xScale(this.axisConversion(d.date)))
                        .y(d => yScale(d.time))
                    );

                //region Create the circle that travels along the curve of chart, and infobox
                const infobox = d3.select("#time-plot-tooltip").style("opacity", 0);

                const verticalLine = g
                    .append('g')
                    .append("line")
                    .style("stroke-dasharray", "5,2")
                    .attr("fill", "none")
                    .attr("stroke", "rgb(101,101,101)")
                    .attr("stroke-width", 2)
                    .attr("y1", yScale.range()[0])
                    .attr("y2", yScale.range()[1])
                    .style("opacity", 0);

                const focusDaily = g
                    .append('g')
                    .append('circle')
                    .style("fill", "#3f79fc")
                    .attr('r', 5)
                    .style("opacity", 0);

                const focusAverage = g
                    .append('g')
                    .append('circle')
                    .style("fill", "#ed540d")
                    .attr('r', 5)
                    .style("opacity", 0);

                let oldYPos = null;
                const mousemove = (event) => {
                    const xPos = d3.pointer(event)[0];
                    const domain = xScale.domain();
                    const range = xScale.range();
                    const rangePoints = d3.range(range[0], range[1], xScale.step())
                    const yPos = domain[d3.bisect(rangePoints, xPos) - 1];

                    if (oldYPos !== yPos) {
                        oldYPos = yPos;
                        const matchPoint = this.timesFormatted.filter(_ => _.timeGraph === yPos)[0]
                        this.hoveredData = matchPoint
                        const xMatch = xScale(yPos);
                        const yMatchDaily = yScale(matchPoint.time);
                        const yMatchAverage = yScale(matchPoint.timeWeeklyAverage);
                        infobox.transition()
                            .duration(200)
                            .style("opacity", .9);
                        infobox
                            .style("left", Math.max(20, xMatch - 180) + "px")
                            .style("top", "-85px");
                        focusDaily
                            .attr("transform", "translate(" + xMatch + "," + yMatchDaily + ")")
                            .style("opacity", 1);
                        verticalLine
                            .attr("transform", "translate(" + xMatch + ",0)")
                            .style("opacity", 1);
                        focusAverage
                            .attr("transform", "translate(" + xMatch + "," + yMatchAverage + ")")
                            .style("opacity", 1);
                    }
                };
                const removeFocus = () => {
                    oldYPos = null;
                    focusDaily.style("opacity", 0);
                    focusAverage.style("opacity", 0);
                    verticalLine.style("opacity", 0);
                    infobox.transition()
                        .duration(200)
                        .style("opacity", 0);
                }

                svg
                    .append('rect')
                    .style("fill", "none")
                    .style("pointer-events", "all")
                    .attr('width', realWidth)
                    .attr('height', realHeight)
                    .attr("transform", "translate(" + marginLeft + "," + 0 + ")")
                    .on('mousemove', mousemove)
                    .on('mouseleave', removeFocus);
                //endregion
            },
            axisConversion (date) {
                if (this.periodDays <= 7) {
                    return date.toLocaleString(this.$i18n.locale, {weekday: 'short'});
                } else {
                    return date.toLocaleString(this.$i18n.locale, {month: '2-digit', day: '2-digit'});
                }
            },
        },
        computed: {
            locale() {
                return this.$i18n.locale;
            },
            infoBoxTitle() {
                return this.hoveredData ? this.hoveredData.date.toLocaleString(this.$i18n.locale, {month: 'short', day: 'numeric'}) : '';
            },
            periodDays () {
                return daysBetween(new Date(this.startDate), new Date(this.endDate));
            },
            timesFormatted() {
                return this.times.map(x => {
                    return {
                        date: x.date,
                        time: x.time,
                        timeWeeklyAverage: x.timeWeeklyAverage,
                        timeGraph: this.axisConversion(x.date),
                    };
                })
            },
        },
        watch: {
            scale () {
                this.createPlot();
            },
            times () {
                this.visibility = 'none';
                this.debounceResize();
                this.createPlot();
            },
            locale(){
                this.visibility = 'none';
                this.debounceResize();
            },
        }
    }
</script>
<style lang="sass">
    .bar_free
        fill: #3F79FC

    .bar_premium
        fill: #ed540d

    .bar_hover
        fill: transparent
    .bar_hover:hover
        fill: rgba(255, 255, 255, 0.2)
</style>
<style scoped lang="sass">
    .time-plot
        position: relative

    .time-plot__tooltip
        position: absolute
        text-align: left
        width: 250px
        //height: 28px
        padding: 2px
        font-size: 14px
        background: white
        font-weight: 500
        border: 0
        z-index: 1
        pointer-events: none

    .time-plot__tooltip-date
        font-weight: bold

    .time-plot__tooltip-dot
        width: 10px
        height: 10px
        border-radius: 10px
        display: inline-block
</style>