<template>
    <div class="time-section pa-4 d-flex elevation-4 flex-row">
        <div class="d-flex flex-column grow">
            <div class="time-section__title secondary--text mb-2 d-flex">
                <div class="mr-2">{{$t('analytics.time.title')}}</div>
                <lexios-icon-button v-bind:title="$t('analytics.time.title_info')"
                                    icon-name="mdi-help-circle"
                                    color="secondary"
                                    hovered-color="background_secondary"
                                    popup-size="l"
                ></lexios-icon-button>
            </div>
            <time-plot v-if="isPremium"
                       v-bind:times="times"
                       v-bind:end-date="endDate"
                       v-bind:start-date="startDate"
            ></time-plot>
        </div>
        <div v-if="isPremium" class="time-section__boxes d-flex flex-row flex-wrap">
            <div class="time-section__info-box primary">
                <div class="time-section__info-box-intro">
                    {{daysCount === 7
                    ? $t('analytics.words.info_box_premium_display_weekly')
                    : $t('analytics.words.info_box_premium_display_p1') + daysCount + $t('analytics.words.info_box_premium_display_p2')}}
                </div>
                <div class="time-section__info-box-data">{{totalTime.toLocaleString() + $t('analytics.time.minutes')}}</div>
            </div>
            <div class="time-section__info-box highlight">
                <div class="time-section__info-box-intro">
                    {{$t('analytics.words.info_box_average')}}
                </div>
                <div class="time-section__info-box-data">{{averageTime.toLocaleString() + $t('analytics.time.minutes')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import TimePlot from './TimePlot';
    import { MINUTE } from '../../utils/time';
    import LexiosIconButton from '../LexiosIconButton';
    export default {
        name: "TimeSection",
        components: {LexiosIconButton, TimePlot},
        props: {
            analyticsData : {
                required: true,
            },
            startDate: {
                type: String,
                required: true,
            },
            endDate: {
                type: String,
                required: true,
            },
            isPremium: {
                type: Boolean,
                required: true,
            },
        },
        computed: {
            averageTime() {
                return Math.round(this.totalTime/this.times.length);
            },
            daysCount() {
                return this.times.length;
            },
            totalTime() {
                return Math.round(this.times.map(d => d.time).reduce((a, b) => a + b, 0));
            },
            times () {
                if (!this.analyticsData) {
                    return [];
                }

                const days = 7;
                const movingAverageWeekly = new Array(days);
                return Object.entries(this.analyticsData).map(pair => {
                    return {
                        date: new Date(pair[0]),
                        time: (pair[1].t || 0) / MINUTE,
                    }
                })
                    .sort((a, b) => a.date - b.date)
                    .map(x => {
                        movingAverageWeekly.shift()
                        movingAverageWeekly.push(x.time/days)
                        return {
                            date: x.date,
                            time: x.time,
                            timeWeeklyAverage: movingAverageWeekly.reduce((a, b) => a + b, 0),
                        }
                    }).filter(x => {
                        return x.date >= new Date(this.startDate) && x.date <= new Date(this.endDate);
                    });
            }
        }
    }
</script>

<style scoped lang="sass">
    .test
        width: 100%

    .time-section
        position: relative
        background-color: white
        border-radius: 30px
        width: max-content
        //max-width: 800px
    .time-section__title
        font-size: 24px
        font-weight: bold
    .time-section__boxes
        z-index: 0
    .time-section__info-box
        display: flex
        flex-direction: column
        justify-content: center
        color: white
        padding: 16px
        margin: 8px
        border-radius: 20px
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4)
        text-align: center
    .time-section__info-box-intro
        font-size: 16px
        ::v-deep a
            color: white
            text-decoration: underline !important
    .time-section__info-box-data
        font-size: 24px
        font-weight: bold
    .time-section__premium-plan-info
        align-self: center
        max-width: 250px
        text-align: center
        ::v-deep a
            text-decoration: underline !important

    @media screen and (max-width: 795px)
        .time-section
            flex-direction: column !important
        .time-section__boxes
            align-items: stretch
        .time-section__premium-plan-info
            max-width: 100%
            padding: 4px !important
            padding-top: 8px !important

    @media screen and (max-width: 599px)
        .time-section__boxes
            justify-content: center

    @media screen and (max-width: 532px)
        .time-section__info-box
            width: 100%
            padding: 8px !important
            margin: 4px !important
</style>