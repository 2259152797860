<template>
    <div class="contact">
        <div class="d-flex flex-column align-center px-4">
            <div class="contact__separator-title rounded-pill background_primary"></div>
            <div class="contact__title mt-4">
                {{$t('home.contact.title')}}
            </div>
            <div class="contact__subtitle mt-4">
                {{$t('home.contact.subtitle_p1')}}
                <word-example word="partnerships"></word-example>
                {{$t('home.contact.subtitle_p2')}}
            </div>
            <div class="d-flex flex-wrap justify-center">
                <a class="contact__email-button-a" href="mailto:contact@lexios.io" target="_blank">
                    <lexios-button class="contact__email-button mt-4"
                                   v-bind:background-color="'highlight'"
                                   v-bind:color-text="'highlight'"
                                   outlined
                    >
                        <div class="d-flex align-center">
                            <v-icon class="contact__email-button-icon mr-2">mdi-email-outline</v-icon>
                            <span class="secondary--text">contact@lexios.io</span>
                        </div>
                    </lexios-button>
                </a>
                <a class="contact__discord-button-a"
                   :href="$t('home.contact.join_discord_link')"
                   target="_blank"
                   rel="nofollow noopener"
                >
                    <lexios-button class="contact__discord-button mt-4"
                                   v-bind:background-color="'highlight'"
                                   v-bind:color-text="'highlight'"
                                   outlined
                    >
                        <div class="d-flex align-center">
                            <v-icon class="contact__discord-button-icon mb-1 mr-2">mdi-discord</v-icon>
                            <span class="contact__discord-button-text secondary--text">{{$t('home.contact.join_discord')}}</span>
                        </div>
                    </lexios-button>
                </a>
            </div>
        </div>
        <div class="contact__background-wave">
            <contact-wave-background></contact-wave-background>
            <div class="contact__bottom-content d-flex justify-center">
                <div class="contact__medias d-flex flex-row align-center flex-wrap justify-center">
                    <logo-with-text class="contact__medias-logo"
                                    v-bind:height="100"
                                    inline-logo
                                    v-bind:text-color="'white'">
                    </logo-with-text>
                    <div class="d-flex ml-8 py-4">
                        <a href="https://www.facebook.com/getLexios/" target="_blank" rel="nofollow noopener">
                            <img class="contact__medias-social-icon"
                                 src="../../assets/icons/socialMedias/facebook.svg"
                                 alt="facebook">
                        </a>
                        <a href="https://twitter.com/getlexios" target="_blank" rel="nofollow noopener">
                            <img class="contact__medias-social-icon"
                                 src="../../assets/icons/socialMedias/twitter.svg"
                                 alt="twitter">
                        </a>
                        <a href="https://www.linkedin.com/company/lexios" target="_blank" rel="nofollow noopener">
                            <img class="contact__medias-social-icon"
                                 src="../../assets/icons/socialMedias/linkedin.svg"
                                 alt="linkedin">
                        </a>
                    </div>
                    <div class="white--text ml-8 py-4">
                        <div>
                            <router-link class="white--text" to="/policies/terms-of-service">
                                {{$t('policy_pages.terms.title')}}
                            </router-link>
                        </div>
                        <div>
                            <router-link class="white--text" to="/policies/privacy-policy">
                                {{$t('policy_pages.privacy.title')}}
                            </router-link>
                        </div>
                        <div>© 2021 Lexios. {{$t('home.contact.copyright')}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LexiosButton from '../LexiosButton';
    import ContactWaveBackground from './ContactWaveBackground';
    import LogoWithText from '../LogoWithText';
    import WordExample from '../popupBox/WordExample';

    export default {
        name: "Contact",
        components: {WordExample, LogoWithText, ContactWaveBackground, LexiosButton}
    }
</script>

<style scoped lang="sass">
    .contact
        width: 100%
        position: relative

        .contact__separator-title
            width: 115px
            height: 4px

        .contact__title
            max-width: 100%
            width: 1000px
            font-size: 50px
            font-weight: bold
            text-align: center

        .contact__subtitle
            font-size: 18px
            max-width: 800px
            text-align: center

        .contact__email-button
            border-width: 2px
            font-size: 18px !important
            font-weight: bold
            min-height: 60px !important
            padding-right: 24px !important
            padding-left: 24px !important

        .contact__email-button-icon
            display: block
            font-size: 32px

        .contact__discord-button
            border-width: 2px
            font-size: 18px !important
            font-weight: bold
            min-height: 60px !important
            padding-right: 24px !important
            padding-left: 24px !important

        .contact__discord-button-icon
            display: block
            font-size: 32px

        .contact__background-wave
            position: relative
            min-height: calc(7/16 * 100vw)
            width: 100%
            height: 100%
            z-index: -1

        .contact__medias-social-icon
            height: 50px
            margin-right: 8px

        @media screen and (min-width: 1905px)
            .contact__background-wave
                margin-top: -400px
                padding-top: 750px

        @media screen and (max-width: 1904px)
            .contact__background-wave
                margin-top: -270px
                padding-top: 540px

        @media screen and (max-width: 1264px)
            .contact__background-wave
                margin-top: -180px
                padding-top: 450px

        @media screen and (max-width: 960px)
            .contact__background-wave
                margin-top: -50px
                padding-top: 380px

        @media screen and (max-width: 600px)
            .contact__background-wave
                padding-top: 230px

            .contact__title
                font-size: 40px
                line-height: 50px
</style>