import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from 'vuetify/es5/locale/de'
import fr from 'vuetify/es5/locale/fr'
import en from 'vuetify/es5/locale/en'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { de, fr, en },
    },
    theme: {
        light: true,
        themes: {
            light: {
                primary: '#3F79FC',
                secondary: '#3d4852',
                highlight: '#ed540d',
                background_highlight: '#fdeee6',
                accent: '#3F79FC',
                disable_primary: '#476dd1',
                background_primary: '#3d62c8',
                background_secondary: '#313a42',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
                disable_color: '#ececf0',
                disable_color_text: '#8190a4',
                disable_on_dark: '#ececf0',
                card: '#F5F7FB',
                white: '#fff'
            },
        },
    },
});
