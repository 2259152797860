const IS_DEBUG = true;

const FLAGS_DEBUG = {
    premium_exists: true,
    paid_premium_exists: false,
}

const FLAGS_PROD = {
    premium_exists: false,
    paid_premium_exists: true,
}

export function getFlags() {
    if (IS_DEBUG) {
        return FLAGS_DEBUG;
    }
    return FLAGS_PROD;
}

