import Vue from 'vue';
import Vuex from 'vuex';
import { ENGLISH } from '../utils/languages';

Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        targetLanguage: ENGLISH,
        hoverText: false,
        infos: Object,
        language: String,
        hoverDebounce: Function,
        leftMargin: 0,
        topMargin: 0,
        lastId: null,
        pageLanguage: null,
        _firebaseUser: null,
        _userProfile: null,
    },
    mutations: {
        changeTargetLanguage (state, language) {
            state.targetLanguage = language;
        },
        changeHoveredText (state, payload) {
            state.hoverText = payload.hoverText;
            if (state.hoverText) {
                state.infos = payload.infos;
                state.language = payload.language;
                state.hoverDebounce = payload.hoverDebounce;
                state.leftMargin = payload.leftMargin;
                state.topMargin = payload.topMargin;
                state.lastId = payload.id;
            } else if(state.lastId === payload.id) {
                state.hoverDebounce = () => {};
                state.leftMargin = 0;
                state.topMargin = 0;
                state.lastId = null;
            }
        },
        changeFirebaseUser (state, payload) {
            state._firebaseUser = payload.firebaseUser;
            state._userProfile = payload.userProfile;
        },
        changeUserProfile (state, payload) {
            state._userProfile = payload.userProfile;
        },
    },
    getters: {
        firebaseUser(state) {
            return state._firebaseUser;
        },
        userProfile(state) {
            return state._userProfile;
        }
    }
});