<template>
    <v-app>
        <v-app-bar
                class="d-flex justify-center"
                app
                v-bind:hide-on-scroll="$vuetify.breakpoint.mobile"
                color="white"
                v-bind:height="85"
        >
            <router-link to="/home/about">
                <logo-with-text class="app-logo" v-bind:inline-logo="true" v-bind:height="60"></logo-with-text>
            </router-link>
            <v-spacer class="d-none d-sm-flex"></v-spacer>
            <div class="app_navigation-bar-links d-none d-lg-flex">
                <router-link v-for="(item, index) in navigationButton"
                             v-bind:key="index"
                             class="d-flex align-center"
                             :to="item.route"
                >
                    <lexios-button class="app_navigation-bar-button"
                                   v-bind:color-text="'secondary'"
                                   v-bind:background-color="'white'"
                                   v-on:click.native="item.action()"
                                   v-html="item.html"
                    ></lexios-button>
                </router-link>
            </div>
            <v-spacer class=""></v-spacer>
            <download-button class="app-download_button d-none d-md-flex"
                             v-bind:resize-small="$vuetify.breakpoint.lgAndDown"
                             element-tracking-id="app-download_button"
            ></download-button>
            <v-select class="app_select-language app_select-language-bar ml-2 rounded-pill d-none d-sm-flex"
                      :value="[selectedLanguage, languages[selectedLanguage]]"
                      @input="updateSelectedLanguage($event)"
                      :items="Object.entries(languages)"
                      outlined
                      hide-details
                      v-bind:color="'background_primary'"
            >
                <template v-slot:selection="{item}">
                    <div class="d-flex">
                        <v-icon>mdi-web</v-icon>
                        <b class="align-self-center ml-2">{{item[1]}}</b>
                    </div>
                </template>
                <template v-slot:item="{item}">
                    <div class="d-flex">
                        <flag class="align-self-center" v-bind:country="getLanguageCode(item[0])"
                              v-bind:size="20"></flag>
                        <b class="align-self-center ml-2">{{item[1]}}</b>
                    </div>
                </template>
            </v-select>
            <div v-if="!showUserMenu && premiumExists" class="app_select-language ml-2">
                <router-link to="/analytics">
                    <lexios-button class="app__login-button"
                                   v-bind:background-color="'primary'"
                                   v-bind:color-text="'primary'"
                                   outlined
                    >
                        <v-icon class="mr-1" color="primary">mdi-account</v-icon>
                        {{$t('generic.login')}}
                    </lexios-button>
                </router-link>
            </div>
            <v-app-bar-nav-icon class="d-flex d-lg-none" @click="navigationDrawer = true"></v-app-bar-nav-icon>
        </v-app-bar>
        <v-navigation-drawer
                v-model="navigationDrawer"
                absolute
                temporary
        >
            <div class="navigation-drawer_content">
                <v-list
                        nav
                        dense
                >
                    <v-list-item-group
                            active-class="primary--text text--accent-4"
                    >
                        <router-link v-for="(item, index) in navigationButton"
                                     v-bind:key="index"
                                     class="d-flex align-center"
                                     :to="item.route"
                        >
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon>{{item.icon}}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title class="app_navigation-bar-links">
                                    {{item.text}}
                                </v-list-item-title>
                            </v-list-item>
                        </router-link>
                    </v-list-item-group>
                </v-list>
                <div class="d-flex">
                    <download-button class="app_download-button-nav mx-2 mt-2 pa-0 flex-grow-1"
                                     v-bind:resize-small="true"
                                     element-tracking-id="app_download-button-nav"
                    ></download-button>
                </div>
                <v-select class="app_select-language app_select-language-nav rounded-pill mx-3 mt-2"
                          :value="[selectedLanguage, languages[selectedLanguage]]"
                          @input="updateSelectedLanguage($event)"
                          :items="Object.entries(languages)"
                          outlined
                          hide-details
                          v-bind:color="'background_primary'"
                >
                    <template v-slot:selection="{item}">
                        <div class="d-flex">
                            <v-icon>mdi-web</v-icon>
                            <b class="align-self-center ml-2">{{item[1]}}</b>
                        </div>
                    </template>
                    <template v-slot:item="{item}">
                        <div class="d-flex">
                            <flag class="align-self-center" v-bind:country="getLanguageCode(item[0])"
                                  v-bind:size="20"></flag>
                            <b class="align-self-center ml-2">{{item[1]}}</b>
                        </div>
                    </template>
                </v-select>
            </div>
        </v-navigation-drawer>
        <v-main>
            <router-view></router-view>
        </v-main>
        <popup-box-content></popup-box-content>
    </v-app>
</template>

<script>
    import LogoWithText from './components/LogoWithText';
    import DownloadButton from './components/homePage/DownloadButton';
    import LexiosButton from './components/LexiosButton';
    import { ENGLISH, FRENCH, GERMAN, getLanguageCode, getLanguageFromCode, SPANISH } from './utils/languages';
    import Flag from './assets/icons/flags/Flag';
    import PopupBoxContent from './components/popupBox/PopupBoxContent';
    import { setLocaleCache } from './plugins/i18n';
    import { gtagSet } from './plugins/tracking';
    import { getFlags } from './flags';

    export default {
        name: 'App',
        mounted () {
            this.$vuetify.lang.current = this.$i18n.locale;
        },
        components: {
            PopupBoxContent,
            Flag,
            LexiosButton,
            DownloadButton,
            LogoWithText,
        },
        data () {
            return {
                navigationDrawer: false,
                selectedLanguage: getLanguageFromCode(this.$i18n.locale),
                premiumExists: getFlags().premium_exists,
            };
        },
        computed: {
            languages () {
                return {
                    [ENGLISH]: "EN",
                    [FRENCH]: "FR",
                    [GERMAN]: "DE",
                    [SPANISH]: "ES",
                    //[PORTUGUESE]: "PT",
                };
            },
            showUserMenu () {
                return this.$route.path !== '/' && !['/home', '/policies'].some(e => this.$route.path.includes(e));
            },
            navigationButton () {
                return this.showUserMenu
                    ? [
                        {
                            text: this.$t('home.about.nav.text.analytics'),
                            html: this.$t('home.about.nav.text.analytics'),
                            icon: 'mdi-chart-timeline-variant',
                            route: '/analytics',
                            action: () => {},
                        },
                        {
                            text: this.$t('home.about.nav.text.achievements'),
                            html: this.$t('home.about.nav.text.achievements'),
                            icon: 'mdi-trophy-variant',
                            route: '/achievements',
                            action: () => {},
                        },
                        /*{
                            text: this.$t('home.about.nav.text.premium'),
                            html: this.$t('home.about.nav.text.premium'),
                            icon: 'mdi-arrow-up-bold-circle',
                            route: '/subscription',
                            action: () => {},
                        },*/
                        {
                            text: this.$t('home.about.nav.text.bookmarks'),
                            html: this.$t('home.about.nav.text.bookmarks'),
                            icon: 'mdi-book',
                            route: '/bookmarks',
                            action: () => {},
                        },
                    ]
                    : [
                        {
                            text: this.$t('home.about.nav.text.about'),
                            html: this.$t('home.about.nav.html.about'),
                            icon: 'mdi-school',
                            route: '/home/about',
                            action: () => {},
                        },
                        {
                            text: this.$t('home.about.nav.text.how'),
                            html: this.$t('home.about.nav.html.how'),
                            icon: 'mdi-checkbox-multiple-marked',
                            route: '/home/how',
                            action: () => {},
                        },
                        {
                            text: this.$t('home.about.nav.text.why'),
                            html: this.$t('home.about.nav.html.why'),
                            icon: 'mdi-lightbulb-on',
                            route: '/home/why',
                            action: () => {},
                        },
                        {
                            text: this.$t('home.about.nav.text.faq'),
                            html: this.$t('home.about.nav.html.faq'),
                            icon: 'mdi-frequently-asked-questions',
                            route: '/home/faq',
                            action: () => {},
                        },
                        {
                            text: this.$t('home.about.nav.text.contact'),
                            html: this.$t('home.about.nav.html.contact'),
                            icon: 'mdi-card-account-mail',
                            route: '/home/contact',
                            action: () => {},
                        },
                    ];
            },
        },
        methods: {
            getLanguageCode,
            updateSelectedLanguage (language) {
                this.selectedLanguage = language[0];
                const languageCode = getLanguageCode(this.selectedLanguage);
                this.$i18n.locale = languageCode;
                this.$vuetify.lang.current = languageCode;
                setLocaleCache(languageCode);
                gtagSet({languageCode});
            },
        },
    };
</script>

<style lang="sass">
    html
        overflow-y: auto
    body
        overflow-y: auto

    a
        text-decoration: none !important

    .v-navigation-drawer
        height: 100vh !important
        position: fixed !important

    .v-app-bar > .v-toolbar__content
        width: 100%
        max-width: 1300px
        justify-content: center

    // Remove min height of the v-input related
    .v-text-field.v-text-field--enclosed .v-text-field__details,
    .v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot
        margin: 0 !important
        min-height: 36px !important
        display: flex !important
        align-items: center !important

    .v-input__append-inner
        margin-top: 0 !important
        align-self: center !important

    .v-list-item
        min-height: 36px !important

    .v-list-item__content
        padding: 0 !important
        vertical-align: center

    .v-select
        min-height: 36px !important
        height: fit-content

        .v-select > .v-input__control > .v-input__slot > .v-select__slot > .v-select__selections
            padding: 0 !important
            vertical-align: middle


</style>
<style scoped lang="sass">
    .v-app-bar
        display: flex
        align-items: center

    .app_select-language
        ::v-deep .v-input__control > .v-input__slot > fieldset
            border-width: 2px
            border-color: #3d62c8

    .app_select-language-bar
        max-width: 105px

    .app_navigation-bar-links
        font-size: 1rem !important

    .app_navigation-bar-button
        font-size: 18px !important
        font-weight: bold
        padding-top: 4px
        padding-bottom: 4px

    .navigation-drawer_content
        position: fixed

    .app__login-button
        height: 48px !important
        font-size: 16px !important

</style>
