<template>
    <div>
        <svg class="how-background__elm1 d-none d-sm-flex "
             xmlns="http://www.w3.org/2000/svg"
             viewBox="-13 0 50 50">
            <circle class="how-background__elm1-path" cx="25" cy="25" r="22"/>
        </svg>
    </div>
</template>

<script>
    export default {
        name: "HowBackground"
    }
</script>

<style scoped lang="sass">
    .how-background__elm1
        position: absolute
        height: 92px
        right: 0
        top: -80px

    .how-background__elm1-path
        fill: none
        stroke: #fdeee6
        stroke-linecap: round
        stroke-miterlimit: 10
        stroke-width: 5.57px
</style>