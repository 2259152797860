<template>
    <div :class="`achievement white secondary--text ma-3 ${this.rank}`">
        <star-icon class="achievement__rank"
                   v-bind:rank="rank"
        ></star-icon>
        <div class="achievement__content d-flex flex-column">
            <component class="achievement__icon mx-auto mb-2"
                       v-bind:is="component"
                       v-bind:grad-color-start="'#e82e10'"
                       v-bind:grad-color-end="'#fcd702'"
                       v-bind:rotate-angle="25"
            ></component>
            <div class="achievement__title">{{title}}</div>
            <div class="achievement__description">{{formattedDescription}}</div>
            <div :class="`achievement__reward d-flex ml-auto mb-1 ${isMaxed ? 'hide' : ''}`">
                <div class="achievement__reward-text mr-2">{{$t('achievements.reward')}}</div>
                <div class="achievement__reward-amount mr-1">{{reward}}</div>
                <trophy-icon class="achievement__reward-symbol"></trophy-icon>
            </div>
            <div class="achievement__bottom-bar">
                <transition name="slide-fade">
                    <lexios-button v-if="isCompleted"
                                   class="achievement__completed-button"
                                   background-color="highlight"
                                   @click.native="claimRewards()"
                    >
                        <div class="achievement__completed-button-text">{{$t('achievements.claim')}}</div>
                    </lexios-button>
                    <v-progress-linear v-else
                                       class="achievement__progress-bar rounded-pill"
                                       v-bind:height="36"
                                       v-bind:value="progressBarValue"
                                       v-bind:background-opacity="0.45"
                    >
                        <template v-slot:default="{ value }">
                            <div class="achievement__progress-bar-text white--text">{{formattedProgressBar(value)}}</div>
                        </template>
                    </v-progress-linear>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    import TrophyIcon from '../../assets/icons/achievements/TrophyIcon';
    import StarIcon from '../../assets/icons/achievements/StarIcon';
    import LexiosButton from '../LexiosButton';

    export default {
        name: "Achievement",
        components: {LexiosButton, StarIcon, TrophyIcon},
        mounted () {
            this.updateCurrent();
        },
        props: {
            component: {
                required: true,
            },
            title: {
                type: String,
                required: true,
            },
            description: {
                type: String,
                required: true,
            },
            level: {
                type: Number,
                required: true,
            },
            target: {
                type: Number,
                required: true,
            },
            current: {
                type: Number,
                required: true,
            },
            reward: {
                type: Number,
                required: true,
            },
            maxLevel: {
                type: Number,
                required: true,
            },
        },
        data() {
            return {
                claimed: false,
                currentAnimated: 0,
                currentAnimatedCompleted: 0,
            }
        },
        computed: {
            rank () {
                const ranks = StarIcon.RANKS;
                if (this.isMaxed) {
                    return ranks.max;
                }
                const ratio = this.level / this.maxLevel;
                if (ratio < 0.2) {
                    return ranks.none;
                }
                if (ratio < 0.4) {
                    return ranks.bronze;
                }
                if (ratio < 0.6) {
                    return ranks.silver;
                }
                if (ratio < 0.8) {
                    return ranks.gold;
                }
                return ranks.diamond;
            },
            isMaxed () {
                return this.maxLevel <= this.level;
            },
            formattedDescription () {
                return this.description.replace('$val', this.target);
            },
            progressBarValue () {
                return this.isMaxed ? 100 : this.currentAnimated / this.target * 100;
            },
            isCompleted() {
                return !this.isMaxed && this.currentAnimatedCompleted >= this.target;
            },
        },
        methods: {
            formattedProgressBar (value) {
                let output = this.isMaxed ? this.$t('achievements.completed') : '';
                return output + `${Math.round(value * (this.isMaxed ? this.current : this.target) / 100)}/${this.target}`
            },
            claimRewards() {
                if (!this.claimed) {
                    this.claimed = true;
                    this.$emit('claimed', true);
                }
            },
            updateCurrent() {
                const extraTimeAfterClaim = this.claimed ? 600 : 0;
                setTimeout(
                    () => this.currentAnimated = Math.min(this.current, this.target),
                    50 + extraTimeAfterClaim,
                );
                setTimeout(
                    () => this.currentAnimatedCompleted = Math.min(this.current, this.target),
                    350 + extraTimeAfterClaim,
                );
            }
        },
        watch: {
            target() {
                this.updateCurrent();
                this.claimed = false;
            }
        }
    }
</script>

<style scoped lang="sass">
    .achievement
        width: 100%
        max-width: 360px
        background-color: white
        box-shadow: 0 11px 43px #17376b20 !important
        border-radius: 33px
        padding: 5px
        position: relative
    .achievement.bronze
        background: linear-gradient(180deg, #CC9A69, #B76F29) !important
    .achievement.silver
        background: linear-gradient(180deg, #D5D5D5, #ABABAB) !important
    .achievement.gold
        background: linear-gradient(180deg, #FFEB7F, #FFD700) !important
    .achievement.diamond, .achievement.max
        background: linear-gradient(180deg, #D9F9F9, #6befe9) !important

    .achievement__content
        padding: 15px
        background-color: white
        border-radius: 29px

    .achievement__rank
        height: 70px
        position: absolute
        right: -8px
        top: -9px

    .achievement__icon
        width: 60px
        height: 60px

    .achievement__title
        width: 100%
        text-align: center
        font-size: 20px
        font-weight: bold

    .achievement__description
        width: 100%
        text-align: left
        font-size: 15px
        height: 40px

    .achievement__reward
        font-size: 18px

    .achievement__reward.hide
        visibility: hidden

    .achievement__reward-amount
        font-weight: bold
        color: #FFAF00

    .achievement__reward-symbol
        width: 26px
        height: 26px

    .achievement__bottom-bar
        position: relative
        width: 100%
        height: 36px

    .achievement__completed-button
        position: absolute
        width: 100%
        margin: 0 !important

    .achievement__completed-button-text
        width: 100%
        font-size: 16px
        text-align: center
        font-weight: bold

    .achievement__progress-bar
        position: absolute

    .achievement__progress-bar-text
        width: 100%
        text-align: center
        font-weight: bold
        text-shadow: 0 1px 4px rgba(0, 0, 0, 0.25) !important

    .slide-fade-enter-active
        transition: all .6s cubic-bezier(1.0, 0.5, 0.8, 1.0)

    .slide-fade-leave-active
        transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0)

    .slide-fade-enter, .slide-fade-leave-to
        opacity: 0

</style>