<template>
    <svg class="icon" height="512pt" viewBox="0 1 60 60" width="512pt" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <clipPath :id="`clip-path_${uuid}`">
                <path d="m30 26c-.146 0-.291-.032-.426-.095l-17-8c-.35-.165-.574-.518-.574-.905s.224-.74.574-.905l17-8c.27-.127.582-.127.852 0l17 8c.35.165.574.518.574.905s-.224.74-.574.905l-17 8c-.135.063-.28.095-.426.095zm-14.651-9 14.651 6.895 14.651-6.895-14.651-6.895z"/>
                <path d="m30 34c-.153 0-.307-.035-.447-.105l-12-6c-.339-.17-.553-.516-.553-.895v-7.647c0-.342.175-.661.464-.844.289-.184.652-.205.961-.061l11.575 5.447 11.574-5.447c.31-.145.673-.123.961.061.29.183.465.502.465.844v7.647c0 .379-.214.725-.553.895l-12 6c-.14.07-.294.105-.447.105zm-11-7.618 11 5.5 11-5.5v-5.453l-10.574 4.976c-.27.127-.582.127-.852 0l-10.574-4.976z"/>
                <path d="m47 18h-17c-.552 0-1-.448-1-1s.448-1 1-1h17c.552 0 1 .448 1 1s-.448 1-1 1z"/>
                <path d="m47 27c-.552 0-1-.448-1-1v-9c0-.552.448-1 1-1s1 .448 1 1v9c0 .552-.448 1-1 1z"/>
                <path d="m30 20c-.552 0-1-.448-1-1v-4c0-.552.448-1 1-1s1 .448 1 1v4c0 .552-.448 1-1 1z"/>
                <path d="m44.999 30c-.19 0-.383-.054-.554-.168-.459-.306-.583-.927-.277-1.387l2-3c.306-.46.926-.585 1.387-.277.459.306.583.927.277 1.387l-2 3c-.193.289-.51.445-.833.445z"/>
                <path d="m49.001 30c-.323 0-.64-.156-.833-.445l-2-3c-.306-.459-.182-1.081.277-1.387.459-.308 1.08-.183 1.387.277l2 3c.306.459.182 1.081-.277 1.387-.171.114-.364.168-.554.168z"/>
                <path d="m30 29c-.552 0-1-.448-1-1v-3c0-.552.448-1 1-1s1 .448 1 1v3c0 .552-.448 1-1 1z"/>
                <path d="m59 38h-58c-.552 0-1-.448-1-1v-32.999c0-2.206 1.795-4.001 4.001-4.001h51.998c2.206 0 4.001 1.795 4.001 4.001v32.999c0 .552-.448 1-1 1zm-57-2h56v-31.999c0-1.104-.897-2.001-2.001-2.001h-51.998c-1.104 0-2.001.897-2.001 2.001z"/>
                <path d="m55.999 46h-51.998c-2.206 0-4.001-1.795-4.001-4.001v-4.999c0-.552.448-1 1-1h58c.552 0 1 .448 1 1v4.999c0 2.206-1.795 4.001-4.001 4.001zm-53.999-8v3.999c0 1.104.897 2.001 2.001 2.001h51.998c1.104 0 2.001-.897 2.001-2.001v-3.999z"/>
                <path d="m33 42h-6c-.552 0-1-.448-1-1s.448-1 1-1h6c.552 0 1 .448 1 1s-.448 1-1 1z"/>
                <path d="m55 38h-50c-.552 0-1-.448-1-1v-32c0-.552.448-1 1-1h50c.552 0 1 .448 1 1v32c0 .552-.448 1-1 1zm-49-2h48v-30h-48z"/>
                <path d="m43 58h-26c-1.654 0-3-1.346-3-3s1.346-3 3-3h26c1.654 0 3 1.346 3 3s-1.346 3-3 3zm-26-4c-.551 0-1 .449-1 1s.449 1 1 1h26c.551 0 1-.449 1-1s-.449-1-1-1z"/>
                <path d="m41 54h-22c-.552 0-1-.448-1-1s.448-1 1-1c3.318 0 5-2.355 5-7 0-.552.448-1 1-1h10c.552 0 1 .448 1 1 0 4.645 1.682 7 5 7 .552 0 1 .448 1 1s-.448 1-1 1zm-17.129-2h12.259c-1.232-1.364-1.957-3.395-2.102-6h-8.055c-.146 2.605-.87 4.636-2.102 6z"/>
            </clipPath>
            <linearGradient :id="`grad_${uuid}`"
                            :gradientTransform="`rotate(${rotateAngle})`"
                            x1="0%"
                            y1="0%"
                            x2="100%"
                            y2="0%"
            >
                <stop offset="0%" :style="`stop-color:${gradColorStart};stop-opacity:1`"/>
                <stop offset="100%" :style="`stop-color:${gradColorEnd};stop-opacity:1`"/>
            </linearGradient>
        </defs>
        <g :clip-path="`url(#clip-path_${uuid})`">
            <rect :fill="iconColor" x="0" y="0" width="60" height="60"/>
        </g>
    </svg>
</template>

<script>
    import { uuidv4 } from '../../utils/helper';

    export default {
        name: "DownloadDocIcon",
        props: {
            color: {
                type: String,
                default: '',
            },
            gradColorStart: {
                type: String,
                default: 'black',
            },
            gradColorEnd: {
                type: String,
                default: 'black',
            },
            rotateAngle: {
                type: Number,
                default: 0,
            }
        },
        data () {
            return {
                uuid: uuidv4(),
            }
        },
        computed: {
            iconColor() {
                if (this.color === '') {
                    return `url(#grad_${this.uuid})`;
                }
                return this.color;
            }
        },
    }
</script>

<style scoped lang="sass">
    .icon
        border-radius: 0 !important
</style>