<template>
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
         y="0px"
         viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
    <g id="_x3C_Group_x3E__31_">
        <path class="st0" d="M283.6,408.2L283.6,408.2l0-68.2H228l0,0v88l29.9,4.9l25.6-4.9V408.2z"/>
		<path class="st0"
              d="M290.8,349.8h-69.7c-1.5,0-3.1-0.2-4.6-0.6l-66.8-17c-38-9.6-71.7-30.3-97.6-59.6S10,207.2,5.2,168.4 c-2.8-22.4,4.2-44.9,19.1-61.8C39.1,89.7,60.6,80,83.2,80h345.7c22.5,0,44,9.7,58.9,26.6c14.9,16.9,21.9,39.4,19.1,61.8 c-4.8,38.9-21.1,74.9-47,104.3c-25.9,29.4-59.7,50-97.6,59.6l-66.8,17C293.9,349.6,292.4,349.8,290.8,349.8L290.8,349.8z M357.7,314.3L357.7,314.3L357.7,314.3z M223.5,312.8h65.1l64.6-16.4c63-16,108.9-68.1,117-132.6c1.5-11.8-2.2-23.7-10.1-32.6 c-7.9-8.9-19.2-14.1-31.1-14.1H83.2c-11.9,0-23.3,5.1-31.1,14c-7.9,8.9-11.6,20.8-10.1,32.6c8,64.5,54,116.6,117,132.6 L223.5,312.8z"/>
            <path class="st1" d="M336.7,470.2h-166v-17.6c0-12.5,6.6-22.8,18.6-24.5c1.1-0.2,2.3-0.2,3.5-0.2h126.3c12.5,0,22.8,9.3,24.5,21.3
		c0.2,1.1-3.8,4.2-3.8,5.3L336.7,470.2z"/>
            <path class="st0" d="M343.6,452.7v17.6c-58.6,0-117.1,0-175.7,0v-17.6c0-12.5,9.2-22.8,21.2-24.4c-0.2,1.1-0.2,2.3-0.2,3.5v11.4
		c0,3.4,2.8,6.2,6.2,6.2h148.3C343.6,450.4,343.6,451.5,343.6,452.7z"/>
            <path class="st1" d="M123.6,73.2l2.1,162.7c0,74.2,55.9,132.8,130.1,132.8c21.8,0,42.4-0.6,60.6-9.8C360,336.8,390,291.4,390,239
		V73.2L123.6,73.2z"/>
            <path class="st0" d="M316.3,358.9c-18.2,9.2-38.8,14.4-60.6,14.4c-74.2,0-134.3-60.1-134.3-134.3V80l15.6-9.4l21.5,9.4v146.6
		c0,74.2,60.1,134.3,134.3,134.3C300.8,361,308.6,360.3,316.3,358.9z"/>
            <path class="st1" d="M390.5,75.3l-267.7,1.5c-6.8,0-12.3-5.5-12.3-12.3l0.7-10.4c0-6.8,5.5-12.3,12.3-12.3h3.2H391
		c6.8,0,12.3,5.5,12.3,12.3v13.6C403.4,74.5,397.3,75.3,390.5,75.3z"/>
            <path class="st0" d="M403.4,61.5v6.2c0,6.8-5.5,12.3-12.3,12.3H120.6c-6.8,0-12.3-5.5-12.3-12.3V54.1c0-6.8,5.5-12.3,12.3-12.3h6.2
		v7.4c0,6.8,5.5,12.3,12.3,12.3H403.4z"/>
    </g>
</svg>
</template>

<script>

    export default {
        name: "TrophyIcon",
    }
</script>

<style scoped lang="sass">
    .icon
        border-radius: 0 !important

    .st0
        fill: #FFD064

    .st1
        fill: #FFE07D
</style>