<template>
    <svg viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon">
        <g :clip-path="`url(#clip0_${this.uuid})`" :filter="`url(#filter0_${this.uuid})`">
            <g v-if="isMaxStar">
                <path fill="#00ADBE" transform="translate(4, 0)" d="M11,10.28l-.91,12.45L4,32.05a2.15,2.15,0,0,0,.78,2.41l11.67,4,6.85,9.47a2.15,2.15,0,0,0,2.53,0l7.51-9.6,11-3.86a2.15,2.15,0,0,0,.78-2.41l-6.94-10L38,10.28A2.15,2.15,0,0,0,36,8.8L24.54,11.86,13.09,8.8A2.15,2.15,0,0,0,11,10.28Z"/>
            </g>
            <path d="M51.694 18.3036L35.7987 16.8293L29.4847 2.16761C29.0426 1.14099 27.587 1.14099 27.1449 2.16761L20.831 16.8294L4.9357 18.3036C3.82272 18.4068 3.3729 19.7911 4.21262 20.5288L16.2057 31.0645L12.6959 46.6375C12.4502 47.7278 13.6277 48.5834 14.5888 48.0127L28.3149 39.8624L42.0409 48.0127C43.002 48.5834 44.1796 47.7278 43.9339 46.6375L40.4241 31.0645L52.4171 20.5288C53.2568 19.7911 52.807 18.4068 51.694 18.3036Z"
                  v-bind:fill="color"
            />
            <path d="M29.4847 2.16761C29.0426 1.14099 27.587 1.14099 27.1449 2.16761L20.831 16.8294L4.9357 18.3036C3.82272 18.4068 3.3729 19.7911 4.21262 20.5288L16.2057 31.0645L12.6959 46.6375C12.4502 47.7278 13.6277 48.5834 14.5888 48.0127L17.6524 46.1936C18.0764 28.7396 26.1861 16.4484 32.6067 9.41714L29.4847 2.16761Z"
                  v-bind:fill="colorLeftPart"
            />
        </g>
        <defs>
            <filter :id="`filter0_${this.uuid}`" x="0.0741284" y="0.259277" width="56.4815" height="56.4815" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                <feOffset dy="3.7037"/>
                <feGaussianBlur stdDeviation="1.85185"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
            </filter>
            <clipPath :id="`clip0_${this.uuid}`">
                <rect width="57" height="56" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    import { uuidv4 } from '../../../utils/helper';

    const RANKS = {
            none: 'none',
            bronze: 'bronze',
            silver: 'silver',
            gold: 'gold',
            diamond: 'diamond',
            max: 'max',
        }

    export default {
        RANKS: RANKS,
        name: "StarIcon",
        props: {
            rank: {
                type: String,
                required: true,
            },
        },
        data () {
            return {
                uuid: uuidv4(),
            }
        },
        computed: {
            color() {
                switch (this.rank) {
                    case RANKS.none:
                        return '#00000000';
                    case RANKS.bronze:
                        return '#FF9A38';
                    case RANKS.silver:
                        return '#E0E0E0';
                    case RANKS.gold:
                        return '#FFDC64';
                    case RANKS.diamond:
                    default:
                        return '#1FFFF4';
                }
            },
            colorLeftPart() {
                switch (this.rank) {
                    case RANKS.none:
                        return '#00000000';
                    case RANKS.bronze:
                        return '#DB842F';
                    case RANKS.silver:
                        return '#C1C1C1';
                    case RANKS.gold:
                        return '#FFC850';
                    case RANKS.diamond:
                    default:
                        return '#46D9D2';
                }
            },
            isMaxStar() {
                return this.rank === RANKS.max;
            },
        },
    }
</script>

<style scoped lang="sass">
    .icon
        border-radius: 0 !important
</style>