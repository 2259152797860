import { ENGLISH, FRENCH, GERMAN, PORTUGUESE, SPANISH } from '../../utils/languages';

const NOUN = 'noun';
//const ADVERB = 'adverb';
//const VERB = 'verb';

export const examples = {
    vocabulary: {
        type: NOUN,
        [ENGLISH]: {
            t: 'vocabulary',
            g: '-',
            f: 's',
        },
        [FRENCH]: {
            t: 'vocabulaire',
            g: 'm',
            f: 's',
        },
        [GERMAN]: {
            t: 'Vokabular',
            g: 'nt',
            f: 's',
        },
        [PORTUGUESE]: {
            t: 'vocabulário',
            g: 'm',
            f: 's',
        },
        [SPANISH]: {
            t: 'vocabulario',
            g: 'm',
            f: 's',
        },
    },
    beginner: {
        type: NOUN,
        [ENGLISH]: {
            t: 'beginner',
            g: '-',
            f: 's',
        },
        [FRENCH]: {
            t: 'débutant',
            g: 'm',
            f: 's',
        },
        [GERMAN]: {
            t: 'Anfänger',
            g: 'm',
            f: 's',
        },
        [PORTUGUESE]: {
            t: 'iniciante',
            g: 'm',
            f: 's',
        },
        [SPANISH]: {
            t: 'principiante',
            g: 'm',
            f: 's',
        },
    },
    results: {
        type: NOUN,
        [ENGLISH]: {
            t: 'results',
            g: '-',
            f: 'pl',
        },
        [FRENCH]: {
            t: 'résultats',
            g: 'm',
            f: 'pl',
        },
        [GERMAN]: {
            t: 'Resultate',
            g: '-',
            f: 'pl',
        },
        [PORTUGUESE]: {
            t: 'resultados',
            g: 'm',
            f: 'pl',
        },
        [SPANISH]: {
            t: 'resultados',
            g: 'm',
            f: 'pl',
        },
    },
    question: {
        type: NOUN,
        [ENGLISH]: {
            t: 'question',
            g: '-',
            f: 's',
        },
        [FRENCH]: {
            t: 'question',
            g: 'f',
            f: 's',
        },
        [GERMAN]: {
            t: 'Frage',
            g: 'f',
            f: 's',
        },
        [PORTUGUESE]: {
            t: 'questão',
            g: 'f',
            f: 's',
        },
        [SPANISH]: {
            t: 'cuestión',
            g: 'f',
            f: 's',
        },
    },
    partnerships: {
        type: NOUN,
        [ENGLISH]: {
            t: 'partnerships',
            g: '-',
            f: 'pl',
        },
        [FRENCH]: {
            t: 'partenariats',
            g: 'f',
            f: 'pl',
        },
        [GERMAN]: {
            t: 'Partnerschaften',
            g: '-',
            f: 'pl',
        },
        [PORTUGUESE]: {
            t: 'parcerias',
            g: 'f',
            f: 'pl',
        },
        [SPANISH]: {
            t: 'asociaciones',
            g: 'f',
            f: 'pl',
        },
    },
}