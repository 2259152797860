<template>
    <div class="words-plot" v-resize="onResize" ref="words_plot">
        <div id="words-plot-d3" :style="`display: ${visibility}`"></div>
        <div class="words-plot__tooltip elevation-2 rounded pa-2" id="words-plot-tooltip"></div>
    </div>
</template>

<script>
    import { daysBetween } from '../../utils/time';

    const LIMIT_FREE_DAILY = 500;
    import * as d3 from "d3";
    import { debounce, viewPortWidth } from '../../utils/helper';

    export default {
        name: "WordsPlot",
        props: {
            wordsCount: {
                required: true,
            },
            startDate: {
                type: String,
                required: true,
            },
            endDate: {
                type: String,
                required: true,
            },
            isPremium: {
                type: Boolean,
                required: true,
            },
        },
        data () {
            return {
                defaultWidth: 450,
                scale: 1,
                debounceResize: debounce(this.computeSvgDimensions, 400),
                visibility: 'block',
            };
        },
        mounted () {
            this.visibility = 'none';
            this.debounceResize();
        },
        methods: {
            onResize(){
                this.visibility = 'none';
                this.debounceResize();
            },
            computeSvgDimensions() {
                this.visibility = 'block';
                const oldWidth = this.defaultWidth;
                const screenWidth = viewPortWidth();
                if (screenWidth > 795) {
                    this.defaultWidth = this.$refs.words_plot.clientWidth - 4;
                } else {
                    this.defaultWidth = screenWidth - 5 * 16;
                }
                if (this.defaultWidth < 450 && screenWidth <= 795) {
                    this.scale = this.defaultWidth / 450;
                } else {
                    this.scale = 1;
                }
                if (oldWidth !== this.defaultWidth) {
                    this.createPlot();
                }
            },
            createPlot () {
                const width = this.defaultWidth;
                const height = 300 * this.scale;
                const marginLeft = 40;
                const marginRight = 10;
                const marginBottom = 25;
                const marginTop = 10;
                const realWidth = width - marginLeft - marginRight;
                const realHeight = height - marginTop - marginBottom;

                d3.select("#words-plot-d3").selectAll("*").remove();
                const svg = d3.select("#words-plot-d3").append('svg')
                    .attr("width", width)
                    .attr("height", height);

                const xScale = d3.scaleBand().range([0, realWidth]).padding(0.2 * this.scale),
                    yScale = d3.scaleLinear().range([realHeight, 0]);

                const g = svg.append("g")
                    .attr("transform", "translate(" + marginLeft + "," + marginTop + ")");

                xScale.domain(this.wordsCount.map(d => this.axisConversion(d.date)));
                const maxY = Math.max(LIMIT_FREE_DAILY, d3.max(this.wordsCount, d => (d.words + d.premium_words)));
                yScale.domain([0, maxY]);

                // Sometimes NaN at first => redraw
                if (isNaN(yScale(LIMIT_FREE_DAILY))) {
                    setTimeout(this.createPlot, 50);
                    return;
                }

                let i = 0;
                g.append("g")
                    .attr("transform", "translate(0," + realHeight + ")")
                    .call(d3.axisBottom(xScale).tickFormat(d => {
                        if (this.periodDays > 7) {
                            if (i++ % Math.ceil(this.periodDays / 7) === 0) {
                                return d;
                            }
                            return '';
                        }
                        return d;
                    })
                        .tickSize(0)
                    )
                    .style("font-size","14px")
                    .selectAll('text')
                    .attr('transform',`translate(0,${marginBottom/5})`);

                if (!this.isPremium) {
                    // Line at 500
                    g.append("g")
                        .append("line")
                        .attr('x1', 0).attr('y1', yScale(LIMIT_FREE_DAILY))
                        .attr('x2', realWidth).attr('y2', yScale(LIMIT_FREE_DAILY))
                        .style("stroke", "rgb(255,0,0)");
                }

                let step;
                if(maxY <= LIMIT_FREE_DAILY){
                    step = 100;
                }else if(maxY <= 3 * LIMIT_FREE_DAILY) {
                    step = LIMIT_FREE_DAILY / 2;
                }else{
                    step = LIMIT_FREE_DAILY;
                }
                g.append("g")
                    .call(
                        d3.axisLeft(yScale)
                            .tickValues(d3.range(0, maxY + step, step))
                            .tickFormat(d => `${d}`)
                            .tickSize(0)
                    )
                    .call(g => g.select(".domain").remove())
                    .style("font-size","14px");

                // lines every step for reference
                for (let x = step; x <= maxY; x += step) {
                    if (x === LIMIT_FREE_DAILY && !this.isPremium) {
                        continue;
                    }
                    g.append("g")
                        .append("line")
                        .attr('x1', 0).attr('y1', yScale(x))
                        .attr('x2', realWidth).attr('y2', yScale(x))
                        .style("stroke", "rgb(172,172,172)");
                }

                // region drop shadow filter (no opacity in it!)
                const defs = svg.append("defs");
                const filter = defs.append("filter")
                    .attr("id", "dropshadow")
                filter.append("feGaussianBlur")
                    .attr("in", "SourceAlpha")
                    .attr("stdDeviation", this.scale)
                    .attr("result", "blur");
                filter.append("feOffset")
                    .attr("in", "blur")
                    .attr("dx", this.scale)
                    .attr("dy", -this.scale)
                    .attr("result", "offsetBlur");
                const feMerge = filter.append("feMerge");
                feMerge.append("feMergeNode")
                    .attr("in", "offsetBlur")
                feMerge.append("feMergeNode")
                // endregion

                // region Create bars in the plot
                const createBars = (className, yFunction, heightFunction) => {
                    return g.selectAll(".bar")
                        .data(this.wordsCount)
                        .enter().append("rect")
                        .attr("class", className)
                        .attr("x", d => {
                            return xScale(this.axisConversion(d.date))
                        })
                        .attr("y", yFunction)
                        .attr("width", xScale.bandwidth())
                        .attr("height", heightFunction);
                };

                createBars(
                    'shadow',
                    d => yScale(d.words + d.premium_words),
                    d => realHeight - yScale(d.words + d.premium_words),
                ).attr('opacity', 0.3).attr("filter", "url(#dropshadow)");

                createBars(
                    'bar_free',
                    d => yScale(d.words),
                    d => realHeight - yScale(d.words),
                );

                createBars(
                    'bar_premium',
                    d => yScale(d.words + d.premium_words),
                    d => realHeight - yScale(d.premium_words),
                );

                // hover effect and tooltip
                const div = d3.select("#words-plot-tooltip").style("opacity", 0);
                const mouseEnter = (event, d) => {
                    div.transition()
                        .duration(200)
                        .style("opacity", .9);
                    div.html(this.infoBoxText(d))
                        .style("left", (Math.max(20, d3.pointer(event)[0] -60)) + "px")
                        .style("top", (d3.pointer(event)[1]) + "px");
                }
                const mouseExit = () => {
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                };
                createBars(
                    'bar_hover',
                    d => yScale(d.words + d.premium_words),
                    d => realHeight - yScale(d.words + d.premium_words),
                ).on("mouseover", mouseEnter)
                    .on("mouseout", mouseExit);
                // endregion
            },
            infoBoxText(data){
                let output = `<b>${data.date.toLocaleString(this.$i18n.locale, {month: 'short', day: 'numeric'})}</b>`
                if (this.isPremium) {
                    output += `<br/>${this.$t('analytics.words.words_premium_display')}<b>${data.words}</b>`;
                } else {
                    output += `<br/>${this.$t('analytics.words.words_free_plan')}<b>${data.words}</b>`;
                    if(data.premium_words > 0){
                        output += `<br/>${this.$t('analytics.words.words_premium_plan')}<b>${data.words + data.premium_words}</b>`
                    }
                }
                return output;
            },
            axisConversion (date) {
                if (this.periodDays <= 7) {
                    return date.toLocaleString(this.$i18n.locale, {weekday: 'short'});
                } else {
                    return date.toLocaleString(this.$i18n.locale, {month: '2-digit', day: '2-digit'});
                }
            },
        },
        computed: {
            periodDays () {
                return daysBetween(new Date(this.startDate), new Date(this.endDate));
            },
            locale() {
                return this.$i18n.locale;
            },
        },
        watch: {
            scale () {
                this.createPlot();
            },
            wordsCount () {
                this.visibility = 'none';
                this.debounceResize();
            },
            locale() {
                this.visibility = 'none';
                this.debounceResize();
            },
        }
    }
</script>
<style lang="sass">
    .bar_free
        fill: #3F79FC

    .bar_premium
        fill: #ed540d

    .bar_hover
        fill: transparent
    .bar_hover:hover
        fill: rgba(255, 255, 255, 0.2)
</style>
<style scoped lang="sass">
    .words-plot
        position: relative

    .words-plot__tooltip
        position: absolute
        text-align: left
        max-width: 200px
        //height: 28px
        padding: 2px
        font-size: 16px
        background: white
        border: 0
        z-index: 1
        pointer-events: none
</style>