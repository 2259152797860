<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76.94 71.5">
        <circle class="dots-grid__path" :style="`fill: ${color}`" cx="2" cy="69.5" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="16.63" cy="69.5" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="16.63" cy="56" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="2" cy="56" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="31.19" cy="69.5" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="45.81" cy="69.5" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="45.81" cy="56" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="31.19" cy="56" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="60.31" cy="69.5" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="74.94" cy="69.5" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="74.94" cy="56" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="60.31" cy="56" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="2" cy="42.5" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="16.63" cy="42.5" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="16.63" cy="29" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="2" cy="29" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="31.19" cy="42.5" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="45.81" cy="42.5" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="45.81" cy="29" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="31.19" cy="29" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="60.31" cy="42.5" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="74.94" cy="42.5" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="74.94" cy="29" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="60.31" cy="29" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="2" cy="15.5" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="16.63" cy="15.5" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="16.63" cy="2" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="2" cy="2" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="31.19" cy="15.5" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="45.81" cy="15.5" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="45.81" cy="2" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="31.19" cy="2" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="60.31" cy="15.5" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="74.94" cy="15.5" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="74.94" cy="2" r="1.5"/>
        <circle class="dots-grid__path" :style="`fill: ${color}`"  cx="60.31" cy="2" r="1.5"/>
    </svg>
</template>

<script>
    export default {
        name: "DotsGrid",
        props: {
            color: {
                type: String,
                default: "#8199d9"
            }
        }
    }
</script>

<style scoped lang="sass">
</style>