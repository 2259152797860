<template>
    <v-btn :class="`lexios-button rounded-pill elevation-0 ma-1 px-3 text-none text-subtitle-2
            ${!clickedState ? colorText : colorTextClicked}--text`"
           :color="!clickedState ? backgroundColor : backgroundColorClicked"
           :outlined="outlined"
           :disabled="disabled"
    >
        <slot></slot>
    </v-btn>
</template>

<script>
    export default {
        name: "LexiosButton",
        props: {
            clickedState: {
                type: Boolean,
                default: false,
            },
            colorText: {
                type: String,
                default: 'white',
            },
            colorTextClicked: {
                type: String,
                default: 'grey',
            },
            backgroundColor: {
                type: String,
                default: 'primary',
            },
            backgroundColorClicked: {
                type: String,
                default: 'disable_on_dark',
            },
            outlined: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
        }
    }
</script>

<style scoped lang="sass">
    .lexios-button
        height: auto !important
        min-height: 36px !important
</style>