<template>
    <div>
        <about id="home__about" v-intersect="intersect"></about>
        <how id="home__how" v-intersect="intersect"></how>
        <why id="home__why" v-intersect="intersect"></why>
        <f-a-q id="home__faq" v-intersect="intersect"></f-a-q>
        <contact id="home__contact" v-intersect="intersect"></contact>
    </div>
</template>

<script>
    import FAQ from './FAQ';
    import About from './About';
    import How from './How';
    import Why from './Why';
    import Contact from './Contact';
    import { SECOND } from '../../utils/time';
    import { EVENTS, getGlobalUserId, GLOBAL_PARAMS, gtag, gtagSet } from '../../plugins/tracking';

    export default {
        name: 'Home',
        components: {Contact, Why, How, About, FAQ},
        data()  {
            return {
                intersect: {
                    handler: this.onIntersect,
                    options: {
                        threshold: 0.25,
                        delay: 3 * SECOND,
                    },
                },
                visited: new Set(),
            };
        },
        mounted () {
            this.scrollRoute(0);
            this.checkReferral();
            gtagSet({[GLOBAL_PARAMS.CURRENT_PAGE]: 'home'});
        },
        methods: {
            checkReferral() {
                // Check if the user got here from a referral link
                if (/^X[0-9|A-Z]{6}$/.test(this.$route.params.other)) {
                    getGlobalUserId().then(globalUserId => {
                        fetch(
                            'https://us-central1-lingualess-com.cloudfunctions.net/webApi/api/v1/referral_visited',
                            {
                                method: 'POST',
                                body: JSON.stringify({globalUserId, referral: this.$route.params.other}),
                            },
                        ).then(_ => _.json()).then(data => console.log('hallo3', data));
                    });
                }
            },
            scrollRoute(duration) {
                const options = {
                    duration,
                    easing: 'easeInOutCubic',
                };
                switch (this.$route.params.section) {
                    case 'how':
                        this.$vuetify.goTo('#home__how', options)
                        break;
                    case 'why':
                        this.$vuetify.goTo('#home__why', options)
                        break;
                    case 'faq':
                        this.$vuetify.goTo('#home__faq', options)
                        break;
                    case 'contact':
                        this.$vuetify.goTo('#home__contact', options)
                        break;
                    case 'about':
                    default:
                        this.$vuetify.goTo('#home__about', options)
                }
            },
            onIntersect(entries) {
                const currentEntry = entries[0];
                if(currentEntry.isIntersecting && !this.visited.has(currentEntry.target.id)) {
                    this.visited.add(currentEntry.target.id);
                    gtag('event', EVENTS.SECTION_VISITED, {sectionId: currentEntry.target.id});
                }
            }
        },
        watch: {
            $route() {
                this.scrollRoute(500);
            }
        }
    }
</script>

<style lang="sass">
    @import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')

    *
        font-family: "Exo", sans-serif
        z-index: 1
</style>
