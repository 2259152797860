<template>
    <div class="recent-words elevation-4 pa-2 d-flex flex-column">
        <div class="recent-words__title ml-2 mt-2 secondary--text">
            {{$t('analytics.recent_words.title')}}
        </div>
        <words-list v-bind:data="isPremium ? data : []"
                    v-bind:lems-data="lemsData"
                    v-bind:target-language-code="targetLanguageCode"
                    v-bind:bookmarked="bookmarked"
                    v-bind:default-items-per-page="7"
                    v-on:long="long = $event"
        ></words-list>
    </div>
</template>

<script>
    import WordsList from '../WordsList';

    export default {
        name: "RecentWords",
        components: {WordsList},
        props: {
            data: {
                type: Array,
                required: true,
            },
            lemsData: {
                type: Map,
                required: true,
            },
            targetLanguageCode: {
                type: String,
                required: true,
            },
            isPremium: {
                type: Boolean,
                required: true,
            },
            bookmarked: {
                type: Object,
                required: true,
            }
        },
        data() {
            return {
                long: false,
            };
        },
        watch: {
            long(newVal) {
                this.$emit('long', newVal);
            }
        },
    }
</script>

<style scoped lang="sass">
    .recent-words
        position: relative
        background-color: white
        border-radius: 26px
        width: fit-content
        max-width: 100%

    .recent-words__title
        font-size: 24px
        font-weight: bold

</style>