<template>
    <svg class="icon" height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <clipPath :id="`clip-path_${uuid}`">
                <path class="cls-1" d="M256,328.76a23.14,23.14,0,1,0-23.14-23.14A23.17,23.17,0,0,0,256,328.76Zm0-30.39a7.25,7.25,0,1,1-7.24,7.25A7.25,7.25,0,0,1,256,298.37Zm108.38-175.5a8.54,8.54,0,0,0-14.59-6,62,62,0,0,1-87.75,0,79.17,79.17,0,0,0-111.92,0,8.56,8.56,0,0,0-2.5,6,108.38,108.38,0,1,0,216.76,0Zm-199.6,3.61A62.08,62.08,0,0,1,250,128.91a79.2,79.2,0,0,0,95.41,12.71,91.3,91.3,0,0,1-180.57-15.14ZM412.1,450.76h-12V391.54A144.13,144.13,0,0,0,304.9,256a144.13,144.13,0,0,0,95.19-135.55V61.24h12A30.32,30.32,0,0,0,433.56,9.48,30.15,30.15,0,0,0,412.1.59H99.9A30.33,30.33,0,0,0,78.45,52.37,30.16,30.16,0,0,0,99.9,61.24h12v59.22A144.15,144.15,0,0,0,207.12,256a144.17,144.17,0,0,0-95.19,135.55v59.22h-12a30.32,30.32,0,1,0,0,60.65H412.1a30.32,30.32,0,1,0,0-60.65ZM90.54,40.29A13.24,13.24,0,0,1,99.9,17.68H412.1a13.24,13.24,0,1,1,0,26.47H99.9A13.16,13.16,0,0,1,90.54,40.29ZM129,391.54A127,127,0,0,1,232.31,266.79l.36-.07h.09a8.31,8.31,0,0,0,6.56-6.48,11.17,11.17,0,0,0,.25-7.78,8.54,8.54,0,0,0-6.82-7.15l-.37-.07A127,127,0,0,1,129,120.47V61.24H383v59.22A127,127,0,0,1,279.3,245.29h-.07a8.35,8.35,0,0,0-6.67,6.78,11.12,11.12,0,0,0-.71,3.92,11.24,11.24,0,0,0,.78,4.11,8.75,8.75,0,0,0,6.9,6.65A127,127,0,0,1,383,391.54v59.22H345.82a90.22,90.22,0,0,0-179.64,0H129V391.54Zm199.63,59.22H183.36a73.14,73.14,0,0,1,145.28,0Zm92.83,39.68a13.15,13.15,0,0,1-9.37,3.88H99.9a13.23,13.23,0,1,1,0-26.47H412.1a13.23,13.23,0,0,1,9.36,22.59Z"/>            </clipPath>
            <linearGradient :id="`grad_${uuid}`"
                            :gradientTransform="`rotate(${rotateAngle})`"
                            x1="0%"
                            y1="0%"
                            x2="100%"
                            y2="0%"
            >
                <stop offset="0%" :style="`stop-color:${gradColorStart};stop-opacity:1`"/>
                <stop offset="100%" :style="`stop-color:${gradColorEnd};stop-opacity:1`"/>
            </linearGradient>
        </defs>
        <g :clip-path="`url(#clip-path_${uuid})`">
            <rect :fill="iconColor" x="0" y="0" width="512" height="512"/>
        </g>
    </svg>
</template>

<script>
    import { uuidv4 } from '../../../utils/helper';

    export default {
        name: "TimeIcon",
        props: {
            color: {
                type: String,
                default: '',
            },
            gradColorStart: {
                type: String,
                default: 'black',
            },
            gradColorEnd: {
                type: String,
                default: 'black',
            },
            rotateAngle: {
                type: Number,
                default: 0,
            }
        },
        data () {
            return {
                uuid: uuidv4(),
            }
        },
        computed: {
            iconColor() {
                if (this.color === '') {
                    return `url(#grad_${this.uuid})`;
                }
                return this.color;
            }
        },
    }
</script>

<style scoped lang="sass">
    .icon
        border-radius: 0 !important
</style>