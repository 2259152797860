<template>
    <div :class="`how d-flex ${flexDirection} align-center secondary--text justify-center`">
        <how-background></how-background>
        <div class="how__example mx-5 mt-7">
            <div class="how__example-background">
                <svg class="how__example-background-elm1"
                     xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 50 50">
                    <circle class="how__example-background-elm1-path" cx="25" cy="25" r="25"/>
                </svg>
                <svg class="how__example-background-elm2"
                     xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 50 50">
                    <circle class="how__example-background-elm2-path" cx="25" cy="25" r="25"/>
                </svg>
            </div>
            <video class="how__example-content elevation-10 rounded"
                   width="500"
                   ref="how__video-player"
                   muted
                   loop
                   playsinline
            >
                <source :src="currentClip" type="video/mp4">
            </video>
        </div>
        <div class="how__content d-flex flex-column align-left px-7">
            <div :class="`how__title-group d-flex ${titleAlign} flex-column`">
                <div class="how__separator-title rounded-pill background_primary"></div>
                <div class="how__title mt-4">{{$t('home.how.title')}}</div>
            </div>
            <div class="how__buttons">
                <how-button v-for="(button, index) in howButtons"
                            v-bind:key="index"
                            class="mt-3"
                            v-on:icon-color="colorSelected($event, index)"
                            v-on:click.native="buttonSelected(index, button.videoMoment)"
                            v-bind:text="button.text"
                            v-bind:is-selected="index === selectedHowButtonIndex">
                    <component slot="icon"
                               class="how__button-icon"
                               :is="button.icon"
                               v-bind:rotate-angle="14"
                               v-bind:color="colorIcons[index]"
                               v-bind:grad-color-start="buttonIconStartColor"
                               v-bind:grad-color-end="buttonIconEndColor"
                    ></component>
                </how-button>
            </div>
        </div>
    </div>
</template>

<script>
    import HowBackground from './HowBackground';
    import HowButton from './HowButton';
    import DownloadDocIcon from '../../assets/icons/DownloadDocIcon';
    import ChooseIcon from '../../assets/icons/ChooseIcon';
    import LearningIcon from '../../assets/icons/LearningIcon';
    import { CHROME, EDGE/*, FIREFOX*/, getBrowser, isSupportedBrowser, isVisible } from '../../utils/helper';
    import { getLanguageCode, getLanguageFromCode } from '../../utils/languages';

    export default {
        name: "How",
        components: {HowButton, HowBackground},
        mounted () {
            this.videoActions();
        },
        data () {
            return {
                selectedHowButtonIndex: 0,
                buttonIconColor: this.$vuetify.theme.currentTheme.secondary,
                buttonIconStartColor: '',
                buttonIconEndColor: '',
                buttonIconColorSelected: 'white',
                isVideoPlaying: false,
            };
        },
        computed: {
            currentClip () {
                return `https://storage.googleapis.com/website_ressources/how_clip_${this.browserClip}_${this.languageClip}.mp4`;
            },
            languageClip () {
                return getLanguageCode(getLanguageFromCode(this.$i18n.locale));
            },
            browserClip () {
                let browser = getBrowser();
                if (![CHROME, EDGE/*, FIREFOX*/].includes(browser)) {
                    browser = CHROME;
                }
                return browser.toLowerCase();
            },
            browserButton () {
                const browser = getBrowser();

                return isSupportedBrowser(browser) ? browser : this.$t('home.how.browser');
            },
            video () {
                return this.$refs['how__video-player'];
            },
            howButtons () {
                return [
                    {
                        text: this.$t('home.how.button1') + this.browserButton,
                        icon: DownloadDocIcon,
                        videoMoment: 0,
                    },
                    {
                        text: this.$t('home.how.button2'),
                        icon: ChooseIcon,
                        videoMoment: 2.5,
                    },
                    {
                        text: this.$t('home.how.button3'),
                        icon: LearningIcon,
                        videoMoment: 16.67,
                    },
                ];
            },
            colorIcons () {
                // An array of color for each button's icon by index
                const out = Array(this.howButtons.length).fill(this.buttonIconColor)
                out[this.selectedHowButtonIndex] = this.buttonIconColorSelected;
                return out;
            },
            flexDirection () {
                if (this.$vuetify.breakpoint.mobile) {
                    return 'flex-column-reverse';
                }
                return 'flex-row';
            },
            titleAlign () {
                if (this.$vuetify.breakpoint.mobile) {
                    return 'align-center';
                }
                return 'align-left';
            }
        },
        methods: {
            videoActions () {
                if (isVisible(this.video)) {
                    if (!this.isVideoPlaying) {
                        this.isVideoPlaying = true;

                        setTimeout(() => {
                            this.video.play();
                        }, 400);
                    }

                    const time = this.video.currentTime;
                    let i = 0;
                    while (i < this.howButtons.length - 1 && time >= this.howButtons[i + 1].videoMoment) {
                        i += 1;
                    }
                    this.selectedHowButtonIndex = i;
                } else {
                    this.video.pause();
                    this.isVideoPlaying = false;
                }

                // Call back
                setTimeout(this.videoActions, 500);
            },
            setVideoMoment (videoMoment) {
                this.video.currentTime = videoMoment;
            },
            colorSelected (value, index) {
                if (index === this.selectedHowButtonIndex) {
                    if (value === 'highlight') {
                        this.buttonIconColorSelected = '';
                        this.buttonIconStartColor = '#e82e10';
                        this.buttonIconEndColor = '#fcc602';
                    } else {
                        this.buttonIconColorSelected = this.$vuetify.theme.currentTheme[value];
                        this.buttonIconStartColor = undefined;
                        this.buttonIconEndColor = undefined;
                    }
                }
            },
            buttonSelected (index, videoMoment) {
                this.selectedHowButtonIndex = index;
                this.setVideoMoment(videoMoment);
            },
        },
        watch: {
            currentClip () {
                this.video.pause();
                this.video.load();
                this.isVideoPlaying = false;
            }
        }
    }
</script>

<style scoped lang="sass">
    .how
        margin-bottom: 100px
        position: relative

        .how__content
            max-width: 100%

        .how__separator-title
            width: 115px
            height: 4px

        .how__title
            max-width: 100% !important
            width: fit-content
            font-size: 50px
            font-family: "Exo", sans-serif
            font-weight: bold
            text-align: left

        .how__button-icon
            height: 60px
            width: 60px

        .how__example
            position: relative
            max-width: 95%
            width: 500px

        .how__example-background
            position: absolute
            width: 100%
            height: 100%
            z-index: -1

        .how__example-content
            max-width: 100%
            width: 100%
            background-color: white

        .how__example-background-elm1
            position: absolute
            height: 265px
            right: -25px
            bottom: -55px

        .how__example-background-elm1-path
            fill: #f3faf8

        .how__example-background-elm2
            position: absolute
            height: 12px
            left: -75px
            bottom: -80px

        .how__example-background-elm2-path
            fill: #d9dceb

        @media screen and (max-width: 1264px)
            .how__example-background-elm1
                right: -5px

        @media screen and (max-width: 600px)
            .how__title
                font-size: 40px
                line-height: 50px
</style>