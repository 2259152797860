<template>
    <div class="why-background">
        <svg xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 1600.02 90.16">
            <path class="why-background__elem1-path1"
                  d="M0,55c139.61-1.09,197.52-1.3,206-1,1.07,0,6.56.27,14,0,6.22-.22,10.74-.69,14-1,8.65-.83,17.33-1.31,26-2,20.89-1.66,36-2.85,52-3a360.13,360.13,0,0,1,42,2c8,.89,43.72,4.86,73,16a177.86,177.86,0,0,0,22,7,201.43,201.43,0,0,0,22,4,218.21,218.21,0,0,0,33,2,200.84,200.84,0,0,0,51-8c14.21-4.1,27.47-10.4,54-23,25.39-12.06,28.69-14.94,42-19a166.69,166.69,0,0,1,42-7,163.6,163.6,0,0,1,58,8c11,3.78,18.89,8,34,16,14.08,7.48,20,11.76,37,20,10.2,4.94,17.5,8,20,9,5.64,2.31,17.86,7.33,28,10,19.54,5.15,36.17,2.89,50,1a183.13,183.13,0,0,0,53-16c15-6.84,14.22-8.68,32-17,9.77-4.57,24.19-11.24,43-16,22-5.56,39.42-5.86,52-6a275.13,275.13,0,0,1,64,7c15.2,3.37,39.79,10.13,52,14,1.32.42,5.41,1.73,11,3a146,146,0,0,0,20,3c11.33,1.07,19.27,1.82,31,2,13.74.22,23-.49,43-2,33.54-2.52,50.41-3.79,55-4,17.1-.79,24.16-.34,53,0,27.66.32,55.33,0,83,0h88V0H0Z"/>
            <path class="why-background__elem1-path2"
                  d="M0,54.5c139.61-1.09,197.52-1.3,206-1,1.07,0,6.56.27,14,0,6.22-.22,10.74-.69,14-1,8.65-.83,17.33-1.31,26-2,20.89-1.66,36-2.85,52-3a360.13,360.13,0,0,1,42,2c8,.89,43.72,4.86,73,16a177.86,177.86,0,0,0,22,7,201.43,201.43,0,0,0,22,4,218.21,218.21,0,0,0,33,2,200.84,200.84,0,0,0,51-8c14.21-4.1,27.47-10.4,54-23,25.39-12.06,28.69-14.94,42-19a166.69,166.69,0,0,1,42-7,163.6,163.6,0,0,1,58,8c11,3.78,18.89,8,34,16,14.08,7.48,20,11.76,37,20,10.2,4.94,17.5,8,20,9,5.64,2.31,17.86,7.33,28,10,19.54,5.15,36.17,2.89,50,1a183.13,183.13,0,0,0,53-16c15-6.84,14.22-8.68,32-17,9.77-4.57,24.19-11.24,43-16,22-5.56,39.42-5.86,52-6a275.13,275.13,0,0,1,64,7c15.2,3.37,39.79,10.13,52,14,1.32.42,5.41,1.73,11,3a146,146,0,0,0,20,3c11.33,1.07,19.27,1.82,31,2,13.74.22,23-.49,43-2,33.54-2.52,50.41-3.79,55-4,17.1-.79,24.16-.34,53,0,27.66.32,55.33,0,83,0h88"/>
        </svg>
        <dots-grid class="why-background__elem2 d-none d-md-flex"
                   v-bind:color="'#c9d1de'"
        ></dots-grid>
    </div>
</template>

<script>
    import DotsGrid from '../../assets/images/background/DotsGrid';
    export default {
        name: "WhyBackground",
        components: {DotsGrid}
    }
</script>

<style scoped lang="sass">
    .why-background
        width: 100%
        height: 100%
        position: absolute
        z-index: 0
        background: linear-gradient(0deg, #fff 0%, #f8f9fe 100%)

    .why-background__elem1-path1
        fill: #fff

    .why-background__elem1-path2
        fill: none
        stroke: #e1e1ff
        stroke-miterlimit: 10
        stroke-width: 2px

    .why-background__elem2
        position: absolute
        left: -60px
        top: 190px
        height: 135px
</style>