<template>
    <v-expansion-panels v-model="panelIndex" flat class="flex-grow-1">
        <transition-group name="fade" class="transition__questions elevation-0">
            <v-expansion-panel
                    v-for="(item,i) in questionsDisplayed"
                    :key="i"
                    class="mb-4"
            >
                <v-expansion-panel-header
                        :id="item[2]"
                        :class="`question-list__panel-header d-flex
                        ${panelIndex === i ? 'background_primary': 'secondary'}--text`"
                >
                    {{item[0]}}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mb-4">
                    <div v-html="item[1]"></div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </transition-group>
    </v-expansion-panels>
</template>

<script>
    import { EVENTS, gtag } from '../../plugins/tracking';

    export default {
        name: "QuestionList",
        props: {
            questions: {
                type: Array,
                required: true,
            },
        },
        data()  {
            return {
                questionsDisplayed: [],
                panelIndex: undefined,
            };
        },
        created () {
            this.questionsDisplayed = this.questions;
        },
        methods: {
            resetPanelIndex () {
                this.panelIndex = undefined;
            },
            updatePanelIndex (newVal) {
                this.panelIndex = newVal;
            },
        },
        watch: {
            questions(newVal) {
                this.questionsDisplayed = [];
                setTimeout(() => {
                    this.questionsDisplayed = newVal;
                }, 150);
            },
            panelIndex(newVal) {
                if (newVal !== undefined){
                    gtag('event', EVENTS.FAQ_QUESTION_OPEN, {questionId: this.questionsDisplayed[newVal][2]});
                }
            },
        }
    }
</script>

<style scoped lang="sass">
    .fade-enter-active, .fade-leave-active
        transition: opacity .15s

    .fade-enter, .fade-leave-to
        opacity: 0

    .transition__questions
        width: 100%

    .v-expansion-panel
        margin-top: 0 !important
        box-shadow: 0 11px 136px #00000018 !important

    .v-expansion-panel::before
        box-shadow: 0 0 0
    .v-expansion-panel:not(:first-child)::after
        border: 0

    .question-list__panel-header
        min-height: 60px
        font-weight: bold
        font-size: 18px
        line-height: 28px

    .v-expansion-panel-content
        line-height: 32px

</style>