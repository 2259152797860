<template>
    <div class="analytics-background d-flex flex-column">
        <div class="analytics-background__top_part flex-grow-1">
            <logo class="analytics-background__elm1" v-bind:color="$vuetify.theme.currentTheme.background_primary"></logo>
            <svg class="analytics-background__elm2" xmlns="http://www.w3.org/2000/svg" viewBox="16.6667 0 50 50">
                <circle class="background-elm2_path" cx="25" cy="25" r="22"/>
            </svg>
            <img class="analytics-background__elm3" :src="fullCircle">
            <img class="analytics-background__elm4" :src="fullCircle">
        </div>
    </div>
</template>

<script>
    import Logo from '../Logo';
    import FullCircle from '../../assets/images/background/fullCircle.svg'

    export default {
        name: "AnalyticsBackground",
        components: {Logo},
        data() {
            return {
                fullCircle: FullCircle,
            }
        }
    }
</script>

<style scoped lang="sass">
    .analytics-background
        width: 100%
        height: 100%
        position: absolute
        z-index: 0

    .analytics-background__top_part
        background: linear-gradient(135deg, #3e69df 0%, #26469d 100%)
        width: 100%
        position: relative

    .analytics-background__elements-bottom
        position: relative

    .analytics-background__elm1
        position: absolute
        height: 250px
        top: 50px
        right: 3%

    .analytics-background__elm2
        position: absolute
        height: 60px
        left: 0
        top: 80%

    .analytics-background__elm3
        position: absolute
        height: 170px
        left: 8%
        top: 25%

    .analytics-background__elm4
        position: absolute
        height: 10px
        right: 3%
        top: 77%

    .background-elm2_path
        fill: none
        stroke: #4D70D1
        stroke-linecap: round
        stroke-miterlimit: 10
        stroke-width: 5.57px

    .analytics-background__bottom_part
        position: absolute
        bottom: -2px

    .cls-1
        fill: #90a1cc

    .cls-2
        fill: #fff

    .cls-3
        fill: #667bb9

    @media screen and (max-width: 1217px)
        .analytics-background__elm1
            height: 200px

    @media screen and (max-width: 960px)

    @media screen and (max-width: 600px)
        .analytics-background__elm3
            left: 1%
            top: 35%
        .analytics-background__elm1
            height: 150px

</style>