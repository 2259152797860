<template>
    <div class="about_background d-flex flex-column">
        <div class="about_background-top_part flex-grow-1">
            <logo class="about_background-elm1" v-bind:color="$vuetify.theme.currentTheme.background_primary"></logo>
            <svg class="about_background-elm2" xmlns="http://www.w3.org/2000/svg" viewBox="-16.6667 0 50 50">
                <circle class="background-elm2_path" cx="25" cy="25" r="22"/>
            </svg>
            <img class="about_background-elm3" :src="fullCircle">
            <svg class="about_background-bottom_part" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 130">
                <path class="cls-1"
                      d="M1160,108c50.14,10,141.09,27.39,252,8,69.64-12.17,112.54-32.78,125-39a406.86,406.86,0,0,0,63-39c-8.51-3.32-20.46-7.69-35-12-8.51-2.52-45.92-13.34-106-18a719,719,0,0,0-108,0,746.3,746.3,0,0,0-165,31c-55.81,17.26-59.84,25.88-110,44l-5.35,1.91.35.09C1111,95.82,1124.73,100.94,1160,108Z"/>
                <path class="cls-2"
                      d="M1537,77c-12.46,6.22-55.36,26.83-125,39-104.06,18.19-190.54,4-242.24-6.07A1485.61,1485.61,0,0,1,930,118l-2.72-.13.77-.09A775.27,775.27,0,0,1,830,123c-85-.26-151.93-15.18-196-25-45.8-10.21-56.41-15.82-126-32-32.84-7.64-67.83-15.77-108.68-23.15C228.16,65.29,92,85.13,0,99v31H1600V38A406.86,406.86,0,0,1,1537,77Z"/>
                <path class="cls-1"
                      d="M399.31,42.85q-4.92-.89-9.95-1.76h0l-5-.86-.34-.06-4.76-.8-.89-.15-4.29-.7-1.54-.25-3.72-.6-2.69-.42-2.66-.42L358,36c-55.19-8.31-129-19-224-15A977.32,977.32,0,0,0,0,36V99C92,85.13,228.16,65.29,399.32,42.85Z"/>
                <path class="cls-1"
                      d="M930,118a1485.61,1485.61,0,0,0,239.76-8.07L1160,108c-35.27-7.06-49-12.18-89-23l-.35-.09-.2.07q-17.26,6.06-34,10.88l-.51.15L1032,97.11l-1,.28-3.7,1-1.16.32-3.5.94-1.34.36-3.27.85-1.54.4-3.14.79-1.63.41-3,.74-1.7.41-3,.7-1.73.41-2.87.66-1.81.41-2.77.61-1.88.41-2.71.58-1.9.41-2.66.55-1.91.39-2.6.52-1.93.38-2.54.49-2,.38-2.46.46-2,.37-2.4.43-2,.36-2.33.4-2.06.35-2.27.38-2.07.34-2.23.36-2.06.33-2.19.34-2.06.31-2.15.32-2.06.3-2.08.3-2.09.29-2,.27-2.1.28-2,.26-2.08.27-1.93.24-2.1.26-.77.09Z"/>
                <path class="cls-3"
                      d="M134,21A977.32,977.32,0,0,0,0,36V74A428,428,0,0,1,90,41c29-7.07,67.74-13.23,152-11,85.54,2.26,146,11,146,11l5.11.74c-11.24-2-22.91-3.9-35.11-5.74C302.81,27.69,229,17,134,21Z"/>
                <path class="cls-3"
                      d="M1351,8a746.3,746.3,0,0,0-165,31c-55.81,17.26-59.84,25.88-110,44l-5.35,1.91.35.09c40,10.82,53.73,15.94,89,23l9.76,1.93C1316.57,93.1,1425.91,58.54,1479,40c20.47-7.15,39.47-14.33,56.85-21.27A598.73,598.73,0,0,0,1459,8,719,719,0,0,0,1351,8Z"/>
            </svg>
        </div>
    </div>
</template>

<script>
    import Logo from '../Logo';
    import FullCircle from '../../assets/images/background/fullCircle.svg'
    export default {
        name: "AboutBackground",
        components: {Logo},
        data() {
            return {
                fullCircle: FullCircle,
            }
        }
    }
</script>

<style scoped lang="sass">
    .about_background
        width: 100%
        height: 100%
        position: absolute
        z-index: 0

        .about_background-top_part
            background: linear-gradient(135deg, #3e69df 0%, #26469d 100%)
            width: 100%
            position: relative

        .about_background-elements-bottom
            position: relative

        .about_background-elm1
            position: absolute
            height: 250px
            top: 200px
            right: 3%

        .about_background-elm2
            position: absolute
            height: 60px
            right: 0
            top: 60%

        .about_background-elm3
            position: absolute
            height: 170px
            left: 13%
            top: 40%

        .background-elm2_path
            fill: none
            stroke: #415fb3
            stroke-linecap: round
            stroke-miterlimit: 10
            stroke-width: 5.57px

        .about_background-bottom_part
            position: absolute
            bottom: -2px

        .cls-1
            fill: #90a1cc

        .cls-2
            fill: #fff

        .cls-3
            fill: #667bb9

        @media screen and (max-width: 1264px)
            .about_background-elm1
                height: 200px

            .about_background-elm3
                left: 2%
                top: 43%

        @media screen and (max-width: 960px)
            .about_background-elm3
                display: none

        @media screen and (max-width: 600px)
            .about_background-elm1
                height: 150px

</style>