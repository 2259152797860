import { render, staticRenderFns } from "./WordsList.vue?vue&type=template&id=d745039a&scoped=true&"
import script from "./WordsList.vue?vue&type=script&lang=js&"
export * from "./WordsList.vue?vue&type=script&lang=js&"
import style0 from "./WordsList.vue?vue&type=style&index=0&id=d745039a&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d745039a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VCheckbox,VDataIterator,VDataTable,VProgressCircular,VProgressLinear})
