import Vue from 'vue';
import VueI18n from "vue-i18n";
import { locales } from "../assets/locales/locales"
import { GLOBAL_PARAMS, gtagSet } from './tracking';

Vue.use(VueI18n);

export function setLocaleCache(newLocaleCode) {
    localStorage.setItem('locale', newLocaleCode);
    gtagSet({[GLOBAL_PARAMS.LANGUAGE_CODE]: newLocaleCode});
}

function getLocale () {
    const output = localStorage.getItem('locale') || navigator.language;
    gtagSet({[GLOBAL_PARAMS.LANGUAGE_CODE]: output});
    return output;
}

export default new VueI18n({
    locale: getLocale(),
    fallbackLocale: 'en',
    messages: locales,
});