<template>
    <div class="faq d-flex flex-column align-center px-4">
        <f-a-q-background></f-a-q-background>
        <div class="faq__separator-title rounded-pill background_primary"></div>
        <div class="faq__title mt-4">
            {{$t('home.faq.title')}}
        </div>
        <div class="faq__subtitle mt-4">
            {{$t('home.faq.subtitle_p1')}}
            <word-example word="question"></word-example>
            {{$t('home.faq.subtitle_p2')}}
        </div>
        <div class="faq__content-questions d-flex flex-column align-center justify-center mt-4">
            <div class="faq__categories d-flex flex-row justify-center flex-wrap">
                <lexios-button v-for="(item, index) in groups"
                               v-bind:key="index"
                               class="faq__category py-4"
                               v-bind:clicked-state="selectedGroup === index"
                               v-bind:outlined="selectedGroup !== index"
                               v-bind:color-text="'background_primary'"
                               v-bind:color-text-clicked="'white'"
                               v-bind:background-color="'background_primary'"
                               v-bind:background-color-clicked="'background_primary'"
                               v-on:click.native="selectedGroup = index"
                >
                    {{item.category}}
                </lexios-button>
            </div>
            <question-list
                    class="faq__question-list mt-10"
                    v-bind:questions="groups[selectedGroup].questions"
                    ref="question_list"
            ></question-list>
        </div>
        <v-divider class="faq__divider mt-12"></v-divider>
    </div>
</template>

<script>
    import QuestionList from './QuestionList';
    import LexiosButton from '../LexiosButton';
    import FAQBackground from './FAQBackground';
    import WordExample from '../popupBox/WordExample';

    export default {
        name: "FAQ",
        components: {WordExample, FAQBackground, LexiosButton, QuestionList},
        mounted () {
            this.scrollRoute();
        },
        data()  {
            return {
                selectedGroup: 0,
            };
        },
        computed: {
            groups () {
                return [
                    {
                        category: this.$t('home.faq.category_how_it_works.name'),
                        categoryId: 'category_how_it_works',
                        questions: [
                            [
                                this.$t('home.faq.category_how_it_works.question_free_to_use.question'),
                                this.$t('home.faq.category_how_it_works.question_free_to_use.answer'),
                                'question_free_to_use',
                            ],
                            [
                                this.$t('home.faq.category_how_it_works.question_combines.question'),
                                this.$t('home.faq.category_how_it_works.question_combines.answer'),
                                'question_combines',
                            ],
                            [
                                this.$t('home.faq.category_how_it_works.question_flashcards.question'),
                                this.$t('home.faq.category_how_it_works.question_flashcards.answer'),
                                'question_flashcards',
                            ],
                            [
                                this.$t('home.faq.category_how_it_works.question_change_words.question'),
                                this.$t('home.faq.category_how_it_works.question_change_words.answer'),
                                'question_change_words',
                            ],
                            [
                                this.$t('home.faq.category_how_it_works.question_more_words.question'),
                                this.$t('home.faq.category_how_it_works.question_more_words.answer'),
                                'question_more_words',
                            ],
                            [
                                this.$t('home.faq.category_how_it_works.question_not_enough.question'),
                                this.$t('home.faq.category_how_it_works.question_not_enough.answer'),
                                'question_not_enough',
                            ],
                            [
                                this.$t('home.faq.category_how_it_works.question_word_mastery.question'),
                                this.$t('home.faq.category_how_it_works.question_word_mastery.answer'),
                                'question_word_mastery',
                            ],
                            [
                                this.$t('home.faq.category_how_it_works.question_language_support.question'),
                                this.$t('home.faq.category_how_it_works.question_language_support.answer'),
                                'question_language_support',
                            ],
                        ],
                    },
                    {
                        category: this.$t('home.faq.category_data.name'),
                        categoryId: 'category_data',
                        questions: [
                            [
                                this.$t('policy_pages.privacy.subsection_0.title'),
                                this.$t('policy_pages.privacy.subsection_0.content'),
                                'question_account',
                            ],
                            [
                                this.$t('home.faq.category_data.question_private.question'),
                                this.$t('home.faq.category_data.question_private.answer'),
                                'question_private',
                            ],
                            [
                                this.$t('home.faq.category_data.question_access_websites.question'),
                                this.$t('home.faq.category_data.question_access_websites.answer'),
                                'question_access_websites',
                            ],
                            [
                                this.$t('home.faq.category_data.question_delete_account.question'),
                                this.$t('home.faq.category_data.question_delete_account.answer'),
                                'question_delete_account',
                            ],
                        ],
                    },
                    {
                        category: this.$t('home.faq.category_about_us.name'),
                        categoryId: 'category_about_us',
                        questions: [
                            [
                                this.$t('home.faq.category_about_us.question_make_money.question'),
                                this.$t('home.faq.category_about_us.question_make_money.answer'),
                                'question_make_money',
                            ],
                            [
                                this.$t('home.faq.category_about_us.question_behind_lexios.question'),
                                this.$t('home.faq.category_about_us.question_behind_lexios.answer'),
                                'question_behind_lexios',
                            ],
                            [
                                this.$t('home.faq.category_about_us.question_feedback.question'),
                                this.$t('home.faq.category_about_us.question_feedback.answer'),
                                'question_feedback',
                            ],
                        ],
                    },
                ]
            },
        },
        methods: {
            scrollRoute () {
                const options = {
                    duration: 500,
                    easing: 'easeInOutCubic',
                };
                // noinspection JSUnresolvedVariable
                const subsection = this.$route.params.subsection;
                if (this.$route.params.section === 'faq' && subsection) {
                    let groupIndex = -1;
                    let panelIndex = -1;
                    for (let i = 0; i < this.groups.length; ++i) {
                        for (let j = 0; j < this.groups[i].questions.length; ++j) {
                            if (this.groups[i].questions[j][2] === subsection) {
                                panelIndex = j;
                                break;
                            }
                        }
                        if (panelIndex !== -1) {
                            groupIndex = i;
                            break;
                        }
                    }
                    if (groupIndex !== -1) {
                        this.selectedGroup = groupIndex;
                        // noinspection JSUnresolvedVariable
                        setTimeout(() => {
                                // noinspection JSUnresolvedVariable
                                this.$refs.question_list.updatePanelIndex(panelIndex);
                                this.$vuetify.goTo(`#${subsection}`, options)
                            },
                            300,
                        );
                    }
                }
            },
        },
        watch: {
            selectedGroup () {
                // noinspection JSUnresolvedVariable
                this.$refs.question_list.resetPanelIndex();
            },
            $route () {
                this.scrollRoute(500);
            },
        },
    }
</script>

<style scoped lang="sass">
    .faq
        width: 100%
        margin-bottom: 100px
        position: relative
        z-index: 2

        .faq__separator-title
            width: 115px
            height: 4px

        .faq__title
            max-width: 100%
            width: 1000px
            font-size: 50px
            font-weight: bold
            text-align: center

        .faq__subtitle
            font-size: 18px
            max-width: 800px
            text-align: center

        .faq__content-questions
            width: 100%

        .faq__categories
            max-width: 100%
            width: 800px

        .faq__category
            font-size: 18px !important
            font-weight: bold
            height: 64px !important
            padding-left: 24px !important
            padding-right: 24px !important
            border-width: 2px !important
        //box-shadow: 0 21px 43px #00000018 !important

        .faq__question-list
            max-width: 100%
            width: 920px

        .faq__divider
            width: 100% !important
            max-width: 1000px

        @media screen and (max-width: 600px)
            .faq__title
                font-size: 40px
                line-height: 50px

</style>