import { DAY } from './time';
import { getLanguageCode } from './languages';
import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/firestore';

export function isMobile () {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export const FIREFOX = 'Firefox';
export const CHROME = 'Chrome';
export const EDGE = 'Edge';
export const OPERA = 'Opera';
export const SAFARI = 'Safari';
export const IE = 'IE';
export const UNKNOWN = 'Unknown';

/**
 * Uses specific browser attributes to determine which browser it is
 */

/* eslint-disable */
export function getBrowser () {
    if ((!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0) {
        return OPERA;
    }
    if (typeof InstallTrigger !== 'undefined') {
        return FIREFOX;
    }
    if (/constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification))) {
        return SAFARI;
    }
    if (/*@cc_on!@*/false || !!document.documentMode) {
        return IE;
    }
    if (!!window.StyleMedia || navigator.userAgent.indexOf("Edg") !== -1) {
        return EDGE;
    }
    if (/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)) {
        return CHROME
    }
    return UNKNOWN;
}

/**
 * Check that the email provided has the correct format
 * @param email
 * @return {boolean}
 */
export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

/**
 * Requires firebase user to be logged in. Should only be called a limited amount of times and be stored.
 * Atomically tries to get a new ID.
 * @param categoryName
 */
export async function generate6digitsId (categoryName) {
    const buckets = 100;

    function numberToId(number) {
        const targetDigits = 6;
        let currentDigits = 1;
        const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
        const alphabetSize = alphabet.length;
        let output = '';
        while (Math.floor(number / alphabetSize) !== 0) {
            ++currentDigits;
            output += alphabet[number % alphabetSize];
            number = Math.floor(number / alphabetSize);
        }
        output += alphabet[number];
        for (;currentDigits < targetDigits; ++currentDigits) {
            output += '0';
        }
        return 'X' + output.split('').reverse().join('');
    }
    return new Promise(resolve => {
        const bucketName = `${categoryName}${Math.floor(Math.random() * buckets)}`;
        const dbRef = firebase.firestore().collection('increasingIds').doc(bucketName);

        dbRef.get().then(doc => {
            const newValue = doc.data().value + 1;
            dbRef.update({
                value: newValue,
            })
                .then(_ => resolve(numberToId(newValue)))
                .catch(_ => generate6digitsId(categoryName).then(_ => resolve(_)));
        })
    });
}

export function isSupportedBrowser (browser) {
    return new Set([CHROME, EDGE, OPERA/*, FIREFOX*/]).has(browser);
}

export function pad (num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

export function dateMinusDayToString (days, reference = new Date()) {
    return dateToString(new Date(reference.getTime() - days * DAY));
}

export function dateToString (date) {
    return date.getFullYear() + '-' + (pad(date.getMonth() + 1, 2)) + '-' + pad(date.getDate(), 2);
}

export const debounce = (func, delay) => {
    let inDebounce;
    return function () {
        const context = this;
        const args = arguments;
        clearTimeout(inDebounce)
        inDebounce = setTimeout(() =>
                func.apply(context, args)
            , delay);
    }
}

export function uuidv4 () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function posY (elm) {
    let test = elm, top = 0;
    while (!!test && test.tagName.toLowerCase() !== "body") {
        top += test.offsetTop;
        test = test.offsetParent;
    }
    return top;
}

export function posX (elm) {
    let test = elm, left = 0;
    while (!!test && test.tagName.toLowerCase() !== "body") {
        left += test.offsetLeft;
        test = test.offsetParent;
    }
    return left;
}

function viewPortHeight () {
    if (window.innerWidth) {
        return window.innerHeight;
    }
    const de = document.documentElement;
    if (de && !isNaN(de.clientHeight)) {
        return de.clientHeight;
    }
    return 0;
}

export function viewPortWidth () {
    if (window.innerHeight) {
        return window.innerWidth;
    }
    const de = document.documentElement;
    if (de && !isNaN(de.clientWidth)) {
        return de.clientWidth;
    }
    return 0;
}

function scrollY () {
    if (window.pageYOffset) {
        return window.pageYOffset;
    }
    return Math.max(document.documentElement.scrollTop, document.body.scrollTop);
}

export function isVisible (elm) {
    const vpH = viewPortHeight(), // Viewport Height
        st = scrollY(), // Scroll Top
        y = posY(elm);

    return y <= (vpH + st) && y >= st && elm.offsetHeight > 0;
}

export function hashCode (s) {
    let ret = 0;
    for (let i = 0, len = s.length; i < len; i++) {
        ret = (31 * ret + s.charCodeAt(i)) << 0;
    }
    return ret;
}

let isSpeaking = false;
const receivedVoices = new Map();

/**
 * Pronounce the text in the given language. Only one text can be pronounced at once.
 * WARNING: user needs to be logged in Firebase to successfully work when language not supported by the browser
 * @param toPronounce content to hear
 * @param language (not the language code!)
 */
export async function speakWord (toPronounce, language) {
    if (isSpeaking) {
        return;
    }
    isSpeaking = true;
    const languageCode = getLanguageCode(language);
    const onEnd = () => { isSpeaking = false };

    const key = `${toPronounce}_${languageCode}`;
    if (receivedVoices.has(key)) {
        playOutput(receivedVoices.get(key), onEnd);
    } else if (await isLanguageCodeSupported(languageCode)) {
        const speaker = new SpeechSynthesisUtterance(toPronounce);
        speaker.lang = languageCode;
        window.speechSynthesis.speak(speaker);
        speaker.onend = onEnd;
    } else {
        firebase
            .app()
            .functions('europe-west2')
            .httpsCallable('text2speech')({text: toPronounce, languageCode})
            .then(res => {
                const audioContent = Object.values(res.data.audioContent);
                receivedVoices.set(key, audioContent);
                playOutput(audioContent, onEnd);
            });
    }
}

async function isLanguageCodeSupported (code) {
    return (await getBrowserSpeeches()).filter(x => x.lang.substr(0, 2) === code).length > 0;
}

function playOutput (audioContent, onEnd) {
    const arrayBuffer = new Uint8Array(audioContent).buffer;
    let audioContext = new AudioContext();
    let outputSource;
    try {
        if (arrayBuffer.byteLength > 0) {
            audioContext.decodeAudioData(arrayBuffer,
                function (buffer) {
                    audioContext.resume();
                    outputSource = audioContext.createBufferSource();
                    outputSource.connect(audioContext.destination);
                    outputSource.buffer = buffer;
                    outputSource.start(0);
                    outputSource.onended = onEnd;
                },
                console.error,
            );
        }
    } catch (e) {
        console.error(e);
    }
}

function getBrowserSpeeches () {
    return new Promise(
        resolve => {
            let synth = window.speechSynthesis;
            let id;

            const handler = () => {
                if (synth.getVoices().length !== 0) {
                    resolve(synth.getVoices());
                    clearInterval(id);
                }
            }
            id = setInterval(handler, 10);
            handler();
        }
    )
}

/**
 * In place shuffling of an array
 * @param array
 * @return the same array, shuffled
 */
export function shuffle(array) {
    let currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }

    return array;
}

/**
 * Firebase user must be loggedIn before that call...!
 * @return {Promise<boolean>}'
 */
export async function isPremium() {
    return true;
    /*
    const decodedToken = await firebase.auth().currentUser.getIdToken(true)
        .then(async () => {
            return await firebase.auth().currentUser.getIdTokenResult();
        });
    return decodedToken.claims.stripeRole === 'premium' || decodedToken.claims.premiumLife === true;*/
}