<template>
    <div class="word-mastery-section elevation-4 pa-2 d-flex flex-column">
        <div class="word-mastery-section__title ml-2 mt-2 mb-2 secondary--text d-flex">
            <div class="mr-2">{{$t('analytics.word_mastery.title')}}</div>
            <lexios-icon-button v-bind:title="$t('analytics.word_mastery.title_info')"
                                icon-name="mdi-help-circle"
                                color="secondary"
                                hovered-color="background_secondary"
                                popup-size="l"
            ></lexios-icon-button>
        </div>
        <div class="grow"></div>
        <table class="word-mastery__table ml-2 mr-2">
            <div v-if="!isPremium" class="word-mastery-section__background-free"></div>
            <div v-if="!isPremium" class="word-mastery-section__background-premium"></div>
            <div v-if="isPremium" class="word-mastery-section__background-premium-only"></div>
            <!--<thead>
            <tr>
                <th class="word-mastery-section__header1 pb-3 text-left secondary--text">
                </th>
                <th :class="`word-mastery-section__header2 text-center ${isPremium ? 'highlight' : 'secondary'}--text ${isPremium ? 'premium':''}`">
                    {{isPremium ? $t('subscription.premium_features.premium') : $t('subscription.premium_features.free')}}
                </th>
                <th v-if="!isPremium" class="word-mastery-section__header3 text-center">
                    {{$t('subscription.premium_features.premium')}}
                </th>
            </tr>
            </thead>-->
            <tbody>
            <tr class="word-mastery-section__row-spacer1"></tr>
            <tr class="word-mastery-section__row">
                <td class="word-mastery-section__col1 py-2 text-left secondary--text">
                    {{displayFreeBoxTop + $t('analytics.word_mastery.display_time_end')}}
                </td>
                <td :class="`word-mastery-section__col2 py-2 text-center secondary--text ${isPremium ? 'premium':''}`">
                    {{wordsFreePlanFormatted + $t('analytics.word_mastery.words')}}
                </td>
                <td v-if="!isPremium" class="word-mastery-section__col3 py-2 text-center">
                    {{wordsPremiumPlanFormatted + $t('analytics.word_mastery.words')}}
                    {{`(+${percentIncreasePremium}%)`}}
                </td>
            </tr>
            <tr v-if="$vuetify.breakpoint.smAndUp" class="word-mastery-section__row-spacer2">
                <td>
                    <v-divider class="ml-4 mr-12"></v-divider>
                </td>
                <td>
                    <v-divider :class="`ml-6 ${isPremium ? 'mr-6' : 'mr-11'}`"></v-divider>
                </td>
                <td v-if="!isPremium" >
                    <v-divider class="ml-6 mr-6"></v-divider>
                </td>
            </tr>
            <tr class="word-mastery-section__row">
                <td class="word-mastery-section__col1 py-2 text-left secondary--text">
                    {{$t('analytics.word_mastery.yearly_projection')}}
                </td>
                <td :class="`word-mastery-section__col2 py-2 text-center secondary--text ${isPremium ? 'premium':''}`">
                    {{wordsYearlyFree.toLocaleString() + $t('analytics.word_mastery.words')}}
                </td>
                <td v-if="!isPremium" class="word-mastery-section__col3 py-2 text-center">
                    {{wordsYearlyPremium.toLocaleString() + $t('analytics.word_mastery.words')}}
                    {{`(+${percentIncreasePremium}%)`}}
                </td>
            </tr>
            </tbody>
        </table>
        <div class="grow"></div>
    </div>
</template>

<script>
    import { DAY, YEAR } from '../../utils/time';
    import LexiosIconButton from '../LexiosIconButton';

    export default {
        name: "WordMasterySection",
        components: {LexiosIconButton},
        props: {
            analyticsData: {
                required: true,
            },
            startDate: {
                type: String,
                required: true,
            },
            endDate: {
                type: String,
                required: true,
            },
            isPremium: {
                type: Boolean,
                required: true,
            },
        },
        methods: {
            convertToYearly(amount) {
                return Math.round(YEAR / DAY / this.filteredData.length * amount);
            },
        },
        computed: {
            displayFreeBoxTop() {
                return this.isPremium
                    ? this.filteredData.length === 7
                        ? this.$t('analytics.word_mastery.display_weekly')
                        : this.$t('analytics.word_mastery.display_generic_p1')
                            + this.filteredData.length
                            + this.$t('analytics.word_mastery.display_generic_p2')
                    : this.$t('analytics.word_mastery.display_weekly');
            },
            wordMasteryFree () {
                return this.filteredData.map(d => d.mastery).reduce((a, b) => a + b, 0);
            },
            wordsFreePlanFormatted () {
                return this.wordMasteryFree.toLocaleString(
                    undefined,
                    { maximumFractionDigits: 1, minimumFractionDigits: 1 },
                );
            },
            wordMasteryPremium () {
                return this.filteredData
                    .map(d => d.words === 0 ? 0 : d.mastery * (d.words + d.premium_words) / d.words)
                    .reduce((a, b) => a + b, 0);
            },
            wordsPremiumPlanFormatted () {
                return this.wordMasteryPremium.toLocaleString(
                    undefined,
                    { maximumFractionDigits: 1, minimumFractionDigits: 1 },
                );
            },
            wordsYearlyFree() {
                return this.convertToYearly(this.wordMasteryFree);
            },
            wordsYearlyPremium() {
                return this.convertToYearly(this.wordMasteryPremium);
            },
            percentIncreasePremium() {
                return Math.round((this.wordsYearlyPremium / this.wordsYearlyFree - 1) * 100);
            },
            filteredData () {
                if (!this.analyticsData) {
                    return [];
                }
                return Object.entries(this.analyticsData).filter(pair => {
                    return pair[0] >= this.startDate && pair[0] <= this.endDate;
                }).map(pair => {
                    return {
                        words: pair[1].w || 0,
                        mastery: pair[1].m || 0,
                        premium_words: pair[1].n || 0,
                    }
                })
            }
        }
    }
</script>

<style scoped lang="sass">
    $sizeCol1: 160px
    $sizeCol2: 150px
    $sizeCol3: 160px
    $margins: 10px
    $margin1to2: 20px
    $margin2to3: 20px
    $margin3: 5px
    $paddingFreeBox: 15px
    $paddingPremiumBox: 20px

    .word-mastery__table
        position: relative
        border-spacing: 0
        border-collapse: collapse

    .word-mastery-section__table
        margin-top: $paddingPremiumBox
        margin-bottom: $paddingPremiumBox
        position: relative
    .word-mastery-section__background-free
        position: absolute
        border: solid 1px rgba(0, 0, 0, 0.1)
        box-shadow: 0 0 30px rgba(23, 55, 107, 0.3)
        border-radius: 15px
        top: -$paddingFreeBox
        left: $sizeCol1
        width: $sizeCol2
        height: calc(100% + 2*#{$paddingFreeBox})
    .word-mastery-section__background-premium
        box-shadow: 0 0 20px rgba(23, 55, 107, 0.5)
        position: absolute
        background: linear-gradient(135deg, #fefffe 0%, #edf7f0 100%)
        border-radius: 15px
        top: calc(-#{$paddingPremiumBox} - 10px)
        left: $sizeCol1 + $sizeCol2 + $margin2to3 + $margin3
        width: $sizeCol2
        height: calc(100% + 2*#{$paddingPremiumBox})
        z-index: -1
    .word-mastery-section__background-premium-only
        box-shadow: 0 0 20px rgba(23, 55, 107, 0.5)
        position: absolute
        background: linear-gradient(135deg, #fefffe 0%, #edf7f0 100%)
        border-radius: 15px
        top: calc(-#{$paddingPremiumBox} + 10px)
        left: $sizeCol1
        width: $sizeCol2
        height: calc(100% + 2*#{$paddingPremiumBox})
        z-index: -1
    .word-mastery-section__header1
        font-size: 18px
        font-weight: bold
        width: $sizeCol1
        padding-right: $margins + $margin1to2
    .word-mastery-section__col1
        font-weight: 500
        width: $sizeCol1
        padding-right: $margins+$margin1to2
    .word-mastery-section__header2
        font-size: 18px
        font-weight: bold
        width: $sizeCol2+$margin2to3
        padding-right: $margins+$margin2to3
        padding-left: $margins
    .word-mastery-section__header2.premium
        padding-right: $margins
        width: $sizeCol2
    .word-mastery-section__col2
        font-size: 20px
        font-weight: 500
        width: $sizeCol2+$margin2to3
        padding-right: $margins+$margin2to3
        padding-left: $margins
    .word-mastery-section__col2.premium
        padding-right: $margins
        width: $sizeCol2
    .word-mastery-section__header3
        font-size: 18px
        font-weight: bold
        width: $sizeCol3
        padding-right: $margins
        padding-left: $margins
        color: #ed540d
    .word-mastery-section__col3
        font-size: 22px
        font-weight: bold
        width: $sizeCol3
        padding-right: $margins
        padding-left: $margins
        color: #ed540d
    .word-mastery-section__row-spacer1
        height: 8px
    .word-mastery-section__row-spacer2
        height: 20px


    @media screen and (max-width: 1248px)
        .word-mastery__table
            margin-top: 16px !important
            margin-bottom: 36px !important

    @media screen and (max-width: 599px)
        .word-mastery-section__header1, .word-mastery-section__col1
            padding-right: 4px !important
        .word-mastery-section__header2, .word-mastery-section__col2
            padding-right: 8px !important
        .word-mastery-section__header3, .word-mastery-section__col3
            font-size: 20px
        .word-mastery__table
            margin-top: 16px !important
            margin-bottom: 16px !important
        th, tr
            border-bottom: solid 1px rgba(0, 0, 0, 0.1)
        .word-mastery-section__background-free,
        .word-mastery-section__background-premium,
        .word-mastery-section__background-premium-only
            display: none

    .word-mastery-section
        position: relative
        background-color: white
        border-radius: 26px
        width: fit-content
        max-width: 600px

    .word-mastery-section__title
        font-size: 24px
        font-weight: bold
</style>