<template>
    <div class="contact-background">
        <div class="contact-background__content-bottom">
            <svg class="contact-background__elm1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 700">
                <path class="contact-background__elm1-path"
                      d="M0,700a576.48,576.48,0,0,1,83.53-96.81C157.81,534.34,231.69,502.52,271,486c51-21.43,88.76-33.93,153.33-49.33,39-9.31,104-21.06,188.67-28.67,50.17-4.51,87.45-4.56,162-4.67,77-.11,88.14,2.7,152,.67,72.25-2.3,115.09-7.71,132-10,25.92-3.52,57.56-7.9,98-18,16-4,67.35-17.37,132-46,45.06-20,93.12-41.24,148-84a579.88,579.88,0,0,0,100-100,583,583,0,0,0,63-100V0L0,2Z"/>
            </svg>
            <logo class="about_background-elm2" v-bind:color="'#386ce2'"></logo>
        </div>
    </div>
</template>

<script>
    import Logo from '../Logo';
    export default {
        name: "ContactWaveBackground",
        components: {Logo}
    }
</script>

<style scoped lang="sass">
    .contact-background
        width: 100%
        height: 100%
        position: absolute
        top: 0
        background: linear-gradient(135deg, #3e69df 0%, #335ed2 100%)

        .contact-background__content-bottom
            position: relative
            height: 100%

        .contact-background__elm1
            position: absolute
            top: -4px

        .contact-background__elm1-path
            fill: white

        .about_background-elm2
            height: 200px
            position: absolute
            right: 0

        @media screen and (min-width: 1905px)
            .about_background-elm2
                top: 500px
                height: 310px
                right: 50px

        @media screen and (max-width: 1904px)
            .about_background-elm2
                top: 320px
                height: 310px
                right: 50px

        @media screen and (max-width: 1264px)
            .about_background-elm2
                top: 300px
                height: 250px
                right: 50px

        @media screen and (max-width: 960px)
            .about_background-elm2
                top: 225px
                height: 200px
                right: 35px

        @media screen and (max-width: 600px)
            .about_background-elm2
                top: 125px
                height: 100px
                right: 25px
</style>