<template>
    <div class="bookmark">
        <analytics-background></analytics-background>
        <div class="bookmark__content ma-auto pt-10 px-8">
            <div class="bookmark__separator-title rounded-pill white"></div>
            <div class="bookmark__title mt-4 white--text mb-2">
                {{$t('bookmarks.title')}}
            </div>
            <div class="white--text mb-4">
                {{`${$t('bookmarks.description')} ${this.isPremium ? '' : $t('bookmarks.not_premium_limit')}`}}
            </div>
            <div v-if="!firebaseUser">
                <connection></connection>
            </div>
            <div v-else-if="bookmarkData && bookmarkData.length === 0" class="white--text">
                {{$t('bookmarks.no_data')}}
            </div>
            <words-list v-else-if="bookmarkData && lemsData && bookmarked"
                        class="bookmark__words-list white rounded pt-4"
                        v-bind:data="bookmarkData"
                        v-bind:lems-data="lemsData"
                        v-bind:bookmarked="bookmarked"
                        v-bind:target-language-code="targetLanguageCode"
                        v-bind:default-items-per-page="10"
            ></words-list>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import Connection from '../connection/Connection';
    import { getLanguageCode } from '../../utils/languages';
    import firebase from 'firebase/app';
    import 'firebase/firestore';
    import { FIRESTORE_BOOKMARK_PATH, FIRESTORE_LEMS_PATH } from '../connection/databasePaths';
    import WordsList from '../WordsList';
    import AnalyticsBackground from '../analytics/AnalyticsBackground';
    import { isPremium } from '../../utils/helper';
    import { EVENTS, GLOBAL_PARAMS, gtagAfterDelay, gtagSet } from '../../plugins/tracking';

    export default {
        name: "Bookmark",
        components: {AnalyticsBackground, WordsList, Connection},
        mounted () {
            this.onFirebaseUserChange();
            gtagAfterDelay(EVENTS.PAGE_VISITED, {pageId: 'bookmarks'});
            gtagSet({[GLOBAL_PARAMS.CURRENT_PAGE]: 'bookmarks'});
        },
        data () {
            return {
                analyticsData: undefined,
                bookmarkData: null,
                bookmarkDataListener: null,
                lemsDataListener: null,
                bookmarked: null,
                lemsData: null,
                isPremium: false,
                targetLanguageCode: null,
            };
        },
        computed: {
            ...mapGetters([
                'firebaseUser', 'userProfile',
            ]),
            bookmarkPath () {
                return this.targetLanguageCode + FIRESTORE_BOOKMARK_PATH;
            },
            lemsPath () {
                return this.userProfile.targetLanguage.toLowerCase() + FIRESTORE_LEMS_PATH;
            }
        },
        methods: {
            updatePremiumStatus () {
                isPremium().then(isPremium => {
                    this.isPremium = isPremium;
                })
            },
            onFirebaseUserChange () {
                if (this.firebaseUser) {
                    this.updatePremiumStatus();

                    this.targetLanguageCode = getLanguageCode(this.userProfile.targetLanguage);

                    if (this.bookmarkDataListener) {
                        this.bookmarkDataListener();
                    }
                    this.bookmarkDataListener = firebase.firestore()
                        .collection(this.bookmarkPath)
                        .doc(this.firebaseUser.uid)
                        .onSnapshot(doc => {
                            if (doc.exists) {
                                this.bookmarked = doc.data();
                                this.bookmarkData = Object.entries(this.bookmarked).map(pair => {
                                    pair[1].l = pair[0];
                                    return pair[1];
                                }).sort((a, b) => a.time - b.time);
                            } else {
                                this.bookmarked = {};
                                this.bookmarkData = [];
                            }
                        });
                    if (this.lemsDataListener) {
                        this.lemsDataListener();
                    }
                    this.lemsDataListener = firebase.firestore()
                        .collection(this.lemsPath)
                        .doc(this.firebaseUser.uid)
                        .onSnapshot(doc => {
                            if (doc.exists) {
                                this.lemsData = new Map(Object.entries(doc.data()));
                            } else {
                                this.lemsData = new Map();
                            }
                        });
                }
            }
        },
        watch: {
            firebaseUser () {
                this.onFirebaseUserChange();
            },
        },
        beforeDestroy() {
            if (this.bookmarkDataListener) {
                this.bookmarkDataListener();
            }
            if (this.lemsDataListener) {
                this.lemsDataListener();
            }
        },
    }
</script>

<style scoped lang="sass">
    .bookmark
        position: relative
        height: 100%
        width: 100%

    .bookmark__content
        position: relative
        height: 100%
        max-width: 1000px
        width: 100%

    .bookmark__separator-title
        position: relative
        width: 115px
        height: 4px

    .bookmark__title
        position: relative
        max-width: 100% !important
        font-size: 50px !important
        font-weight: bold

    @media screen and (max-width: 795px)
        .bookmark__content
            padding-left: 16px !important
            padding-right: 16px !important

</style>