import { ENGLISH, FRENCH, GERMAN, getLanguageCode, SPANISH } from "../utils/languages";

/**
 * Get the firebase auth ui localized
 * @param locale code in i18n format
 */
export function getFirebaseUiLocalized(locale) {
    switch (locale.substring(0, 2)) {
        case getLanguageCode(ENGLISH):
            return require('firebaseui/dist/npm__en');
        case getLanguageCode(FRENCH):
            return require('firebaseui/dist/npm__fr');
        case getLanguageCode(GERMAN):
            return require('firebaseui/dist/npm__de');
        case getLanguageCode(SPANISH):
            return require('firebaseui/dist/npm__es');
        default:
            return require('firebaseui/dist/npm__en');
    }
}