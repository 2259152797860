<template>
    <v-btn :class="`how-button d-flex text-none flex-row pl-8 pr-6 align-center rounded-pill justify-start
                    ${background}
                    elevation-${elevation}
    `">
        <div :class="`how-button__icon-circle rounded-pill ${iconCircleColor}`"></div>
        <div class="how-button__icon">
            <slot name="icon" ></slot>
        </div>
        <div :class="`how-button__text
                    ${textColor}--text
                    ml-2 flex-grow-1
                    font-weight-${textWeight}`">{{text}}</div>
    </v-btn>
</template>

<script>
    export default {
        name: "HowButton",
        mounted () {
            this.$emit('icon-color', this.textColor);
        },
        props: {
            text: {
                type: String,
                required: true,
            },
            isSelected: {
                type: Boolean,
                required: true,
            }
        },
        computed: {
            textColor () {
                if (this.isSelected && this.$vuetify.breakpoint.mobile) {
                    return 'white';
                } else if (this.isSelected) {
                    return 'highlight';
                } else if (this.$vuetify.breakpoint.mobile) {
                    return 'secondary'
                }
                return 'secondary';
            },
            background () {
                if (this.isSelected && this.$vuetify.breakpoint.mobile) {
                    return 'background_primary';
                }
                return 'white';
            },
            elevation () {
                if (this.isSelected && !this.$vuetify.breakpoint.mobile) {
                    return '5';
                }
                return '0';
            },
            iconCircleColor () {
                if (this.isSelected && this.$vuetify.breakpoint.mobile) {
                    return 'disable_primary';
                } else if (this.isSelected) {
                    return 'background_highlight';
                }
                return 'disable_color';
            },
            textWeight() {
                if (this.isSelected) {
                    return 'bold';
                }
                return 'regular';
            }
        },
        watch: {
            textColor (value) {
                this.$emit('icon-color', value);
            }
        }
    }
</script>

<style scoped lang="sass">
    .how-button
        height: 115px !important
        width: 100% !important
        max-width: 500px !important
        justify-content: center

        ::v-deep .v-btn__content
            max-width: 100% !important
            height: 100% !important

        .how-button__icon-circle
            height: 55px
            width: 55px
            position: absolute
            left: -5px
            top: 20px

        .how-button__icon
            width: 60px

        .how-button__text
            word-wrap: break-word
            white-space: normal
            width: calc(100% - 76px)
            max-width: calc(100% - 76px)
            display: block
            font-size: 20px

        @media screen and (max-width: 600px)
            .how-button__text
                font-size: 16px
</style>