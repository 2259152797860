<template>
    <div>Hello world</div>
</template>

<script>
    import { EVENTS, gtag } from '../plugins/tracking';

    export default {
        name: "WelcomePage",
        mounted() {
            gtag('event', EVENTS.WELCOME_PAGE, {isMobile: this.isMobile});
        }
    }
</script>

<style scoped>

</style>