import {DAY} from '../../utils/time';
const LEARNT_SEEN_COUNT = 16;
const LEARNT_SEEN_COUNT_COMPOUND = 13;

const FACTOR = 1.32; // 346 days on 16 total base
const MINIMUM_COUNT_FOR_SCORE = 1;

const COMPUTING_FACTOR1 = Math.sqrt(2 * Math.PI);
const SCORE_FACTOR_COMPOUND = 1.2;
const SCORE_FACTOR_REGULAR = 1;


/**
 * Get the score for each lem. There should not be any duplicate.
 * @param lemsDict
 * @param lems
 * @param rawUserLevel
 */
export function getLemsScores(lemsDict, lems, rawUserLevel) {
    const timestamp = Date.now();
    const userLevel = getUserLevel(rawUserLevel)
    return new Map(lems.map(lem => {
        const subLems = getSubLems(lem);
        const subLemsValues = subLems.map(subLemInfos => {
            const subLem = subLemInfos.lem;
            const seenCount = getSeenCount(lemsDict, subLem);
            const countIncrease = getMaxCountIncrease(lemsDict, subLem, timestamp);
            const wordMasteryRef = getWordMasteryReference(subLem);
            return {
                lem: subLem,
                score: getLemScore(lemsDict, subLem, 1, userLevel, timestamp),
                countIncrease,
                wordMastery: getWordMastery(seenCount, wordMasteryRef),
                wordMasteryIncrease: getWordMastery(countIncrease, wordMasteryRef),
                learningMult: subLemInfos.learningMult,
            }
        })

        const scoreUnweighted = subLemsValues.reduce((output, item) => {
            output.score += item.score * item.learningMult;
            output.wordMastery += item.wordMastery * item.learningMult;
            output.wordMasteryIncrease += item.wordMasteryIncrease * item.learningMult;
            output.learningMult += item.learningMult;
            return output;
        }, {score: 0, wordMastery: 0, wordMasteryIncrease: 0, learningMult: 0})
        //const score = scoreUnweighted.score / scoreUnweighted.learningMult;
        const wordMastery = scoreUnweighted.wordMastery / scoreUnweighted.learningMult;
        //const wordMasteryIncrease = scoreUnweighted.wordMasteryIncrease / scoreUnweighted.learningMult;
        return [lem, wordMastery];
    }));
}

function computeScore(userLevel, wordDifficulty, dt, visitedCount) {
    const dtDays = dt / DAY;
    let score;
    const targetDays = FACTOR ** visitedCount;
    if (dtDays < targetDays) {
        score = Math.E * (dtDays / targetDays);
    } else {
        score = Math.E ** (dtDays / targetDays)
    }
    return score * computeFactorDifficulty(userLevel, wordDifficulty);
}

function computeScoreNewWord(userLevel, wordDifficulty) {
    return Math.E * computeFactorDifficulty(userLevel, wordDifficulty);
}

/**
 * Note: This is absolutely ugly and makes no sense as it is based on stupid heuristic from @Nicolas :)
 * @param userLevel in range [0-15000]
 * @param wordDifficulty [0-...]
 */
function computeFactorDifficulty(userLevel, wordDifficulty) {
    let sigma, scale;
    const wordDifficultyCapped = Math.min(wordDifficulty, 15000);
    if (wordDifficulty > userLevel) {
        if (userLevel < 2000) {
            sigma = 1000 + 3000 * userLevel / 2000;
            scale = 2500 + 7500 * userLevel / 2000;
        } else {
            sigma = 4000 * userLevel / 2000;
            scale = 10000 * userLevel / 2000;
        }
    } else {
        if (userLevel < 2000) {
            sigma = 200 + 600 * userLevel / 2000;
            scale = 400 + 1600 * userLevel / 2000;
        } else {
            sigma = 800 * userLevel / 2000;
            scale = 2000 * userLevel / 2000;
        }
    }

    //console.log('diff', (1 / (sigma * COMPUTING_FACTOR1)) * Math.pow(Math.E, -Math.pow(wordDifficultyCapped - userLevel, 2) / 2 / sigma ** 2) * scale)
    return (1 / (sigma * COMPUTING_FACTOR1)) * Math.pow(Math.E, -Math.pow(wordDifficultyCapped - userLevel, 2) / 2 / sigma ** 2) * scale;
}

/**
 * A1(@0) -> 0
 * A2(@25) -> 800
 * B1(@50) -> 2000
 * B2(@75) -> 5000
 * C1(@100) -> 15000
 * @param rawUserLevel [0-100]
 */
function getUserLevel(rawUserLevel) {
    let pow, div;
    if (rawUserLevel < 50) {
        pow = 1.32193;
        div = 0.0880818;
    } else if (rawUserLevel < 75) {
        pow = 2.25985;
        div = 3.45453;
    } else {
        pow = 3.81884;
        div = 2894.61;
    }
    return rawUserLevel ** pow / div
}

function isCompound(lem) {
    return lem.includes(' ');
}

function getLemScore(lemsDict, lem, difficulty, userLevel, timestamp) {
    let data = getLemData(lemsDict, lem);
    const multiplier = isCompound(lem) ? SCORE_FACTOR_COMPOUND : SCORE_FACTOR_REGULAR;
    let score;
    if (data) {
        if (data.learnt) {
            return 0;
        }
        if (data.c >= MINIMUM_COUNT_FOR_SCORE) {
            score = computeScore(userLevel, difficulty, timestamp - data.t, data.c);
        } else {
            score = computeScoreNewWord(userLevel, difficulty);
        }
    } else {
        score = computeScoreNewWord(userLevel, difficulty);
    }
    return score * multiplier;
}

function getSeenCount(lemsDict, lem) {
    let data = getLemData(lemsDict, lem);
    if (data) {
        if (data.learnt) {
            return getWordMasteryReference(lem);
        }
        return data.c;
    }
    return 0;
}

function getWordMastery(visitedCount, wordMasteryReference) {
    return visitedCount / wordMasteryReference;
}

function getWordMasteryReference(lem) {
    if (isCompound(lem)) {
        return LEARNT_SEEN_COUNT_COMPOUND;
    }
    return LEARNT_SEEN_COUNT;
}

function getLemData(lemsDict, lem) {
    if (lemsDict.has(lem)) {
        return lemsDict.get(lem);
    }
    return false;
}

function getMaxCountIncrease(lemsDict, lem, timestamp) {
    let visitedCount;
    let dt;
    let data = getLemData(lemsDict, lem);
    if (data) {
        if (data.learnt) {
            return 0;
        }
        visitedCount = data.c;
        dt = timestamp - data.t;
    } else {
        return 1;
    }
    // 0.9 is a rough approximation so that viewing it twice at dt/2 is close to dt
    return Math.min(1, (dt / DAY / (FACTOR ** visitedCount)) ** 0.9);
}

function getSubLems(lem) {
    if (!lem.includes(' ')) {
        return [{learningMult: 1, lem}];
    }
    return [
        ...lem.split(' ').filter(subLem => subLem.length > 2)
            .map(subLem => {
                return {learningMult: 0.5, lem: subLem};
            }),
        {learningMult: 1, lem},
    ]
}
