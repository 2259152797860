<template>
    <popup-button class="popup-button__wrapper"
                  v-bind:title="title"
                  v-bind:popup-size="popupSize"
                  @hovered="isHovered = $event">
        <v-icon :color="isHovered ? hoveredColor : color" style="font-size: 26px; margin-top: -6px">
            {{iconName}}
        </v-icon>
    </popup-button>
</template>

<script>
    import PopupButton from './popupBox/PopupButton';
    export default {
        name: "LexiosIconButton",
        components: {PopupButton},
        props: {
            title: {
                type: String,
                default: null,
            },
            iconName: {
                type: String,
                required: true,
            },
            popupSize: {
                type: String, //s, m, l, xl
                default: 's',
            },
            hoveredColor: {
                type: String,
                default: 'background_primary',
            },
            color: {
                type: String,
                default: 'primary',
            },
        },
        data() {
            return {
                isHovered: false,
            };
        }
    }
</script>

<style scoped>

</style>