<template>
    <svg class="icon" height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <clipPath :id="`clip-path_${uuid}`">
                <path d="M503.3,116.1L240.8,66.2c-1.6-0.3-3.2-0.2-4.8,0.2L7.8,128.9c-5.7,1.6-9,7.4-7.4,13.1c1,3.8,4,6.6,7.8,7.5l97.8,23.1v60.1
	l-54.6-19.5c-15.9-6.7-35.7,6.7-46.1,31.2c-2,4.7-3.5,9.5-4.5,14.5c-0.8,3.1-0.2,6.4,1.6,9c1.2,1.7,2.9,3,4.9,3.7l-3.1,14.2
	c-3.6,17.9,0.5,30.7,10.7,35l219,108.6c4.9,2.5,11,0.4,13.4-4.5l0.6-1.2c2.5-4.9,0.4-10.9-4.5-13.4l-106.5-52.8
	c-3.3-9.2-3.7-19.2-0.9-28.6l111.4,49.6c5.4,2.4,11.7,0,14.1-5.4c0.1-0.2,0.2-0.5,0.3-0.7c2.3-6.3,5.4-12.3,9.1-18l16.6,6.5
	c-2,2.9-3.9,6-5.7,9.1c-6.3,11-26.2,48.1-16.3,66.3c2.8,5.4,8.3,8.8,14.3,9.2c1.1,0.1,2.3,0.2,3.4,0.2c23.4,0,44.3-24,55.4-45.5
	c18.9-36.4,13.6-75.4-12-88.8c-2.6-1.4-5.4-2.5-8.3-3.2l-16.2-5.8c9.4-0.2,18.8-0.9,28.1-2.1c42.8-6.2,64.4-17.9,64.4-34.7v-58.7
	c0-5.5-4.5-10-10-10h-2c-5.2,0-9.3,4.2-9.3,9.3v58.4c-2.3,2.7-14.4,10-46.3,14.7c-27.9,2.2-55.9,2.9-83.9,2.1l-54-19.3l0.1-0.1
	c-0.8-0.3-1.7-0.4-2.5-0.7l-59.4-21.2v-62.6L275,212.7c1.9,0.5,4,0.4,5.9-0.3l173.7-58.8v27c-11.6,4.4-19.3,15.5-19.3,28v13.6
	c-0.3,16.6,12.9,30.3,29.5,30.6c16.6,0.3,30.3-12.9,30.6-29.5c0-0.4,0-0.8,0-1.1v-13.6c0-12.4-7.7-23.6-19.4-28v-34.2l28.8-9.8
	c5.6-1.9,8.6-7.9,6.7-13.5C510.2,119.5,507.1,116.8,503.3,116.1L503.3,116.1z M25,252.9c6.4-15.1,16.1-20.8,18.7-19.7l103.7,37
	c-10.2,8-18.4,18.2-23.9,29.9l-99.6-44.4C24.2,254.8,24.5,253.9,25,252.9L25,252.9z M113,345.8l-88.2-43.7
	c-0.8-3.9-0.7-7.9,0.3-11.7l2.1-9.7l89,39.7C113.9,328.6,112.9,337.2,113,345.8z M247.1,353c-0.3,0.6-0.6,1.2-0.9,1.9l-103.2-46
	c5-11.1,13.7-20.3,24.5-25.9c3.6-1.7,7.6-2.2,11.5-1.5l99.7,35.6C265.2,326.1,254.4,338.5,247.1,353L247.1,353z M316.8,330.7
	c14.5,7.6,15.9,35,2.9,60c-9.8,18.9-24.5,32.9-35.7,33.9c2.1-15.5,7.7-30.4,16.2-43.6c3.6-6.3,7.7-12.3,12.3-17.9
	c3.7-4.5,3.1-11.3-1.5-15c-0.9-0.7-1.9-1.3-2.9-1.7l-22-8.5C294.2,329.7,306.1,326.9,316.8,330.7L316.8,330.7z M474,222.2
	c0.2,5.3-4.4,9.7-9.9,8.9c-2.6-0.3-4.9-1.9-6.2-4.1c-0.9-1.5-1.3-3.2-1.2-4.8v-13.6c0.2-4.8,4.2-8.6,9-8.4c4.6,0.2,8.2,3.8,8.4,8.4
	V222.2z M277,191.3L53.8,138.5l185.4-50.8l220.2,41.8L277,191.3z"/>
            </clipPath>
            <linearGradient :id="`grad_${uuid}`"
                            :gradientTransform="`rotate(${rotateAngle})`"
                            x1="0%"
                            y1="0%"
                            x2="100%"
                            y2="0%"
            >
                <stop offset="0%" :style="`stop-color:${gradColorStart};stop-opacity:1`"/>
                <stop offset="100%" :style="`stop-color:${gradColorEnd};stop-opacity:1`"/>
            </linearGradient>
        </defs>
        <g :clip-path="`url(#clip-path_${uuid})`">
            <rect :fill="iconColor" x="0" y="0" width="512" height="512"/>
        </g>
    </svg>
</template>

<script>
    import { uuidv4 } from '../../../utils/helper';

    export default {
        name: "MaxWordMasteryIcon",
        props: {
            color: {
                type: String,
                default: '',
            },
            gradColorStart: {
                type: String,
                default: 'black',
            },
            gradColorEnd: {
                type: String,
                default: 'black',
            },
            rotateAngle: {
                type: Number,
                default: 0,
            }
        },
        data () {
            return {
                uuid: uuidv4(),
            }
        },
        computed: {
            iconColor () {
                if (this.color === '') {
                    return `url(#grad_${this.uuid})`;
                }
                return this.color;
            }
        },
    }
</script>

<style scoped lang="sass">
    .icon
        border-radius: 0 !important
</style>