<template>
    <div class="analytics mb-10">

        <analytics-background></analytics-background>
        <div class="analytics__content pl-8 pr-4 pt-10">
            <div class="d-flex justify-space-between flex-row align-end flex-wrap mr-4">
                <div>
                    <div class="analytics__separator-title rounded-pill white"></div>
                    <div class="analytics__title mt-4 white--text">
                        {{$t('analytics.title')}}
                    </div>
                </div>
                <date-selector v-if="firebaseUser && hasData"
                               class="mt-4"
                               v-on:date-range="dateRange = $event"
                               v-bind:is-premium="isPremium"
                ></date-selector>
            </div>
            <div v-if="!firebaseUser">
                <connection ref="connection"></connection>
            </div>
            <v-progress-circular v-else-if="!loadingComplete"
                                 class="ma-3 flex-xl-grow-0"
                                 indeterminate
                                 color="grey darken-4"
            ></v-progress-circular>
            <div v-else-if="!hasData" class="analytics__no-data white--text mt-4">
                {{$t('analytics.no_data')}}
            </div>
            <div v-else class="analytics__panels d-flex flex-column flex-wrap">
                <div class="analytics__panels d-flex flex-row flex-wrap">
                    <!--<v-slider v-model="scale" min="0.1" max="2" step="0.1"></v-slider>-->
                    <word-mastery-section class="analytics__word-mastery-section mr-4 mt-4"
                                          v-bind:analytics-data="analyticsData"
                                          v-bind:start-date="startDate"
                                          v-bind:end-date="endDate"
                                          v-bind:is-premium="isPremium"
                    ></word-mastery-section>
                    <words-section class="analytics__words-section mr-4 mt-4 grow"
                                   v-bind:analytics-data="analyticsData"
                                   v-bind:start-date="startDate"
                                   v-bind:end-date="endDate"
                                   v-bind:is-premium="isPremium"
                    ></words-section>
                </div>
                <div :class="`analytics__panels d-flex flex-wrap ${recentWordsLongDisplay ? 'flex-column' : 'flex-row'}`">
                    <time-section :class="`analytics__time-section mt-4 mr-4 grow ${isPremium ? 'premium' : 'free-plan'}`"
                                  v-bind:analytics-data="analyticsData"
                                  v-bind:start-date="startDate"
                                  v-bind:end-date="endDate"
                                  v-bind:is-premium="isPremium"
                    ></time-section>
                    <recent-words v-if="recentlyHoveredData && lemsData && bookmarked"
                                  id="analytics__recently-hovered"
                                  class="analytics__recently-hovered mt-4 mr-4"
                                  v-bind:data="recentlyHoveredData"
                                  v-bind:lems-data="lemsData"
                                  v-bind:bookmarked="bookmarked"
                                  v-bind:target-language-code="targetLanguageCode"
                                  v-bind:is-premium="isPremium"
                                  v-on:long="recentWordsLongDisplay = $event"
                    ></recent-words>
                </div>
            </div>
            <div v-if="!isPremium && loadingComplete && hasData"
                 class="analytics__not-premium-overlay d-flex flex-column align-center">
                <div class="analytics__not-premium-text mb-2 mx-2">
                    {{$t('analytics.hide_premium_text')}}
                </div>
                <router-link to="/subscription">
                    <lexios-button class="analytics__not-premium-button"
                                   background-color="primary"
                    >
                        {{$t('analytics.hide_premium_button')}}
                    </lexios-button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import WordsSection from './WordsSection';
    import { mapGetters } from 'vuex';
    import firebase from 'firebase/app';
    import 'firebase/firestore';
    import Connection from '../connection/Connection';
    import DateSelector from './DateSelector';
    import { dateMinusDayToString, dateToString, isPremium } from '../../utils/helper';
    import AnalyticsBackground from './AnalyticsBackground';
    import WordMasterySection from './WordMasterySection';
    import { getLanguageCode } from '../../utils/languages';
    import RecentWords from './RecentWords';
    import TimeSection from './TimeSection';
    import LexiosButton from '../LexiosButton';
    import {
        FIRESTORE_ANALYTICS_PATH, FIRESTORE_BOOKMARK_PATH,
        FIRESTORE_LEMS_PATH,
        FIRESTORE_RECENTLY_HOVERED_PATH
    } from '../connection/databasePaths';
    import { EVENTS, GLOBAL_PARAMS, gtagAfterDelay, gtagSet } from '../../plugins/tracking';

    export default {
        name: "Analytics",
        components: {
            LexiosButton, TimeSection,
            RecentWords, WordMasterySection, AnalyticsBackground, DateSelector, Connection, WordsSection
        },
        data () {
            return {
                analyticsData: undefined,
                scale: 1,
                dateRange: [
                    dateMinusDayToString(7),
                    dateMinusDayToString(1),
                ],
                isPremium: false,
                loadingComplete: false,
                targetLanguageCode: null,
                recentlyHoveredDataListener: null,
                bookmarkDataListener: null,
                lemsDataListener: null,
                recentlyHoveredData: null,
                bookmarked: null,
                lemsData: null,
                recentWordsLongDisplay: false,
            };
        },
        computed: {
            ...mapGetters([
                'firebaseUser', 'userProfile',
            ]),
            hasData() {
                return this.analyticsData && Object.keys(this.analyticsData).length > 0;
            },
            bookmarkPath () {
                return this.targetLanguageCode + FIRESTORE_BOOKMARK_PATH;
            },
            startDate () {
                return this.dateRange[0];
            },
            endDate () {
                if (this.dateRange.length > 1) {
                    return this.dateRange[1];
                }
                return this.dateRange[0];
            },
            recentlyHoveredPath () {
                return this.targetLanguageCode + FIRESTORE_RECENTLY_HOVERED_PATH;
            },
            lemsPath () {
                return this.userProfile.targetLanguage.toLowerCase() + FIRESTORE_LEMS_PATH;
            },
        },
        mounted () {
            this.onFirebaseUserChange();
            gtagAfterDelay(EVENTS.PAGE_VISITED, {pageId: 'analytics'});
            gtagSet({[GLOBAL_PARAMS.CURRENT_PAGE]: 'analytics'});
        },
        methods: {
            updatePremiumStatus () {
                isPremium().then(isPremium => {
                    this.isPremium = isPremium;
                    this.loadingComplete = true;
                })
            },
            onFirebaseUserChange () {
                if (this.firebaseUser) {
                    this.updatePremiumStatus();

                    this.targetLanguageCode = getLanguageCode(this.userProfile.targetLanguage);

                    firebase.firestore()
                        .collection(FIRESTORE_ANALYTICS_PATH)
                        .doc(this.firebaseUser.uid)
                        .get()
                        .then(doc => {
                            this.analyticsData = doc.data();
                            if (this.hasData > 0) {
                                this.minDate = new Date(Object.keys(this.analyticsData).sort()[0]);
                                const maxDate = dateMinusDayToString(1);
                                for (
                                    let currentDate = dateToString(this.minDate);
                                    currentDate !== maxDate;
                                    currentDate = dateMinusDayToString(-1, new Date(currentDate))
                                ) {
                                    if (!this.analyticsData[currentDate]) {
                                        this.analyticsData[currentDate] = {
                                            w: 0,
                                            h: 0,
                                            p: 0,
                                            t: 0,
                                            m: 0,
                                        }
                                    }
                                }
                            }
                        });

                    if (this.recentlyHoveredDataListener) {
                        this.recentlyHoveredDataListener();
                    }
                    this.recentlyHoveredDataListener = firebase.firestore()
                        .collection(this.recentlyHoveredPath)
                        .doc(this.firebaseUser.uid)
                        .onSnapshot(doc => {
                            if (doc.exists) {
                                this.recentlyHoveredData = doc.data().list || [];
                            } else {
                                this.recentlyHoveredData = [];
                            }
                        });

                    if (this.bookmarkDataListener) {
                        this.bookmarkDataListener();
                    }
                    this.bookmarkDataListener = firebase.firestore()
                        .collection(this.bookmarkPath)
                        .doc(this.firebaseUser.uid)
                        .onSnapshot(doc => {
                            if (doc.exists) {
                                this.bookmarked = doc.data();
                            } else {
                                this.bookmarked = {};
                                this.bookmarkData = [];
                            }
                        });

                    if (this.lemsDataListener) {
                        this.lemsDataListener();
                    }
                    this.lemsDataListener = firebase.firestore()
                        .collection(this.lemsPath)
                        .doc(this.firebaseUser.uid)
                        .onSnapshot(doc => {
                            if (doc.exists) {
                                this.lemsData = new Map(Object.entries(doc.data()));
                            } else {
                                this.lemsData = new Map();
                            }
                        });
                }
            }
        },
        watch: {
            recentWordsLongDisplay(newVal) {
                if (newVal) {
                    setTimeout(() => this.$vuetify.goTo(
                        '#analytics__recently-hovered',
                        {duration: 500, easing: 'easeInOutCubic'},
                    ), 50);
                }
            },
            firebaseUser () {
                this.onFirebaseUserChange();
            },
        },
        beforeDestroy() {
            if (this.recentlyHoveredDataListener) {
                this.recentlyHoveredDataListener();
            }
            if (this.bookmarkDataListener) {
                this.bookmarkDataListener();
            }
            if (this.lemsDataListener) {
                this.lemsDataListener();
            }
        },
    }
</script>

<style scoped lang="sass">
    .analytics
        position: relative
        height: 100%

    .analytics__separator-title
        position: relative
        width: 115px
        height: 4px

    .analytics__title
        position: relative
        max-width: 100% !important
        font-size: 50px !important
        font-weight: bold

    .analytics__no-data
        position: relative
        font-size: 20px
        font-weight: 500

    .analytics__panels
        max-width: 100%

    .analytics__not-premium-overlay
        width: 100%
        position: absolute
        bottom: 0
        left: 0
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 30%)
        height: 250px
        padding-top: 100px

    .analytics__not-premium-text
        font-size: 24px
        font-weight: bold

    .analytics__not-premium-button
        font-size: 20px !important

    .analytics__time-section
        display: block !important

    @media screen and (max-width: 1615px)
        .analytics__time-section.free-plan
            display: none !important

    @media screen and (max-width: 795px)
        .analytics__content
            padding-left: 16px !important
            padding-right: 16px !important
        .analytics__word-mastery-section, .analytics__recently-hovered
            margin-right: 0 !important
        .analytics__words-section, .analytics__time-section
            flex-grow: unset !important
            flex-shrink: unset !important

        @media screen and (max-width: 700px)
        .analytics__title
            font-size: 40px !important
</style>