<template>
    <div :style="`position: relative; height: ${size}px; width: ${size}px;`">
        <img class="flag"
             :style="`position: absolute; top: 0; left: 0;
             height: ${size}px;
             width: ${size}px;`"
             :alt="`country flag ${country}`"
             :src="flag"/>
        <div v-if="border"
             :style="`position: absolute;
             height: ${size}px;
             width: ${size}px;
             border: ${size*0.05}px grey solid;
             border-radius: ${2*size}px;`"></div>
    </div>
</template>

<script>
    const flags = {}
    flags.de = require("./de.svg")
    flags.fr = require("./fr.svg")
    flags.en = require("./en.svg")
    flags.es = require("./es.svg")
    flags.pt = require("./pt.svg")
    flags.ch = require("./ch.svg")

    export default {
        name: 'Flag',
        props: {
            country: {
                // 2 letters country code
                type: String,
                required: true,
            },
            size: {
                type: Number,
                default: 25,
            },
            border: {
                type: Boolean,
                default: true,
            }
        },
        computed: {
            flag () {
                return flags[this.country];
            },
        }
    }
</script>

<style lang="sass" scoped>
</style>