<template>
    <div class="policy-page d-flex justify-center">
        <div class="policy-page__content d-flex flex-column px-8 pt-12">
            <div class="policy-page__title">{{title}}</div>
            <div class="policy-page__last-update">{{lastUpdate}}</div>
            <p></p>
            <div class="policy-page__description">{{description}}</div>
            <div v-for="(section, index) in sections"
                 v-bind:key="index"
            >
                <div class="policy-page__section-title mt-5">{{section.title}}</div>
                <div class="policy-page__section-content mt-1">
                    <div v-html="section.content"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PolicyPage",
        props: {
            title: {
                type: String,
                required: true,
            },
            lastUpdate: {
                type: String,
                required: true,
            },
            description: {
                type: String,
                required: true,
            },
            sections: {
                // Array of objects containing title and content (can include html)
                type: Array,
                required: true,
            }
        }
    }
</script>

<style scoped lang="sass">
.policy-page
    width: 100%
    margin-bottom: 150px

.policy-page__content
    max-width: 1000px
    width: 100%

.policy-page__title
    text-align: center
    font-size: 40px
    font-weight: bold

.policy-page__last-update
    font-size: 16px
    text-align: justify
    font-style: italic

.policy-page__description
    font-size: 16px
    text-align: justify

.policy-page__section-title
    font-size: 20px
    font-weight: bold

.policy-page__section-content
    text-align: justify
</style>