<template>
    <div class="words-selection pa-4 d-flex elevation-4 flex-row">
        <div class="d-flex flex-column grow">
            <div class="words-selection__title secondary--text mb-2">{{$t('analytics.words.title')}}</div>
            <words-plot class=""
                        v-bind:words-count="wordsCount"
                        v-bind:end-date="endDate"
                        v-bind:start-date="startDate"
                        v-bind:is-premium="isPremium"
            ></words-plot>
        </div>
        <div class="words-selection__boxes d-flex flex-column">
            <div class="words-selection__info-box primary">
                <div v-if="!isPremium"
                     class="words-selection__info-box-intro"
                     v-html="$t('analytics.words.info_box_free')"
                ></div>
                <div v-else class="words-selection__info-box-intro">
                    {{daysCount === 7
                    ? $t('analytics.words.info_box_premium_display_weekly')
                    : $t('analytics.words.info_box_premium_display_p1') + daysCount + $t('analytics.words.info_box_premium_display_p2')}}
                </div>
                <div class="words-selection__info-box-data">{{wordsFreePlan.toLocaleString() + $t('analytics.words.info_box_p2')}}</div>
            </div>
            <div v-if="isPremium" class="words-selection__info-box highlight">
                <div class="words-selection__info-box-intro">
                    {{$t('analytics.words.info_box_average')}}
                </div>
                <div class="words-selection__info-box-data">{{averageWords.toLocaleString() + $t('analytics.words.info_box_p2')}}</div>
            </div>
            <div v-if="!isPremium" class="words-selection__info-box highlight">
                <div class="words-selection__info-box-intro" v-html="$t('analytics.words.info_box_premium')"></div>
                <div class="words-selection__info-box-data">{{wordsPremiumPlan.toLocaleString() + $t('analytics.words.info_box_p2')}}</div>
            </div>
            <div v-if="!isPremium" class="words-selection__premium-plan-info ma-auto">
                <div class="mb-1">
                    {{$t('analytics.words.500_word_premium_plan')}}
                </div>
                <router-link to="/subscription">
                    <lexios-button>Lexios Premium</lexios-button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    import WordsPlot from './WordsPlot';
    import LexiosButton from '../LexiosButton';
    export default {
        name: "WordsSection",
        components: {LexiosButton, WordsPlot},
        props: {
            analyticsData : {
                required: true,
            },
            startDate: {
                type: String,
                required: true,
            },
            endDate: {
                type: String,
                required: true,
            },
            isPremium: {
                type: Boolean,
                required: true,
            },
        },
        computed: {
            averageWords() {
                return Math.round(this.wordsFreePlan/this.wordsCount.length);
            },
            daysCount() {
                return this.wordsCount.length;
            },
            wordsFreePlan() {
                return this.wordsCount.map(d => d.words).reduce((a, b) => a + b, 0)
            },
            wordsPremiumPlan() {
                return this.wordsCount.map(d => d.words + d.premium_words).reduce((a, b) => a + b, 0)
            },
            wordsCount () {
                if (!this.analyticsData) {
                    return [];
                }
                return Object.entries(this.analyticsData).filter(pair => {
                    return pair[0] >= this.startDate && pair[0] <= this.endDate;
                }).map(pair => {
                    return {
                        date: new Date(pair[0]),
                        words: pair[1].w,
                        premium_words: this.isPremium ? 0 : pair[1].n || 0, // If already premium, no comparison is useful
                    }
                }).sort((a, b) => a.date - b.date)
            }
        }
    }
</script>

<style scoped lang="sass">
    .test
        width: 100%

    .words-selection
        position: relative
        background-color: white
        border-radius: 30px
        width: max-content
        //max-width: 800px
    .words-selection__title
        font-size: 24px
        font-weight: bold
    .words-selection__boxes
        z-index: 0
    .words-selection__info-box
        display: flex
        flex-direction: column
        justify-content: center
        color: white
        padding: 16px
        margin: 8px
        border-radius: 20px
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4)
        text-align: center
    .words-selection__info-box-intro
        font-size: 16px
        ::v-deep a
            color: white
            text-decoration: underline !important
    .words-selection__info-box-data
        font-size: 24px
        font-weight: bold
    .words-selection__premium-plan-info
        align-self: center
        max-width: 250px
        text-align: center
        ::v-deep a
            text-decoration: underline !important

    @media screen and (max-width: 795px)
        .words-selection
            flex-direction: column !important
        .words-selection__boxes
            flex-direction: row !important
            align-items: stretch
        .words-selection__premium-plan-info
            max-width: 100%
            padding: 4px !important
            padding-top: 8px !important

    @media screen and (max-width: 599px)
        .words-selection__boxes
            flex-wrap: wrap
            justify-content: center

    @media screen and (max-width: 532px)
        .words-selection__info-box
            width: 100%
            padding: 8px !important
            margin: 4px !important
</style>