<template>
    <popup-text v-bind:infos="titlePopupBoxInfo"
                v-bind:language="targetLanguage"
                v-bind:id="uuidv4()">
    </popup-text>
</template>

<script>
    import { uuidv4 } from '../../utils/helper';
    import { getLanguageFromCode } from '../../utils/languages';
    import { examples } from './wordExamples';
    import PopupText from './PopupText';

    export default {
        name: "WordExample",
        components: {PopupText},
        props: {
            word: {
                type: String,
                required: true,
            },
        },
        methods: {
            uuidv4,
        },
        computed: {
            titlePopupBoxInfo() {
                const wordMultiLocalInfo = examples[this.word];
                const targetLanguageInfos = wordMultiLocalInfo[this.targetLanguage];
                return  {
                    translation: targetLanguageInfos.t,//depends on the target language
                    token: wordMultiLocalInfo[this.locale].t,//depends on the locale setting
                    form: targetLanguageInfos.f, //pl or s or - if not a noun
                    gender: targetLanguageInfos.g,
                    type: wordMultiLocalInfo.type,
                }
            },
            locale() {
                return getLanguageFromCode(this.$i18n.locale);
            },
            targetLanguage() {
                return this.$store.state.targetLanguage;
            },
        },
    }
</script>

<style scoped>

</style>