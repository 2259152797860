<template>
    <svg class="icon" height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <clipPath :id="`clip-path_${uuid}`">
                <path d="m25 285.996h30.001c13.785 0 25-11.215 25-25v-94.997h100.998c5.523 0 10-4.478 10-10s-4.477-10-10-10h-110.998c-5.523 0-10 4.478-10 10v104.997c0 2.71-2.29 5-5 5h-30.001c-2.71 0-5-2.29-5-5v-114.998c0-10.659 4.167-20.697 11.735-28.265 7.567-7.567 17.605-11.735 28.265-11.735h322.002c5.522 0 10-4.478 10-10l.004-49.808c.106-.075.318-.174.563-.189.263-.023.367.061.479.161.142.128.286.252.435.372l98.457 79.496c-.036.028-.072.058-.108.086l-98.811 79.722c-.177.144-.294.18-.492.152-.236-.032-.435-.148-.527-.231v-49.76c0-5.522-4.478-10-10-10h-111.004c-5.522 0-10 4.478-10 10s4.478 10 10 10h101.005v39.815c-.005 7.761 4.435 14.705 11.584 18.123 7.308 3.493 15.737 2.519 21.999-2.537l98.76-79.681c4.822-3.812 7.612-9.489 7.656-15.587.044-6.108-2.683-11.834-7.483-15.713l-98.29-79.302c-6.051-5.306-14.711-6.605-22.165-3.302-7.439 3.299-12.061 10.335-12.061 18.362v39.819h-312.003c-16.001 0-31.062 6.248-42.407 17.593-11.345 11.347-17.593 26.407-17.593 42.409v114.998c0 13.785 11.215 25 25 25z"/>
                <path d="m487.002 226.005h-30c-13.785 0-25 11.215-25 25v94.998h-292.002v-39.816c.004-7.761-4.434-14.705-11.584-18.123-7.308-3.493-15.739-2.52-21.999 2.537l-98.762 79.682c-4.821 3.812-7.61 9.487-7.654 15.584-.044 6.108 2.681 11.834 7.483 15.714l98.29 79.302c3.827 3.355 8.698 5.109 13.647 5.109 2.876 0 5.78-.593 8.52-1.808 7.438-3.299 12.06-10.334 12.06-18.361v-39.82h312.002c16.002 0 31.062-6.248 42.407-17.593s17.593-26.405 17.593-42.407v-114.998c-.001-13.785-11.216-25-25.001-25zm5 139.998c0 10.659-4.168 20.697-11.735 28.265s-17.605 11.735-28.265 11.735h-322.002c-5.523 0-10 4.478-10 10l-.003 49.808c-.107.076-.319.174-.564.189-.26.016-.369-.06-.48-.161-.141-.128-.286-.252-.434-.372l-98.524-79.432c.004-.006.024-.03.067-.064.037-.028.073-.058.109-.086l98.81-79.722c.143-.115.244-.161.381-.161.035 0 .071.003.111.009.237.032.435.148.528.231v49.761c0 5.522 4.477 10 10 10h312.002c5.522 0 10-4.478 10-10v-104.998c0-2.71 2.29-5 5-5h30c2.71 0 5 2.29 5 5v114.998z"/>
                <path d="m225.994 145.999c-5.523 0-9.996 4.478-9.996 10s4.48 10 10.003 10 10-4.478 10-10-4.477-10-10-10z"/></clipPath>
            <linearGradient :id="`grad_${uuid}`"
                            :gradientTransform="`rotate(${rotateAngle})`"
                            x1="0%"
                            y1="0%"
                            x2="100%"
                            y2="0%"
            >
                <stop offset="0%" :style="`stop-color:${gradColorStart};stop-opacity:1`"/>
                <stop offset="100%" :style="`stop-color:${gradColorEnd};stop-opacity:1`"/>
            </linearGradient>
        </defs>
        <g :clip-path="`url(#clip-path_${uuid})`">
            <rect :fill="iconColor" x="0" y="0" width="512" height="512"/>
        </g>
    </svg>
</template>

<script>
    import { uuidv4 } from '../../utils/helper';

    export default {
        name: "GraphUpIcon",
        props: {
            color: {
                type: String,
                default: '',
            },
            gradColorStart: {
                type: String,
                default: 'black',
            },
            gradColorEnd: {
                type: String,
                default: 'black',
            },
            rotateAngle: {
                type: Number,
                default: 0,
            }
        },
        data () {
            return {
                uuid: uuidv4(),
            }
        },
        computed: {
            iconColor() {
                if (this.color === '') {
                    return `url(#grad_${this.uuid})`;
                }
                return this.color;
            }
        },
    }
</script>

<style scoped lang="sass">
    .icon
        border-radius: 0 !important
</style>