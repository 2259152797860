import { md5 } from '../utils/md5Hashing';
import { getBrowser } from '../utils/helper';
import { SECOND } from '../utils/time';

export const EVENTS = {
    DOWNLOAD_CLICK: 'download_button',
    WELCOME_PAGE: 'welcome_page',
    MOBILE_USER_NEWSLETTER: 'mobile_user_newsletter',
    FAQ_QUESTION_OPEN: 'faq_question_open',
    SECTION_VISITED: 'section_visited',
    PAGE_VISITED: 'page_visited',
    WORDS_LIST: 'words_list',
    REFERRAL: 'referral',
};
export const GLOBAL_PARAMS = {
    GLOBAL_USER_ID: 'global_user_id',
    USER_ID: 'user_id',
    LANGUAGE_CODE: 'language_code',
    BROWSER: 'browser',
    CURRENT_PAGE: 'current_page',
    TARGET_LANGUAGE: 'user_target_language',
};

let extraValues = {};

/**
 * Used to set or modify global parameters that should be passed with every event
 * @param pairs
 */
export function gtagSet (pairs) {
    extraValues = {...extraValues, ...pairs}
}

// Init gtag
window.dataLayer = window.dataLayer || [];

export function gtag () {
    if (arguments[0] === 'event') {
        arguments[2] = {...arguments[2], ...extraValues}
    }
    // eslint-disable-next-line no-undef
    dataLayer.push(arguments);
}

/**
 * Only triggers after a 1 second delay to make sure UID was set. Mostly useful for onPageLoadEvents
 * @param name
 * @param payload
 */
export function gtagAfterDelay(name, payload) {
    setTimeout(() => {
        gtag('event', name, payload);
    }, 2 * SECOND);
}

gtag('js', new Date());
gtagSet({[GLOBAL_PARAMS.BROWSER]: getBrowser()})

// region globalUserId
let globalUserId;
let promisesHasGlobalUserId = [];
export async function getGlobalUserId() {
    return new Promise(resolve => {
        if (!globalUserId && promisesHasGlobalUserId.length === 0) {
            promisesHasGlobalUserId.push(resolve);

            fetch('https://us-central1-lingualess-com.cloudfunctions.net/ip')
                .then(_ => _.json())
                .then(data => {
                const ip = data.ip;
                globalUserId = md5(ip + navigator.userAgent);
                gtagSet({[GLOBAL_PARAMS.GLOBAL_USER_ID]: globalUserId});
                gtag('config', 'G-WZV92YK79P', {...extraValues, ...{'cookie_expires': 1}});
                promisesHasGlobalUserId.forEach(r => r(globalUserId));
            });
        } else if (!globalUserId) {
            promisesHasGlobalUserId.push(resolve);
        } else {
            resolve(globalUserId);
        }
    });
}
// noinspection JSIgnoredPromiseFromCall
getGlobalUserId(); // To set id for gtag and any other future fetch
// endregion
