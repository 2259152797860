<template>
    <svg class="icon" height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <clipPath :id="`clip-path_${uuid}`">
                <path d="M455.822,62.599c-63.875-4-129.228-24.57-194.245-61.138c-3.465-1.948-7.691-1.948-11.156,0
				C185.404,38.03,120.051,58.6,56.176,62.599c-5.995,0.375-10.666,5.348-10.666,11.356v216.178
				c0,35.772,11.163,69.812,33.176,101.176c17.293,24.637,41.281,47.625,71.298,68.325c50.574,34.875,100.429,51.143,102.526,51.818
				c1.134,0.365,2.311,0.547,3.487,0.547s2.353-0.183,3.487-0.547c2.098-0.676,51.952-16.944,102.526-51.818
				c30.017-20.698,54.005-43.686,71.298-68.325c22.016-31.364,33.179-65.404,33.179-101.176V73.956
				C466.488,67.947,461.817,62.975,455.822,62.599z M443.733,290.133c0,56.598-31.692,107.221-94.197,150.463
				c-40.464,27.994-81.542,43.715-93.541,47.993c-11.948-4.25-52.667-19.812-93.092-47.689
				c-62.797-43.304-94.637-94.028-94.637-150.767V84.499c62.053-5.558,125.125-25.749,187.733-60.107
				c62.609,34.356,125.68,54.548,187.733,60.107V290.133z"/>
                <path d="M298.509,298.322c-1.611-9.663-3.265-19.589-4.732-29.425c18.992-12.568,30.489-33.68,30.489-56.867
				c0-37.642-30.624-68.267-68.267-68.267s-68.267,30.624-68.267,68.267c0,23.187,11.496,44.299,30.489,56.866
				c-1.467,9.836-3.121,19.762-4.732,29.425c-3.295,19.777-6.705,40.226-8.639,60.54c-0.304,3.191,0.752,6.361,2.909,8.734
				c2.156,2.371,5.212,3.723,8.417,3.723h79.644c3.205,0,6.262-1.352,8.417-3.723c2.157-2.371,3.212-5.542,2.909-8.734
				C305.214,338.548,301.805,318.098,298.509,298.322z M270.149,264.486c1.747,12.577,3.865,25.287,5.913,37.577
				c2.547,15.284,5.163,30.979,7.068,46.5h-54.262c1.905-15.52,4.52-31.216,7.068-46.5c2.048-12.29,4.167-25,5.913-37.577
				c0.671-4.833-1.815-9.556-6.178-11.74c-15.533-7.774-25.182-23.377-25.182-40.716c0-25.095,20.416-45.511,45.511-45.511
				s45.511,20.416,45.511,45.511c0,17.341-9.649,32.942-25.184,40.716C271.964,254.929,269.479,259.652,270.149,264.486z"/>
                <path d="M250.824,52.884c-49.851,25.465-100.398,42.448-150.234,50.48c-5.515,0.889-9.568,5.648-9.568,11.233v175.536
				c0,48.291,27.962,92.319,83.11,130.862c30.397,21.242,61.674,35.196,77.614,41.622c1.364,0.551,2.81,0.826,4.254,0.826
				s2.89-0.275,4.254-0.826c8.373-3.376,21.474-9.024,36.333-16.755c5.574-2.9,7.743-9.77,4.842-15.345
				c-2.9-5.575-9.77-7.741-15.344-4.841c-11.741,6.109-22.311,10.837-30.084,14.088c-15.924-6.662-42.774-19.21-68.832-37.422
				c-48.699-34.034-73.391-71.787-73.391-112.209V124.185c47.329-8.646,95.105-24.911,142.222-48.423
				c47.118,23.512,94.893,39.777,142.222,48.423v165.948c0,29.949-13.645,58.616-40.558,85.205c-4.47,4.417-4.514,11.62-0.097,16.09
				c4.418,4.471,11.62,4.514,16.09,0.097c31.399-31.023,47.32-65.135,47.32-101.392V114.597c0-5.585-4.054-10.345-9.568-11.233
				c-49.837-8.032-100.384-25.015-150.234-50.48C257.923,51.223,254.074,51.223,250.824,52.884z"/>
                <path d="M322.868,403.762c-0.354,0.356-0.707,0.711-1.062,1.062c-4.467,4.42-4.503,11.625-0.083,16.09
				c2.226,2.248,5.155,3.374,8.086,3.374c2.892,0,5.786-1.096,8.003-3.292c0.405-0.402,0.81-0.807,1.214-1.214
				c4.425-4.462,4.392-11.667-0.071-16.09C334.495,399.269,327.293,399.299,322.868,403.762z"/>
            </clipPath>
            <linearGradient :id="`grad_${uuid}`"
                            :gradientTransform="`rotate(${rotateAngle})`"
                            x1="0%"
                            y1="0%"
                            x2="100%"
                            y2="0%"
            >
                <stop offset="0%" :style="`stop-color:${gradColorStart};stop-opacity:1`"/>
                <stop offset="100%" :style="`stop-color:${gradColorEnd};stop-opacity:1`"/>
            </linearGradient>
        </defs>
        <g :clip-path="`url(#clip-path_${uuid})`">
            <rect :fill="iconColor" x="0" y="0" width="512" height="512"/>
        </g>
    </svg>
</template>

<script>
    import { uuidv4 } from '../../utils/helper';

    export default {
        name: "SecureIcon",
        props: {
            color: {
                type: String,
                default: '',
            },
            gradColorStart: {
                type: String,
                default: 'black',
            },
            gradColorEnd: {
                type: String,
                default: 'black',
            },
            rotateAngle: {
                type: Number,
                default: 0,
            }
        },
        data () {
            return {
                uuid: uuidv4(),
            }
        },
        computed: {
            iconColor() {
                if (this.color === '') {
                    return `url(#grad_${this.uuid})`;
                }
                return this.color;
            }
        },
    }
</script>

<style scoped lang="sass">
    .icon
        border-radius: 0 !important
</style>