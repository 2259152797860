<template>
    <span class="popup-text"
          ref="popup-text"
          @mouseover="hoverDebounce(true)"
          @mouseleave="hoverDebounce(false)"><span>{{infos.translation}}</span>
    </span>
</template>

<script>
    import { debounce, posX, posY, viewPortWidth } from '../../utils/helper';

    export default {
        name: "PopupText",
        props: {
            infos: {
                type: Object,
                required: true,
            },
            language: {
                type: String,
                required: true,
            },
            id: {
                type: String,
                required: true,
            },
        },
        data () {
            return {
                interval: null,
                isVisible: false,
                isActive: false,
                timeLearningTotal: 0,
                timeLearning: 0,
                timestampStarted: -1,
                hasBeenHovered: false,
                hoverText: false,
                hoverDebounce: debounce((hoverText) => {
                    if (this.hoverText !== hoverText) {
                        this.hoverText = hoverText;
                        this.$store.commit("changeHoveredText", {
                            hoverText: hoverText,
                            infos: this.infos,
                            language: this.language,
                            hoverDebounce: this.hoverDebounce,
                            leftMargin: this.leftMargin(),
                            topMargin: this.topMargin(),
                            id: this.id,
                        });
                    }
                }, 300),
            };
        },
        methods: {
            leftMargin () {
                return Math.min(viewPortWidth() - 250, posX(this.$refs['popup-text']));
            },
            topMargin () {
                const elem = this.$refs['popup-text'];
                return posY(elem) + elem.offsetHeight;
            },
        },
    }
</script>

<style lang="sass">
    @import "Popup.sass"
</style>