<template>
    <div class="achievements">
        <achievements-background></achievements-background>
        <div class="achievements__content d-flex flex-column align-center">
            <div class="achievements__separator-title rounded-pill primary mt-10"></div>
            <div class="achievements__title mt-4 secondary--text mb-2">
                {{$t('achievements.title')}}
            </div>
            <div v-if="!firebaseUser">
                <connection ref="connection"></connection>
            </div>
            <div v-else class="d-flex flex-wrap justify-center">
                <v-progress-circular v-if="!hasLoaded"
                                     class="ma-3 flex-xl-grow-0"
                                     indeterminate
                                     color="grey darken-4"
                ></v-progress-circular>
                <div v-else>
                    <div class="d-flex flex-wrap flex-row justify-center secondary--text pb-4">
                        <div class="achievements__total-points white py-4 px-8 mr-4">
                            <div class="achievements__total-points-title mb-2">{{$t('achievements.total_points')}}</div>
                            <div class="d-flex justify-center">
                                <div class="achievements__total-points-amount mr-2">{{trophiesAnimation}}</div>
                                <trophy-icon class="achievements__reward-symbol"></trophy-icon>
                            </div>
                        </div>
                        <div class="achievements__rank white py-4 px-8 mr-4 d-flex flex-column">
                            <div class="achievements__rank-title">{{this.$t('achievements.rank_title')}}</div>
                            <div class="achievements__rank-value mx-auto">{{rankDisplay}}</div>
                            <div class="achievements__rank-total ml-auto">{{usersCountDisplay}}</div>
                        </div>
                        <div class="achievements__top-percent white py-4 px-5 d-flex flex-column">
                            <div class="achievements__top-percent-title ml-4">
                                {{$t('achievements.top_ranking')}}
                            </div>
                            <div class="my-1">
                                <v-progress-linear class="achievements__top-percent-progress-bar rounded-pill"
                                                   v-bind:height="36"
                                                   v-bind:value="progressBarValue"
                                                   v-bind:background-opacity="0.45"
                                >
                                    <template v-slot:default="{ value }">
                                        <div class="achievements__top-percent-progress-bar-text white--text">
                                            {{(100 - value).toFixed(3)}}%
                                        </div>
                                    </template>
                                </v-progress-linear>
                            </div>
                            <div class="achievements__top-percent-total ml-auto mr-4">
                                {{$t('achievements.of_users')}}
                            </div>
                        </div>
                    </div>
                    <div class="achievements__list d-flex flex-wrap justify-center mb-5">
                        <achievement v-for="(ach, key) in achievements"
                                     v-bind:key="key"
                                     v-bind:component="ach.component"
                                     v-bind:title="ach.title"
                                     v-bind:description="ach.description"
                                     v-bind:target="ach.target"
                                     v-bind:current="ach.current"
                                     v-bind:reward="ach.reward"
                                     v-bind:level="ach.level"
                                     v-bind:max-level="ach.maxLevel"
                                     @claimed="onAchievementClaimed(ach)"
                        ></achievement>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AchievementsBackground from './AchievementsBackground';
    import WordMasteryIcon from '../../assets/icons/achievements/WordMasteryIcon';
    import HoverIcon from '../../assets/icons/achievements/HoverIcon';
    import SeenIcon from '../../assets/icons/achievements/SeenIcon';
    import TimeIcon from '../../assets/icons/achievements/TimeIcon';
    import VisitedIcon from '../../assets/icons/achievements/VisitedIcon';
    import Achievement from './Achievement';
    import {
        ACHIEVEMENTS_DEFINITIONS, HEAR_ACHIEVEMENT,
        HOVER_ACHIEVEMENT, SEEN_ACHIEVEMENT, TIME_ACHIEVEMENT,
        VISITED_ACHIEVEMENT, DAYS_ACHIEVEMENT,
        WORD_MASTERY_ACHIEVEMENT, MAX_WORD_MASTERY_ACHIEVEMENT, getTrophiesFromData
    } from './achievementsDefinitions';
    import HearIcon from '../../assets/icons/achievements/HearIcon';
    import DaysIcon from '../../assets/icons/achievements/DaysIcon';
    import MaxWordMasteryIcon from '../../assets/icons/achievements/MaxWordMasteryIcon';
    import { mapGetters } from 'vuex';
    import firebase from 'firebase/app';
    import { HOUR, SECOND } from '../../utils/time';
    import Connection from '../connection/Connection';
    import TrophyIcon from '../../assets/icons/achievements/TrophyIcon';
    import { ENGLISH, getLanguageFromCode } from '../../utils/languages';
    import { EVENTS, GLOBAL_PARAMS, gtagAfterDelay, gtagSet } from '../../plugins/tracking';

    const DATABASE_PATH = 'users_achievements';
    const DATABASE_PATH_RANKING = 'scores/achievements';
    const DATABASE_PATH_USERS_COUNT = 'nb_players';
    const DATABASE_PATH_ACHIEVEMENTS_STEPS = 'leaderboards_steps/achievements';
    const DATABASE_PATH_ACHIEVEMENTS_ABSOLUTE = 'leaderboards/achievements';

    export default {
        name: "Achievements",
        components: {TrophyIcon, Connection, Achievement, AchievementsBackground},
        data () {
            return {
                hasLoaded: false,
                achievementData: null,
                trophiesAnimation: 0,
                usersCount: 0,
                rank: 0,
            };
        },
        mounted () {
            gtagAfterDelay(EVENTS.PAGE_VISITED, {pageId: 'achievements'});
            gtagSet({[GLOBAL_PARAMS.CURRENT_PAGE]: 'achievements'});
        },
        created () {
            this.onFirebaseUserChange();
        },
        computed: {
            ...mapGetters([
                'firebaseUser', 'userProfile',
            ]),
            progressBarValue() {
                return 100 - (this.rank / this.usersCount * 100);
            },
            rankDisplay() {
                if (this.rank === 1) {
                    return this.rank + this.$t('achievements.first');
                }
                if (getLanguageFromCode(this.$i18n.locale) === ENGLISH) {
                    switch (this.rank % 10) {
                        case 1:
                            return this.rank + this.$t('achievements.first');
                        case 2:
                            return this.rank + this.$t('achievements.second');
                        case 3:
                            return this.rank + this.$t('achievements.third');
                        default:
                            return this.rank + this.$t('achievements.else');
                    }
                }
                return this.rank + this.$t('achievements.else');
            },
            usersCountDisplay() {
                return `/${this.usersCount} ${this.$t('achievements.users')}`
            },
            achievements () {
                return [
                    this.getAchievementValues(WordMasteryIcon, WORD_MASTERY_ACHIEVEMENT),
                    this.getAchievementValues(HoverIcon, HOVER_ACHIEVEMENT),
                    this.getAchievementValues(SeenIcon, SEEN_ACHIEVEMENT),
                    this.getAchievementValues(TimeIcon, TIME_ACHIEVEMENT),
                    this.getAchievementValues(VisitedIcon, VISITED_ACHIEVEMENT),
                    this.getAchievementValues(HearIcon, HEAR_ACHIEVEMENT),
                    this.getAchievementValues(DaysIcon, DAYS_ACHIEVEMENT),
                    this.getAchievementValues(MaxWordMasteryIcon, MAX_WORD_MASTERY_ACHIEVEMENT),
                ];
            },
            firebaseDbRef () {
                return this.firebaseUser ? firebase.app()
                    .database("https://lingualess-com-default-rtdb.europe-west1.firebasedatabase.app")
                    .ref().child(DATABASE_PATH).child(this.firebaseUser.uid) : null;
            },
            firebaseDbRefRanking () {
                return this.firebaseUser ? firebase.app()
                    .database("https://lingualess-com-default-rtdb.europe-west1.firebasedatabase.app")
                    .ref().child(DATABASE_PATH_RANKING).child(this.firebaseUser.uid) : null;
            },
            firebaseDbRefUsersCount () {
                return this.firebaseUser ? firebase.app()
                    .database("https://lingualess-com-default-rtdb.europe-west1.firebasedatabase.app")
                    .ref().child(DATABASE_PATH_USERS_COUNT) : null;
            },
            firebaseDbRefAchievementsSteps () {
                return this.firebaseUser ? firebase.app()
                    .database("https://lingualess-com-default-rtdb.europe-west1.firebasedatabase.app")
                    .ref().child(DATABASE_PATH_ACHIEVEMENTS_STEPS).child(this.trophies) : null;
            },
            firebaseDbRefAchievementsAbsolute () {
                return this.firebaseUser ? firebase.app()
                    .database("https://lingualess-com-default-rtdb.europe-west1.firebasedatabase.app")
                    .ref().child(DATABASE_PATH_ACHIEVEMENTS_ABSOLUTE).child(this.firebaseUser.uid) : null;
            },
            trophies() {
                return this.achievementData ? getTrophiesFromData(this.achievementData) : -1;
            },
        },
        methods: {
            onAchievementClaimed(achievement) {
                const newLevel = this.achievementData[achievement.name].level + 1;
                this.achievementData[achievement.name].level = newLevel;
                this.firebaseDbRef.update({
                    [this.nameLevelForAchievement(achievement.name)]: newLevel,
                });
            },
            nameLevelForAchievement(achievement) {
                return achievement + '_level';
            },
            getAchievementData(achievement, data) {
                if (achievement in data) {
                    return Math.round(data[achievement]);
                }
                return 0;
            },
            onFirebaseUserChange () {
                this.achievementData = null;
                this.hasLoaded = false;
                if (this.firebaseUser) {
                    this.firebaseDbRef.get().then(snapshot => {
                        this.hasLoaded = true;
                        const data = snapshot.exists() ? snapshot.val() : {};
                        this.achievementData = {
                            [WORD_MASTERY_ACHIEVEMENT]: {
                                current: this.getAchievementData(WORD_MASTERY_ACHIEVEMENT, data),
                                level: this.getAchievementData(this.nameLevelForAchievement(WORD_MASTERY_ACHIEVEMENT), data),
                            },
                            [HOVER_ACHIEVEMENT]: {
                                current: this.getAchievementData(HOVER_ACHIEVEMENT, data),
                                level: this.getAchievementData(this.nameLevelForAchievement(HOVER_ACHIEVEMENT), data),
                            },
                            [SEEN_ACHIEVEMENT]: {
                                current: this.getAchievementData(SEEN_ACHIEVEMENT, data),
                                level: this.getAchievementData(this.nameLevelForAchievement(SEEN_ACHIEVEMENT), data),
                            },
                            [TIME_ACHIEVEMENT]: {
                                current: Math.floor(this.getAchievementData(TIME_ACHIEVEMENT, data) / HOUR),
                                level: this.getAchievementData(this.nameLevelForAchievement(TIME_ACHIEVEMENT), data),
                            },
                            [VISITED_ACHIEVEMENT]: {
                                current: this.getAchievementData(VISITED_ACHIEVEMENT, data),
                                level: this.getAchievementData(this.nameLevelForAchievement(VISITED_ACHIEVEMENT), data),
                            },
                            [HEAR_ACHIEVEMENT]: {
                                current: this.getAchievementData(HEAR_ACHIEVEMENT, data),
                                level: this.getAchievementData(this.nameLevelForAchievement(HEAR_ACHIEVEMENT), data),
                            },
                            [DAYS_ACHIEVEMENT]: {
                                current: this.getAchievementData(DAYS_ACHIEVEMENT, data),
                                level: this.getAchievementData(this.nameLevelForAchievement(DAYS_ACHIEVEMENT), data),
                            },
                            [MAX_WORD_MASTERY_ACHIEVEMENT]: {
                                current: data[MAX_WORD_MASTERY_ACHIEVEMENT] ? Math.round(100 * data[MAX_WORD_MASTERY_ACHIEVEMENT]) : 0,
                                level: this.getAchievementData(this.nameLevelForAchievement(MAX_WORD_MASTERY_ACHIEVEMENT), data),
                            },
                        };
                    });
                }
            },
            getAchievementValues (component, name) {
                if (!this.achievementData) {
                    return null;
                }
                const definition = ACHIEVEMENTS_DEFINITIONS[name];
                const values = this.achievementData[name];
                const level = values.level;
                const length = definition.length;
                const defLevel = definition[Math.min(length - 1, level)];
                return {
                    component,
                    name,
                    title: this.$t(`achievements.${name}.title`),
                    description: this.$t(`achievements.${name}.description`),
                    target: defLevel.target,
                    reward: defLevel.reward,
                    current: values.current,
                    level,
                    maxLevel: length,
                }
            },
            onTrophiesUpdate() {
                this.updateRanking();

                const FPS = 60;
                const dt = SECOND / FPS;
                const duration = 300;
                const calls = Math.round(duration / dt);
                const increase = (this.trophies - this.trophiesAnimation) / calls;
                let current = this.trophiesAnimation;
                const interval = setInterval(() => {
                    current += increase;
                    this.trophiesAnimation = Math.round(current);
                    if (this.trophiesAnimation === this.trophies){
                        clearInterval(interval);
                    }
                }, dt);

                const trophiesRankingVal = -(this.trophies * 10_000_000_000 + (10_000_000_000 - Math.floor(Date.now() / 333)));
                this.firebaseDbRefRanking.set(trophiesRankingVal).catch(() => {
                    // Nothing, expected that we try to save lower values so ignore that
                })
            },
            updateRanking() {
                this.firebaseDbRefUsersCount.get().then(snapshot => {
                    this.usersCount = snapshot.val();
                    if (this.rank === 0) {
                        this.rank = this.usersCount;
                    }
                });
                this.firebaseDbRefAchievementsSteps.get().then(snapshot => {
                    const val = snapshot.val();
                    if (val > 500 && val !== null) {
                        this.rank = val;
                    } else if (val <= 500) {
                        this.firebaseDbRefAchievementsAbsolute.get().then(snapshot => {
                            if (snapshot.val()) {
                                this.rank = snapshot.val();
                            }
                        });
                    }
                });
            },
        },
        watch: {
            firebaseUser() {
                this.onFirebaseUserChange();
            },
            trophies() {
                this.onTrophiesUpdate();
            },
        }
    }
</script>

<style scoped lang="sass">
    .achievements__content
        width: 100%

    .achievements__separator-title
        position: relative
        width: 115px
        height: 4px

    .achievements__title
        position: relative
        max-width: 100% !important
        font-size: 50px !important
        font-weight: bold

    .achievements__total-points
        box-shadow: 0 11px 43px #17376b20 !important
        width: 100%
        max-width: 273px
        max-height: 130px
        border-radius: 33px

    .achievements__total-points-title
        font-weight: bold
        font-size: 20px

    .achievements__total-points-amount
        font-size: 40px
        font-weight: bold
        color: #FFAF00

    .achievements__reward-symbol
        height: 55px
        width: 55px

    .achievements__rank
        box-shadow: 0 11px 43px #17376b20 !important
        width: 100%
        max-width: 273px
        max-height: 130px
        border-radius: 33px

    .achievements__rank-title
        font-size: 20px
        font-weight: bold

    .achievements__rank-value
        margin-top: -8px
        margin-bottom: -8px
        font-size: 40px
        font-weight: bold

    .achievements__rank-total
        font-size: 20px

    .achievements__top-percent
        position: relative
        box-shadow: 0 11px 43px #17376b20 !important
        width: 100%
        max-width: 273px
        max-height: 130px
        border-radius: 33px

    .achievements__top-percent-title
        font-size: 20px
        font-weight: bold

    .achievements__top-percent-progress-bar-text
        width: 100%
        text-align: center
        font-weight: bold
        text-shadow: 0 1px 4px rgba(0, 0, 0, 0.25) !important

    .achievements__top-percent-total
        font-size: 20px
</style>