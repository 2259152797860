<template>
    <div class="connection">
        <div v-if="showLogInMessage || !loggedIn || (!checkingAccountCreated && !hasAccountCreated)"
             class="connection__content rounded white pa-4"
        >
            <div v-if="showLogInMessage">
                {{logInMessageFormatted}}
            </div>
            <div v-if="!loggedIn" id="firebaseui-auth-container"></div>
            <div v-else>
                {{$t('connection.not_used_extension')}}
                <download-button class="mt-2"></download-button>
            </div>
        </div>
        <v-progress-circular v-else-if="checkingAccountCreated"
                             class="ma-3 flex-xl-grow-0"
                             indeterminate
                             color="grey darken-4"
        ></v-progress-circular>
    </div>
</template>

<script>
    import '../../plugins/firebaseInit';
    import 'firebaseui/dist/firebaseui.css';
    import firebase from 'firebase/app';
    import 'firebase/auth';
    import 'firebase/firestore';
    import { getFirebaseUiLocalized } from '../../plugins/firebaseUi';
    import Vue from 'vue';
    import DownloadButton from '../homePage/DownloadButton';
    import { GLOBAL_PARAMS, gtagSet } from '../../plugins/tracking';
    import { getLanguageCode } from '../../utils/languages';
    const FIRESTORE_USER_PATH = "usersSettings";

    export default {
        name: "Connection",
        components: {DownloadButton},
        props: {
            logInMessage: {
                type: String,
                default: undefined,// Default message is in logInMessageFormatted for localization
            }
        },
        mounted () {
            this.initFirebaseApp();
        },
        data () {
            return {
                firebaseUser: null,
                loggedIn: false,
                checkingAccountCreated: false,
                hasAccountCreated: false,
                signInDisplayed: false,
            }
        },
        computed: {
            showLogInMessage() {
                return this.signInDisplayed && (this.checkingAccountCreated || this.hasAccountCreated);
            },
            logInMessageFormatted() {
                if (this.logInMessage === undefined) {
                    return this.$t('connection.default_log_in_message');
                }
                return this.logInMessage;
            }
        },
        methods: {
            initFirebaseApp () {
                const auth = firebase.auth();
                auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
                auth.onAuthStateChanged(firebaseUser => {
                    if (firebaseUser) {
                        this.firebaseUser = firebaseUser;
                        gtagSet({[GLOBAL_PARAMS.USER_ID]: firebaseUser.uid});
                        this.loggedIn = true;
                        this.checkAccountCreated();
                    } else {
                        this.$store.commit("changeFirebaseUser", {firebaseUser: null});
                        this.displayAuthentication();
                    }
                });
            },
            displayAuthentication () {
                this.loggedIn = false;

                // Firefox not supported by google provider yet
                const signInOptions = [firebase.auth.EmailAuthProvider.PROVIDER_ID];
                const isFirefox = typeof InstallTrigger !== 'undefined';
                if (!isFirefox) {
                    signInOptions.push(firebase.auth.GoogleAuthProvider.PROVIDER_ID);
                }

                // Wait for the change have #firebaseui-auth-container visible
                Vue.nextTick(() => {
                    const uiConfig = {
                        callbacks: {
                            signInSuccessWithAuthResult: () => {
                                // Wait for the user to be updated in background script
                                setTimeout(() => {
                                    this.loggedIn = true;
                                }, 1500);
                                return false;
                            }
                        },
                        // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
                        signInFlow: 'popup',
                        signInOptions
                    };

                    const firebaseui = getFirebaseUiLocalized(this.$i18n.locale);
                    const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
                    this.signInDisplayed = true;
                    ui.start('#firebaseui-auth-container', uiConfig);
                })
            },
            checkAccountCreated () {
                // Checks that the user has already created an account through the extension
                this.checkingAccountCreated = true;
                firebase.firestore().collection(FIRESTORE_USER_PATH).doc(this.firebaseUser.uid).get().then(doc => {
                    this.checkingAccountCreated = false;
                    if (doc.exists) {
                        this.hasAccountCreated = true;
                        const userProfile = doc.data();
                        this.$store.commit("changeFirebaseUser", {
                            firebaseUser: this.firebaseUser,
                            userProfile,
                        });
                        gtagSet({[GLOBAL_PARAMS.TARGET_LANGUAGE]: getLanguageCode(userProfile.targetLanguage)});
                    } else {
                        this.hasAccountCreated = false;
                    }
                });
            },
        },
    }
</script>

<style scoped lang="sass">
    .connection
        position: relative

    .connection__content
        max-width: 400px

    #firebaseui-auth-container
        ::v-deep div > div > form > ul
            padding-left: 0
</style>