<template>
    <div :class="`lexios-spoiler ${isVisible ? '' : 'hide'}`" @click="$emit('is-visible', true)">
        <div :style="isVisible ? '' : 'visibility: hidden'">
            <slot></slot>
        </div>
    </div>
</template>

<script>

    export default {
        name: "LexiosSpoiler",
        props: {
            isVisible: {
                type: Boolean,
                default: false,
            },
        },
        watch: {
            isVisible(newVal) {
                this.show = newVal;
            },
        }
    }
</script>

<style scoped lang="sass">
    .lexios-spoiler
        z-index: 100

    .lexios-spoiler.hide
        cursor: pointer
        border-radius: 3px
        background-color: #171717
    .lexios-spoiler.hide:hover
        background-color: #363636

    .lexios-spoiler__content
        z-index: 99
</style>