<template>
    <div class="popup-box">
        <div class="popup-box__main">
            <div class="popup-box__buttons">
                <lexios-icon-button v-bind:title="$t('popupbox.hear_word')"
                                    icon-name="mdi-volume-high"
                                    @click.native="speakWord()"
                ></lexios-icon-button>
                <logo-with-text class="popup-box__footer-logo" inline-logo v-bind:height="22"></logo-with-text>
            </div>
            <span class="popup-box_origin-token">{{infos.token}}</span>
            <br/>
            <div class="popup-box_translation">
                {{getWordDetails()}}
            </div>
        </div>
    </div>
</template>

<script>
    import { ENGLISH, FRENCH, GERMAN, PORTUGUESE, SPANISH } from '../../utils/languages';
    import LogoWithText from '../LogoWithText';
    import { speakWord } from '../../utils/helper';
    import LexiosIconButton from '../LexiosIconButton';

    export default {
        name: "PopupBox",
        components: {
            LexiosIconButton,
            LogoWithText,
        },
        props: {
            infos: {
                type: Object,
                required: true,
            },
            language: {
                type: String,
                required: true,
            },
        },
        data () {
            return {
                microphoneHover: false,
                flagHover: false,
                checkHover: false,
                learnProgress: 0,
            };
        },
        methods: {
            getWordDetails () {
                let output = this.$t(`popupbox.word_types.${this.infos.type}`);
                if (this.isGender || this.infos.form) {
                    output += ' (';
                    if (this.isGender) {
                        output += this.$t(`popupbox.word_genders.${this.infos.gender}`);
                        if (this.infos.form) {
                            output += ', ';
                        }
                    }
                    if (this.infos.form) {
                        output += this.isPlural ? this.$t('popupbox.plural_abrev') : this.$t('popupbox.singular_abrev');
                    }
                    output += ')';
                }
                output += this.$t('popupbox.semicolon') + ' ' + this.determinant + this.infos.translation;
                return output;
            },
            updateMicrophoneHover (value) {
                this.microphoneHover = value;
            },
            speakWord () {
                speakWord(this.determinant + this.infos.translation, this.language);
            },
            hidePopup () {
                this.$store.commit("changeHoveredText", {
                    hoverText: false,
                });
            }
        },
        computed: {
            isGender () {
                return this.infos.gender && this.infos.gender !== '-';
            },
            isPlural () {
                return this.infos.form === 'pl';
            },
            determinant () {
                switch (this.language) {
                    case ENGLISH:
                        return '';
                    case GERMAN:
                        if (this.isPlural) {
                            return 'die ';
                        }
                        switch (this.infos.gender) {
                            case 'nt':
                                return 'das ';
                            case 'm':
                                return 'der ';
                            case 'f':
                                return 'die ';
                        }
                        return '';
                    case FRENCH:
                        if (this.isPlural) {
                            return 'des ';
                        }
                        switch (this.infos.gender) {
                            case 'm':
                                return 'un ';
                            case 'f':
                                return 'une ';
                        }
                        return '';
                    case SPANISH:
                        if (this.isPlural) {
                            switch (this.infos.gender) {
                                case 'm':
                                    return 'los ';
                                case 'f':
                                    return 'las ';
                            }
                            return '';
                        }
                        switch (this.infos.gender) {
                            case 'm':
                                return 'el ';
                            case 'f':
                                return 'la ';
                        }
                        return '';
                    case PORTUGUESE:
                        if (this.isPlural) {
                            switch (this.infos.gender) {
                                case 'm':
                                    return 'os ';
                                case 'f':
                                    return 'as ';
                            }
                            return '';
                        }
                        switch (this.infos.gender) {
                            case 'm':
                                return 'o ';
                            case 'f':
                                return 'a ';
                        }
                        return '';
                    default:
                        return '';
                }
            },
        },
    }
</script>