<template>
    <svg class="icon" height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <clipPath :id="`clip-path_${uuid}`">
                <path d="m504.5 464.67h-30.89v-117.01c0-4.142-3.358-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v117.01h-65.81v-257.74h65.81v105.73c0 4.142 3.358 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-113.23c0-4.142-3.358-7.5-7.5-7.5h-80.81c-4.142 0-7.5 3.358-7.5 7.5v265.24h-17.324v-224.833c0-4.142-3.358-7.5-7.5-7.5h-80.813c-4.142 0-7.5 3.358-7.5 7.5v224.833h-17.325v-164.223c0-4.142-3.358-7.5-7.5-7.5h-80.813c-4.142 0-7.5 3.358-7.5 7.5v164.223h-17.325v-115.735c0-4.142-3.358-7.5-7.5-7.5h-80.814c-4.142 0-7.5 3.358-7.5 7.5v115.735h-30.886c-4.142 0-7.5 3.358-7.5 7.5v32.33c0 4.142 3.358 7.5 7.5 7.5h81c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-73.5v-17.33h482v17.33h-373.5c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h381c4.142 0 7.5-3.358 7.5-7.5v-32.33c0-4.142-3.358-7.5-7.5-7.5zm-224.837-217.333h65.813v217.333h-65.813zm-113.139 60.61h65.813v156.723h-65.813zm-113.138 48.488h65.813v108.235h-65.813z"/>
                <path d="m44.88 273.6h114.14c6.622 0 12.982-2.797 17.449-7.672l92.97-101.428h63.331c6.323 0 12.266-2.46 16.733-6.927l76.787-76.787v23.693c0 4.142 3.358 7.5 7.5 7.5h32.32c4.142 0 7.5-3.358 7.5-7.5v-80.819c0-13.046-10.614-23.66-23.66-23.66h-80.81c-4.142 0-7.5 3.358-7.5 7.5v32.33c0 4.142 3.358 7.5 7.5 7.5h23.684l-69.85 69.85h-63.944c-6.615 0-12.972 2.797-17.438 7.671l-92.981 101.429h-20.681c-4.142 0-7.5 3.358-7.5 7.5s3.358 7.5 7.5 7.5h23.98c2.102 0 4.108-.882 5.528-2.432l95.211-103.861c1.634-1.783 3.959-2.806 6.38-2.806h67.05c1.989 0 3.897-.79 5.303-2.197l84.85-84.85c2.145-2.145 2.787-5.371 1.626-8.173-1.161-2.803-3.896-4.63-6.929-4.63h-34.29v-17.331h73.31c4.775 0 8.66 3.885 8.66 8.66v73.32h-17.32v-34.3c0-3.033-1.827-5.768-4.63-6.929-2.801-1.159-6.029-.519-8.173 1.626l-89.589 89.589c-1.634 1.634-3.811 2.534-6.127 2.534h-66.63c-2.103 0-4.108.882-5.529 2.432l-95.201 103.861c-1.634 1.784-3.963 2.807-6.39 2.807h-106.64v-17.32h40.55c4.142 0 7.5-3.358 7.5-7.5s-3.358-7.5-7.5-7.5h-48.05c-4.142 0-7.5 3.358-7.5 7.5v32.32c0 4.142 3.358 7.5 7.5 7.5z"/>
            </clipPath>
            <linearGradient :id="`grad_${uuid}`"
                            :gradientTransform="`rotate(${rotateAngle})`"
                            x1="0%"
                            y1="0%"
                            x2="100%"
                            y2="0%"
            >
                <stop offset="0%" :style="`stop-color:${gradColorStart};stop-opacity:1`"/>
                <stop offset="100%" :style="`stop-color:${gradColorEnd};stop-opacity:1`"/>
            </linearGradient>
        </defs>
        <g :clip-path="`url(#clip-path_${uuid})`">
            <rect :fill="iconColor" x="0" y="0" width="512" height="512"/>
        </g>
    </svg>
</template>

<script>
    import { uuidv4 } from '../../utils/helper';

    export default {
        name: "GraphUpIcon",
        props: {
            color: {
                type: String,
                default: '',
            },
            gradColorStart: {
                type: String,
                default: 'black',
            },
            gradColorEnd: {
                type: String,
                default: 'black',
            },
            rotateAngle: {
                type: Number,
                default: 0,
            }
        },
        data () {
            return {
                uuid: uuidv4(),
            }
        },
        computed: {
            iconColor() {
                if (this.color === '') {
                    return `url(#grad_${this.uuid})`;
                }
                return this.color;
            }
        },
    }
</script>

<style scoped lang="sass">
    .icon
        border-radius: 0 !important
</style>