export const WORD_MASTERY_ACHIEVEMENT = "word_mastery";
export const TIME_ACHIEVEMENT = "time";
export const SEEN_ACHIEVEMENT = "seen";
export const HOVER_ACHIEVEMENT = "hover";
export const VISITED_ACHIEVEMENT = "visited";
export const DAYS_ACHIEVEMENT = "days";
export const MAX_WORD_MASTERY_ACHIEVEMENT = "max_word_mastery";
export const HEAR_ACHIEVEMENT = "hear";

export const ACHIEVEMENTS_DEFINITIONS = {
    [WORD_MASTERY_ACHIEVEMENT]: [
        {target: 10, reward: 30},
        {target: 50, reward: 60},
        {target: 150, reward: 90},
        {target: 500, reward: 120},
        {target: 1500, reward: 150},
    ],
    [TIME_ACHIEVEMENT]: [
        {target: 4, reward: 15},
        {target: 20, reward: 30},
        {target: 80, reward: 45},
        {target: 250, reward: 60},
        {target: 1000, reward: 75},
    ],
    [SEEN_ACHIEVEMENT]: [
        {target: 500, reward: 12},
        {target: 5000, reward: 25},
        {target: 25000, reward: 40},
        {target: 75000, reward: 55},
        {target: 250000, reward: 70},
    ],
    [HOVER_ACHIEVEMENT]: [
        {target: 25, reward: 15},
        {target: 250, reward: 30},
        {target: 1500, reward: 45},
        {target: 5000, reward: 60},
        {target: 15000, reward: 75},
    ],
    [VISITED_ACHIEVEMENT]: [
        {target: 50, reward: 11},
        {target: 500, reward: 20},
        {target: 3000, reward: 32},
        {target: 10000, reward: 46},
        {target: 30000, reward: 60},
    ],
    [DAYS_ACHIEVEMENT]: [
        {target: 3, reward: 15},
        {target: 10, reward: 30},
        {target: 30, reward: 45},
        {target: 90, reward: 60},
        {target: 365, reward: 75},
    ],
    [MAX_WORD_MASTERY_ACHIEVEMENT]: [
        {target: 20, reward: 10},
        {target: 40, reward: 20},
        {target: 60, reward: 40},
        {target: 80, reward: 60},
        {target: 100, reward: 80},
    ],
    [HEAR_ACHIEVEMENT]: [
        {target: 25, reward: 25},
        {target: 250, reward: 50},
        {target: 1500, reward: 75},
        {target: 5000, reward: 100},
        {target: 15000,reward: 125},
    ],
}

/**
 * Get the correct trophies amount from the data (sum of reward for each achievement current level)
 * @param achievementData
 * @return {*}
 */
export function getTrophiesFromData(achievementData) {
    return Object.entries(achievementData).map(pair =>
        ACHIEVEMENTS_DEFINITIONS[pair[0]]
            .slice(0, pair[1].level)
            .map(_ => _.reward)
            .reduce((acc, cur) => acc + cur, 0)
    ).reduce((acc, cur) => acc + cur, 0);
}