<template>
    <div class="achievements-background">
        <dots-grid class="achievements-background__elem1 d-none d-md-flex"
                   v-bind:color="'#c9d1de'"
        ></dots-grid>
        <dots-grid class="achievements-background__elem2 d-none d-md-flex"
                   v-bind:color="'#c9d1de'"
        ></dots-grid>
    </div>
</template>

<script>
    import DotsGrid from '../../assets/images/background/DotsGrid';
    export default {
        name: "AchievementsBackground",
        components: {DotsGrid}
    }
</script>

<style scoped lang="sass">
    .achievements-background
        width: 100%
        height: 100%
        position: absolute
        z-index: 0
        background: #f8f9fe

    .achievements-background__elem1-path1
        fill: #fff

    .achievements-background__elem1-path2
        fill: none
        stroke: #e1e1ff
        stroke-miterlimit: 10
        stroke-width: 2px

    .achievements-background__elem1
        position: absolute
        left: -60px
        top: 190px
        height: 135px

    .achievements-background__elem2
        position: absolute
        right: 7vw
        bottom: 20px
        height: 135px
</style>