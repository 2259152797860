import firebase from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyB4DfF5YyEdtCCoOR_NqiGMS-LcP8Cl0Ys",
    authDomain: "lexios.io",
    projectId: "lingualess-com",
    storageBucket: "lingualess-com.appspot.com",
    messagingSenderId: "376290556364",
    appId: "1:376290556364:web:6cb351602cb7e43d1b8569",
    measurementId: "G-5V6BZ883H1"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);