<template>
    <div class="popup-tooltip" :style="`max-width: ${maxWidth}px !important`">
        {{title}}
    </div>
</template>

<script>
    export default {
        name: "PopupTooltip",
        props: {
            title: {
                type: String,
                required: true,
            },
            popupSize: {
                type: String, //s, m, l, xl
                default: 's',
            },
        },
        computed: {
            maxWidth () {
                switch (this.popupSize) {
                    case 'xl':
                        return 350;
                    case 'l':
                        return 250;
                    case 'm':
                        return 175;
                    case 's':
                    default:
                        return 100;

                }
            }
        }
    }
</script>